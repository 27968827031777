import {
  GET_EAP_LIST,
  GET_EAP_LIST_SUCCESS,
  EAP_GET_BY_ID,
  EAP_GET_BY_ID_ERROR,
  EAP_GET_BY_ID_SUCCESS,
  EAP_UPDATE,
  EAP_UPDATE_SUCCESS,
  EAP_UPDATE_ERROR,
  EAP_SYNC,
  EAP_SYNC_SUCCESS,
  EAP_SYNC_ERROR,
  SAVE_EAP_PLAN_ITEM_ATTACHMENT,
  GET_EAP_DOCUMENT_LIST,
  GET_EAP_DOCUMENT_LIST_SUCCESS,
  EAP_SYNC_CLEAR_NOTIFY,
  GET_REFRESH_EAP_LIST,
  GET_REFRESH_EAP_LIST_SUCCESS,
  GET_REFRESH_EAP_ATTACHMENT_LIST,
  GET_REFRESH_EAP_ATTACHMENT_LIST_SUCCESS,
  EAP_DETECT_DIFFERENCE,
  EAP_DETECT_DIFFERENCE_SUCCESS,
  GET_SSM_USER,
  GET_SSM_USER_SUCCESS,
} from './types';

export const getEapList = payload => ({
  type: GET_EAP_LIST,
  payload,
});

export const getEapListSuccess = payload => ({
  type: GET_EAP_LIST_SUCCESS,
  payload,
});

export const getRefreshEapList = payload => ({
  type: GET_REFRESH_EAP_LIST,
  payload,
});

export const getRefreshEapListSuccess = payload => ({
  type: GET_REFRESH_EAP_LIST_SUCCESS,
  payload,
});

export const getRefreshEapAttachmentList = payload => ({
  type: GET_REFRESH_EAP_ATTACHMENT_LIST,
  payload,
});

export const getRefreshEapAttachmentListSuccess = payload => ({
  type: GET_REFRESH_EAP_ATTACHMENT_LIST_SUCCESS,
  payload,
});

export const getEapDocumentList = payload => ({
  type: GET_EAP_DOCUMENT_LIST,
  payload,
});

export const getEapDocumentListSuccess = payload => ({
  type: GET_EAP_DOCUMENT_LIST_SUCCESS,
  payload,
});

export const getEAPById = payload => ({
  type: EAP_GET_BY_ID,
  payload,
});

export const getEAPByIdSuccess = eapDetail => ({
  type: EAP_GET_BY_ID_SUCCESS,
  payload: eapDetail,
});

export const getEAPByIdError = error => ({
  type: EAP_GET_BY_ID_ERROR,
  payload: error,
});

export const updateEAP = eapDetail => ({
  type: EAP_UPDATE,
  payload: eapDetail,
});

export const updateEAPSuccess = eapDetail => ({
  type: EAP_UPDATE_SUCCESS,
  payload: eapDetail,
});

export const updateEAPError = error => ({
  type: EAP_UPDATE_ERROR,
  payload: error,
});

export const syncEAP = (clientId, servicePlanHeaderId, clientReferenceNumberOverride) => ({
  type: EAP_SYNC,
  payload: { clientId, servicePlanHeaderId, clientReferenceNumberOverride },
});

export const syncEAPSuccess = eapDetail => ({
  type: EAP_SYNC_SUCCESS,
  payload: eapDetail,
});

export const syncEAPError = error => ({
  type: EAP_SYNC_ERROR,
  payload: error,
});

export const savePlannedItemAttachment = payload => ({
  type: SAVE_EAP_PLAN_ITEM_ATTACHMENT,
  payload,
});

export const detectDifferenceEapDetail = payload => ({
  type: EAP_DETECT_DIFFERENCE,
  payload,
});

export const detectDifferenceEapDetailSuccess = payload => ({
  type: EAP_DETECT_DIFFERENCE_SUCCESS,
  payload,
});

export const clearEAPNotify = () => ({
  type: EAP_SYNC_CLEAR_NOTIFY,
});

export const getSSMUser = () => ({
  type: GET_SSM_USER,
});

export const getSSMUserSuccess = payload => ({
  type: GET_SSM_USER_SUCCESS,
  payload,
});
