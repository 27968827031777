import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../../helpers/auth-header';
import axios from 'axios';
import Config from '../../../config';
import { buildUrl } from '../../../helpers/Utils';

import { GET_CASE_LOAD_REPORT } from './types';

import { getCaseLoadReportSuccess, getCaseLoadReportError } from './actions';

const getCaseLoadReportRequest = async criteria => {
  return await axios.post(
    `${Config.apiServerHost}/api/caseLoadReport/caseload-by-staff`,
    criteria,
    await authHeader()
  );
};

function* getCaseLoadReport({ payload }) {
  try {
    const response = yield call(getCaseLoadReportRequest, payload);
    yield put(getCaseLoadReportSuccess(response.data));
  } catch (error) {
    yield put(getCaseLoadReportError(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_CASE_LOAD_REPORT, getCaseLoadReport);
}
