// EAP
export const GET_EAP_LIST = 'GET_EAP_LIST';
export const GET_EAP_LIST_SUCCESS = 'GET_EAP_LIST_SUCCESS';
export const GET_EAP_DOCUMENT_LIST = 'GET_EAP_DOCUMENT_LIST';
export const GET_EAP_DOCUMENT_LIST_SUCCESS = 'GET_EAP_DOCUMENT_LIST_SUCCESS';

export const GET_SSM_USER = 'GET_SSM_USER';
export const GET_SSM_USER_SUCCESS = 'GET_SSM_USER_SUCCESS';

export const EAP_GET_BY_ID = 'EAP_GET_BY_ID';
export const EAP_GET_BY_ID_SUCCESS = 'EAP_GET_BY_ID_SUCCESS';
export const EAP_GET_BY_ID_ERROR = 'EAP_GET_BY_ID_ERROR';
export const SAVE_EAP_PLAN_ITEM_ATTACHMENT = 'SAVE_EAP_PLAN_ITEM_ATTACHMENT';

export const EAP_UPDATE = 'EAP_UPDATE';
export const EAP_UPDATE_SUCCESS = 'EAP_UPDATE_SUCCESS';
export const EAP_UPDATE_ERROR = 'EAP_UPDATE_ERROR';

export const GET_REFRESH_EAP_LIST = 'GET_REFRESH_EAP_LIST';
export const GET_REFRESH_EAP_LIST_SUCCESS = 'GET_REFRESH_EAP_LIST_SUCCESS';

export const GET_REFRESH_EAP_ATTACHMENT_LIST = 'GET_REFRESH_EAP_ATTACHMENT_LIST';
export const GET_REFRESH_EAP_ATTACHMENT_LIST_SUCCESS = 'GET_REFRESH_EAP_ATTACHMENT_LIST_SUCCESS';

export const EAP_DETECT_DIFFERENCE = 'EAP_DETECT_DIFFERENCE';
export const EAP_DETECT_DIFFERENCE_SUCCESS = 'EAP_DETECT_DIFFERENCE_SUCCESS';

export const EAP_SYNC = 'EAP_SYNC';
export const EAP_SYNC_SUCCESS = 'EAP_SYNC_SUCCESS';
export const EAP_SYNC_ERROR = 'EAP_SYNC_ERROR';
export const EAP_SYNC_CLEAR_NOTIFY = 'EAP_SYNC_CLEAR_NOTIFY';
