import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import trainerSagas from './employers/saga';
import employerSagas from './employers/employer-saga';
import contactSagas from './contact/saga';
import clientSagas from './client/saga';
import actionPlanSaga from './action-plan/saga';
import activitySaga from './activity/saga';
import eventSaga from './event/saga';
import jobSeekerSaga from './job-seeker/saga';
import cojgContractSagas from './contract/cojg/saga';
import chequeSagas from './contract/cojg/cheque-saga';
import budgetSagas from './contract/budget/saga';
import geographySagas from './geography/saga';
import shiftsSagas from './shifts/saga';
import jobtypeSagas from './job-type/saga';
import sectorSagas from './sector/saga';
import cojgFinancialSagas from './report/cojg/saga';
import contractsPaidSagas from './report/contracts-paid/saga';
import cojgFundSagas from './report/cojg-fund/saga';
import cojgReconciliationSagas from './report/cojg-reconciliation/saga';
import esContractReportSagas from './report/es/saga';
import allESContractOustandingSagas from './report/all-es-contract-oustanding/saga';
import roleSagas from './role/saga';
import userSagas from './user/saga';
import assessementSaga from './assessement/saga';
import clientAggregateSaga from './client-aggregate/saga';
import aggregateSaga from './aggregate/saga';
import notificationReportSagas from './report/notification/saga';
import clientFundSaga from './client-fund/saga';
import caseNote2Saga from './case-note2/saga';
import resumeSaga from './resume/saga';
import camsImportSaga from './cams-import/saga';
import employerCaseNoteSaga from './employer-case-note/saga';
import todoSagas from './todo/saga';
import caseSummarySaga from './case-summary/saga';
import mileageSaga from './mileage/saga';
import expenseSaga from './expense/saga';
import mileageEntrySaga from './mileage-entry/saga';
import expenseEntrySaga from './expense-entry/saga';
import mileageSettingSaga from './mileage-setting/saga';
import clientFundSettingSaga from './client-fund-setting/saga';
import cojgCaseNoteSaga from './cojg-case-note/saga';
import seContractSaga from './se-contract/saga';
import esContractSaga from './es-contract/saga';
import iesContractSaga from './ies-contract/saga';
import documentSaga from './document/saga';
import settingSaga from './system-setting/saga';
import esChequeSaga from './es-cheque/saga';
import seChequeSaga from './se-cheque/saga';
import esCaseNoteSaga from './es-case-note/saga';
import seCaseNoteSaga from './se-case-note/saga';
import mileageSupervisorSaga from './mileage-supervisor/saga';
import eventCalendarSaga from './event-calendar/saga';
import formDocumentSaga from './form-document/saga';
import additionalInfoSaga from './additional-info/saga';
import siteSaga from './site/saga';
import employmentEarningSaga from './employment-earning/saga';
import postingSaga from './posting/saga';
import eventParticiapntSaga from './event-participant/saga';
import cecContactSaga from './cec-contact/saga';
import cecListSaga from './cec-list/saga';
import yjcContractSaga from './yjc-contract/saga';
import yjcChequeSaga from './yjc-cheque/saga';
import yjcsContractSaga from './yjcs-contract/saga';
import yjcsChequeSaga from './yjcs-cheque/saga';
import prospectReceiveNotificationSaga from './prospect-receive-notification/saga';
import servicePlanSaga from './service-plan/saga';
import alertSaga from './alert/saga';
import referralSaga from './referral/saga';
import clientFundSupervisorSaga from './client-fund-supervisor/saga';
import employmentActionPlanSaga from './employment-action-plan/saga';
import clientSupportSaga from './client-support/saga';
import employerDocumentSaga from './employer-document/saga';
import outreachSaga from './outreach/saga';
import outcomesSaga from './outcomes/saga';
import subGoalSaga from './sub-goal/saga';
import clientSummarySaga from './client-summary/saga';
import financialTrackingSaga from './financial-tracking/saga';
import jobMatchingSaga from './job-matching/saga';
import interventionFollowupIESSaga from './intervention-followup-ies/saga';
import prospectSaga from './prospect/saga';
import eidmSaga from './eidm/saga';
import subGoalLBSSaga from './subgoal-lbs/saga';
import clientSummaryLBSSaga from './client-summary-lbs/saga';
import clientTSASaga from './client-tsa/saga';
import ClientReferralSaga from './client-referral/saga';
import ClientCourseSaga from './client-course/saga';
import planContentSaga from './plan-content/saga';
import cojgContractDocumentSaga from './cojg-contract-document/saga';
import clientSummaryYJCSaga from './client-summary-yjc/saga';
import applicantSaga from './applicant/saga';
import caseAuditSaga from './case-audit/saga';
import clientInfoSaga from './client-info/saga';
import eapSaga from './eap/saga';
import caReferralSaga from './ca-referral/saga';
import outcomeQuestionairSaga from './outcome-questionaire/saga';
import outcomeCheckpointSaga from './outcome-checkpoint/saga';
import camsSaga from './cams/saga';
import employerSaga from './employer-info/saga';
import clientJobMatchSaga from './client-job-match/saga';
import clientNewMatchingSaga from './client-new-matching/saga';
import postingSentToClientSaga from './posting-sent-to-client/saga';
import postingNewMatchingSaga from './posting-new-matching/saga';
import postingMatchingSentToClientSaga from './posting-matching-sent-to-client/saga';
import taskManagementSaga from './task-management/saga';
import noteSaga from './note/saga';
import invitationSaga from './invitation/saga';
import esuiteSaga from './esuite/saga';
import esuiteActivitySaga from './esuite-activity/saga';
import transactionNotificationSaga from './transaction-notification/saga';
import iesDashboardSaga from './ies-dashboard/saga';
import iesChartDetailReportSaga from './ies-chart-detail-report/saga';
import targetSchemeSaga from './target-scheme/saga';
import userNotificationSaga from './user-notification/saga';
import interventionSaga from './intervention/saga';
import jobSeekerRetainedEmployerSaga from './job-seeker-retained-employer/saga';
import actionPlanForStaffSaga from './action-plan-for-staff/saga';
import actionPlanningToolSaga from './action-planning-tool/saga';
import jmpiMatrixSaga from './jmpi-matrix/saga';
import stakeHolderSaga from './stake-holder/saga';
import stakeHolderContactSaga from './stake-holder-contact/saga';
import stakeHolderEmailSaga from './stake-holder-email/saga';
import stakeHolderActionSaga from './stake-holder-action/saga';
import stakeHolderMeetingNoteSaga from './stake-holder-meeting-note/saga';
import stakeHolderDocumentSaga from './stake-holder-document/saga';
import communityPartnerSaga from './community-partner/saga';
import emailTemplateSaga from './email-template/saga';
import locationSaga from './location/saga';
import campaginSaga from './campaign/saga';
import campdashboardSaga from './camp-dashboard/saga';
import trfCaReferralSaga from './trf-ca-referral/saga';
import weeklyReportSaga from './report/ies-weekly/saga';
import caseLoadReportSaga from './report/ies-caseload/saga';
import budgetTrackingSaga from './budget-tracking/saga';
import cpReferrerSaga from './cp-referrer/saga';
import surveyNotificationSaga from './survey-notification-setting/saga';
import workshopEventSaga from './workshop-event/saga';
import userDocumentSaga from './user-document/saga';
import journeyAckSaga from './journey-ack/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    trainerSagas(),
    employerSagas(),
    contactSagas(),
    clientSagas(),
    actionPlanSaga(),
    activitySaga(),
    eventSaga(),
    jobSeekerSaga(),
    esContractSaga(),
    iesContractSaga(),
    cojgContractSagas(),
    chequeSagas(),
    budgetSagas(),
    geographySagas(),
    shiftsSagas(),
    jobtypeSagas(),
    sectorSagas(),
    cojgFinancialSagas(),
    contractsPaidSagas(),
    cojgFundSagas(),
    cojgReconciliationSagas(),
    esContractReportSagas(),
    allESContractOustandingSagas(),
    roleSagas(),
    userSagas(),
    assessementSaga(),
    clientAggregateSaga(),
    aggregateSaga(),
    notificationReportSagas(),
    clientFundSaga(),
    caseNote2Saga(),
    resumeSaga(),
    camsImportSaga(),
    employerCaseNoteSaga(),
    todoSagas(),
    caseSummarySaga(),
    mileageSaga(),
    expenseSaga(),
    mileageEntrySaga(),
    expenseEntrySaga(),
    mileageSettingSaga(),
    clientFundSettingSaga(),
    cojgCaseNoteSaga(),
    seContractSaga(),
    documentSaga(),
    settingSaga(),
    esChequeSaga(),
    seChequeSaga(),
    esCaseNoteSaga(),
    seCaseNoteSaga(),
    mileageSupervisorSaga(),
    eventCalendarSaga(),
    formDocumentSaga(),
    additionalInfoSaga(),
    siteSaga(),
    employmentEarningSaga(),
    postingSaga(),
    eventParticiapntSaga(),
    cecContactSaga(),
    cecListSaga(),
    yjcContractSaga(),
    yjcChequeSaga(),
    yjcsContractSaga(),
    yjcsChequeSaga(),
    prospectReceiveNotificationSaga(),
    servicePlanSaga(),
    alertSaga(),
    referralSaga(),
    clientFundSupervisorSaga(),
    employmentActionPlanSaga(),
    clientSupportSaga(),
    employerDocumentSaga(),
    outreachSaga(),
    outcomesSaga(),
    subGoalSaga(),
    clientSummarySaga(),
    financialTrackingSaga(),
    jobMatchingSaga(),
    interventionFollowupIESSaga(),
    prospectSaga(),
    eidmSaga(),
    subGoalLBSSaga(),
    clientSummaryLBSSaga(),
    clientTSASaga(),
    ClientReferralSaga(),
    ClientCourseSaga(),
    planContentSaga(),
    cojgContractDocumentSaga(),
    clientSummaryYJCSaga(),
    applicantSaga(),
    caseAuditSaga(),
    clientJobMatchSaga(),
    clientNewMatchingSaga(),
    postingSentToClientSaga(),
    postingNewMatchingSaga(),
    postingMatchingSentToClientSaga(),
    noteSaga(),
    taskManagementSaga(),
    clientInfoSaga(),
    eapSaga(),
    caReferralSaga(),
    outcomeQuestionairSaga(),
    outcomeCheckpointSaga(),
    camsSaga(),
    employerSaga(),
    invitationSaga(),
    esuiteSaga(),
    esuiteActivitySaga(),
    transactionNotificationSaga(),
    iesDashboardSaga(),
    iesChartDetailReportSaga(),
    targetSchemeSaga(),
    userNotificationSaga(),
    interventionSaga(),
    jobSeekerRetainedEmployerSaga(),
    actionPlanForStaffSaga(),
    actionPlanningToolSaga(),
    jmpiMatrixSaga(),
    stakeHolderSaga(),
    stakeHolderContactSaga(),
    stakeHolderEmailSaga(),
    stakeHolderActionSaga(),
    stakeHolderMeetingNoteSaga(),
    stakeHolderDocumentSaga(),
    communityPartnerSaga(),
    emailTemplateSaga(),
    locationSaga(),
    campaginSaga(),
    campdashboardSaga(),
    trfCaReferralSaga(),
    weeklyReportSaga(),
    caseLoadReportSaga(),
    budgetTrackingSaga(),
    cpReferrerSaga(),
    surveyNotificationSaga(),
    workshopEventSaga(),
    userDocumentSaga(),
    journeyAckSaga(),
  ]);
}
