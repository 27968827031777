import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import axios from 'axios';
import Config from '../../config';

import {
  ES_CONTRACT_GET_LIST,
  ES_CONTRACT_ADD_ITEM,
  ES_CONTRACT_EDIT_ITEM,
  ES_CONTRACT_DELETE_ITEM,
  ES_CONTRACT_GET_BY_ID,
} from '../actions';

import {
  getESContractListSuccess,
  getESContractListError,
  addESContractItemSuccess,
  addESContractItemError,
  editESContractItemSuccess,
  editESContractItemError,
  deleteESContractItemSuccess,
  deleteESContractItemError,
  getESContractByIdSuccess,
  getESContractByIdError,
} from './actions';

const getESContractsRequest = async () => {
  return await axios.get(`${Config.apiServerHost}/api/ESContract`, await authHeader());
};

const getEsContactPagingRequest = async query => {
  return await axios.post(`${Config.apiServerHost}/api/ESContract/paging`, query, {
    headers: { ...(await authHeader()).headers },
  });
};

const getESContractRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/ESContract/${id}`, await authHeader());
};

const addNewESContractRequest = async data => {
  return await axios.post(`${Config.apiServerHost}/api/ESContract`, data, await authHeader());
};

const editESContractRequest = async data => {
  return await axios.put(`${Config.apiServerHost}/api/ESContract`, data, await authHeader());
};

const deleteESContractRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/ESContract/${id}`, await authHeader());
};

const getEmployerIncentiveAndClientSupportRequest = async () => {
  return await axios.get(
    `${Config.apiServerHost}/api/ESContract/employerIncentiveAndClientSupport`,
    await authHeader()
  );
};

const validateTransferToCaMSRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/ESContract/${id}/validateTransferToCaMs`,
    await authHeader()
  );
};

function* getESContractById({ payload }) {
  try {
    const response = yield call(getESContractRequest, payload);
    yield put(getESContractByIdSuccess(response.data));
  } catch (error) {
    yield put(getESContractByIdError(error));
  }
}

function* getESContractList({ payload }) {
  try {
    const response = yield call(getEsContactPagingRequest, payload);
    yield put(getESContractListSuccess(response.data));
  } catch (error) {
    yield put(getESContractListError(error));
  }
}

function* addNewESContract({ payload }) {
  try {
    const { item, history } = payload;
    const { programId } = item;

    if (history) {
      yield call(addNewESContractRequest, item);
      if (programId && Number(programId) === 14) {
        history.push('/app/report/ies-placement');
      } else {
        history.push('/app/contract/es');
      }
    } else {
      const { data, programId, clientId, employerId, servicePlanHeaderId, siteIds } = item;

      let response = yield call(addNewESContractRequest, data);
      yield put(addESContractItemSuccess(response.data));

      response = yield call(getEsContactPagingRequest, {
        programId,
        clientId,
        employerId,
        servicePlanHeaderId,
        siteIds,
      });

      yield put(getESContractListSuccess(response.data));
    }
  } catch (error) {
    yield put(addESContractItemError(error));
  }
}

function* editESContract({ payload }) {
  try {
    const { item, history } = payload;
    const { programId } = item;
    if (history) {
      yield call(editESContractRequest, item);

      if (programId && Number(programId) === 14) {
        history.push('/app/report/ies-placement');
      } else {
        history.push('/app/contract/es');
      }
    } else {
      const { data, programId, clientId, employerId, servicePlanHeaderId, siteIds } = item;
      let response = yield call(editESContractRequest, data);
      yield put(editESContractItemSuccess(response.data));

      response = yield call(getEsContactPagingRequest, {
        programId,
        clientId,
        employerId,
        servicePlanHeaderId,
        siteIds,
      });
      yield put(getESContractListSuccess(response.data));
    }
  } catch (error) {
    yield put(editESContractItemError(error));
  }
}

function* deleteESContract({ payload }) {
  try {
    const { clientId, id, history, programId, employerId, servicePlanHeaderId, siteIds } = payload;

    yield call(deleteESContractRequest, id);

    if (history) {
      if (programId && Number(programId) === 14) {
        history.push('/app/report/ies-placement');
      } else {
        history.push('/app/contract/es');
      }
    } else {
      const response = yield call(getEsContactPagingRequest, {
        programId,
        clientId,
        employerId,
        servicePlanHeaderId,
        siteIds,
      });
      yield put(getESContractListSuccess(response.data));
    }
  } catch (error) {
    yield put(deleteESContractItemError(error));
  }
}

export function* watchSEContactList() {
  yield takeEvery(ES_CONTRACT_GET_LIST, getESContractList);
}

export function* watchSEContactGetById() {
  yield takeEvery(ES_CONTRACT_GET_BY_ID, getESContractById);
}

export function* watchESContractAddNew() {
  yield takeEvery(ES_CONTRACT_ADD_ITEM, addNewESContract);
}
export function* watchESContractEdit() {
  yield takeEvery(ES_CONTRACT_EDIT_ITEM, editESContract);
}
export function* watchESContractDelete() {
  yield takeEvery(ES_CONTRACT_DELETE_ITEM, deleteESContract);
}

export default function* rootSaga() {
  yield all([
    fork(watchSEContactList),
    fork(watchSEContactGetById),
    fork(watchESContractAddNew),
    fork(watchESContractEdit),
    fork(watchESContractDelete),
  ]);
}

export {
  addNewESContractRequest,
  editESContractRequest,
  getESContractRequest,
  getESContractsRequest,
  deleteESContractRequest,
  getEmployerIncentiveAndClientSupportRequest,
  getEsContactPagingRequest,
  validateTransferToCaMSRequest,
};
