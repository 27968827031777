/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const RECONNECT_USER = 'RECONNECT_USER';
export const RECONNECTING_USER = 'RECONNECTING_USER';
export const RECONNECTING_USER_DONE = 'RECONNECTING_USER_DONE';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const VERIFY_CODE = 'VERIFY_CODE';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_ERROR = 'VERIFY_CODE_ERROR';
export const USER_CLOSE_ERROR_MESSAGE = 'USER_CLOSE_ERROR_MESSAGE';
export const UPDATE_USER_SETTING = 'UPDATE_USER_SETTING';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* TRAINERAPP*/
export const TRAINER_GET_LIST = 'TRAINER_GET_LIST';
export const TRAINER_GET_LIST_SUCCESS = 'TRAINER_GET_LIST_SUCCESS';
export const TRAINER_GET_LIST_ERROR = 'TRAINER_GET_LIST_ERROR';
export const TRAINER_GET_LIST_WITH_FILTER = 'TRAINER_GET_LIST_WITH_FILTER';
export const TRAINER_GET_LIST_WITH_ORDER = 'TRAINER_GET_LIST_WITH_ORDER';
export const TRAINER_GET_LIST_SEARCH = 'TRAINER_GET_LIST_SEARCH';
export const TRAINER_ADD_ITEM = 'TRAINER_ADD_ITEM';
export const TRAINER_ADD_ITEM_SUCCESS = 'TRAINER_ADD_ITEM_SUCCESS';
export const TRAINER_ADD_ITEM_ERROR = 'TRAINER_ADD_ITEM_ERROR';
export const TRAINER_EDIT_ITEM = 'TRAINER_EDIT_ITEM';
export const TRAINER_EDIT_ITEM_ERROR = 'TRAINER_EDIT_ITEM_ERROR';
export const TRAINER_EDIT_ITEM_SUCCESS = 'TRAINER_EDIT_ITEM_SUCCESS';
export const TRAINER_DELETE_ITEM = 'TRAINER_DELETE_ITEM';
export const TRAINER_DELETE_ITEM_ERROR = 'TRAINER_DELETE_ITEM_ERROR';
export const TRAINER_DELETE_ITEM_SUCCESS = 'TRAINER_DELETE_ITEM_SUCCESS';
export const TRAINER_SELECTED_ITEMS_CHANGE = 'TRAINER_SELECTED_ITEMS_CHANGE';

/* CONTACT*/
export const CONTACT_QUERY = 'CONTACT_QUERY';
export const CONTACT_QUERY_SUCCESS = 'CONTACT_QUERY_SUCCESS';
export const CONTACT_QUERY_ERROR = 'CONTACT_QUERY_ERROR';

export const CONTACT_GET_LIST = 'CONTACT_GET_LIST';
export const CONTACT_GET_LIST_SUCCESS = 'CONTACT_GET_LIST_SUCCESS';
export const CONTACT_GET_LIST_ERROR = 'CONTACT_GET_LIST_ERROR';

export const CONTACT_GET_BY_ID = 'CONTACT_GET_BY_ID';
export const CONTACT_GET_BY_ID_SUCCESS = 'CONTACT_GET_BY_ID_SUCCESS';
export const CONTACT_GET_BY_ID_ERROR = 'CONTACT_GET_BY_ID_ERROR';

export const CONTACT_ADD_NEW = 'CONTACT_ADD_NEW';
export const CONTACT_ADD_NEW_SUCCESS = 'CONTACT_ADD_NEW_SUCCESS';
export const CONTACT_ADD_NEW_ERROR = 'CONTACT_ADD_NEW_ERROR';
export const CONTACT_ADD_NEW_REQUEST = 'CONTACT_ADD_NEW_REQUEST';

export const CONTACT_EDIT = 'CONTACT_EDIT';
export const CONTACT_EDIT_SUCCESS = 'CONTACT_EDIT_SUCCESS';
export const CONTACT_EDIT_ERROR = 'CONTACT_EDIT_ERROR';

export const CONTACT_DELETE = 'CONTACT_DELETE';
export const CONTACT_DELETE_SUCCESS = 'CONTACT_DELETE_SUCCESS';
export const CONTACT_DELETE_ERROR = 'CONTACT_DELETE_ERROR';

/* CLIENT */
export const CLIENT_GET_LIST = 'CLIENT_GET_LIST';
export const CLIENT_GET_LIST_SUCCESS = 'CLIENT_GET_LIST_SUCCESS';
export const CLIENT_GET_LIST_ERROR = 'CLIENT_GET_LIST_ERROR';

export const CLIENT_GET_BY_ID = 'CLIENT_GET_BY_ID';
export const CLIENT_GET_BY_ID_SUCCESS = 'CLIENT_GET_BY_ID_SUCCESS';
export const CLIENT_GET_BY_ID_ERROR = 'CLIENT_GET_BY_ID_ERROR';

export const CLIENT_ADD_NEW = 'CLIENT_ADD_NEW';
export const CLIENT_ADD_NEW_SUCCESS = 'CLIENT_ADD_NEW_SUCCESS';
export const CLIENT_ADD_NEW_ERROR = 'CLIENT_ADD_NEW_ERROR';

export const CLIENT_EDIT = 'CLIENT_EDIT';
export const CLIENT_EDIT_SUCCESS = 'CLIENT_EDIT_SUCCESS';
export const CLIENT_EDIT_ERROR = 'CLIENT_EDIT_ERROR';
export const CLIENT_EDIT_REQUEST = 'CLIENT_EDIT_REQUEST';

export const CLIENT_DELETE = 'CLIENT_DELETE';
export const CLIENT_DELETE_REQUEST = 'CLIENT_DELETE_REQUEST';
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS';
export const CLIENT_DELETE_ERROR = 'CLIENT_DELETE_ERROR';

export const CLIENT_FILTER = 'CLIENT_FILTER';
export const CLIENT_FILTER_SUCCESS = 'CLIENT_FILTER_SUCCESS';
export const CLIENT_FILTER_ERROR = 'CLIENT_FILTER_ERROR';

export const CLIENT_MANAGER_VIEW_UPDATE = 'CLIENT_MANAGER_VIEW_UPDATE';
export const CLIENT_COMPLETED_RESULT = 'CLIENT_COMPLETED_RESULT';

export const GENERATE_CLIENT_REPORT = 'GENERATE_CLIENT_REPORT';
export const GENERATE_CLIENT_REPORT_SUCCESS = 'GENERATE_CLIENT_REPORT_SUCCESS';
export const GENERATE_CLIENT_REPORT_ERROR = 'GENERATE_CLIENT_REPORT_ERROR';

export const GENERATE_PERFORMANCE_INDICATOR_REPORT = 'GENERATE_PERFORMANCE_INDICATOR_REPORT';
export const GENERATE_PERFORMANCE_INDICATOR_REPORT_SUCCESS =
  'GENERATE_PERFORMANCE_INDICATOR_REPORT_SUCCESS';
export const GENERATE_PERFORMANCE_INDICATOR_REPORT_ERROR =
  'GENERATE_PERFORMANCE_INDICATOR_REPORT_ERROR';

export const CLIENT_FILE_ACIVITY_UPDATE = 'CLIENT_FILE_ACIVITY_UPDATE';
export const CLIENT_FILE_ACIVITY_UPDATE_SUCCESS = 'CLIENT_FILE_ACIVITY_UPDATE_SUCCESS';
export const CLIENT_FILE_ACIVITY_UPDATE_ERROR = 'CLIENT_FILE_ACIVITY_UPDATE_ERROR';

export const CLIENT_FILE_ACIVITY_HUB_UPDATE = 'CLIENT_FILE_ACIVITY_HUB_UPDATE';
export const CLIENT_FILE_ACIVITY_HUB_UPDATE_SUCCESS = 'CLIENT_FILE_ACIVITY_HUB_UPDATE_SUCCESS';
export const CLIENT_FILE_ACIVITY_HUB_UPDATE_ERROR = 'CLIENT_FILE_ACIVITY_HUB_UPDATE_ERROR';

export const GET_CLIENT_FILE_ACIVITY = 'GET_CLIENT_FILE_ACIVITY';
export const GET_CLIENT_FILE_ACIVITY_SUCCESS = 'GET_CLIENT_FILE_ACIVITY_SUCCESS';
export const GET_CLIENT_FILE_ACIVITY_ERROR = 'GET_CLIENT_FILE_ACIVITY_ERROR';

export const GET_CLIENT_FILE_ACIVITY_HUB = 'GET_CLIENT_FILE_ACIVITY_HUB';
export const GET_CLIENT_FILE_ACIVITY_HUB_SUCCESS = 'GET_CLIENT_FILE_ACIVITY_HUB_SUCCESS';
export const GET_CLIENT_FILE_ACIVITY_HUB_ERROR = 'GET_CLIENT_FILE_ACIVITY_HUB_ERROR';

export const UNSUBSCRIBED_CLIENT_DATA = 'UNSUBSCRIBED_CLIENT_DATA';

export const ADD_NEW_CLIENT_PARTICIPANT = 'ADD_NEW_CLIENT_PARTICIPANT';

export const SAVE_CLIENT_PARTICIPANT = 'SAVE_CLIENT_PARTICIPANT';
export const SAVE_CLIENT_PARTICIPANT_SUCCESS = 'SAVE_CLIENT_PARTICIPANT_SUCCESS';
export const SAVE_CLIENT_PARTICIPANT_ERROR = 'SAVE_CLIENT_PARTICIPANT_ERROR';

export const SAVE_CLIENT_PARTICIPANT_LBS = 'SAVE_CLIENT_PARTICIPANT_LBS';
export const SAVE_CLIENT_PARTICIPANT_LBS_SUCCESS = 'SAVE_CLIENT_PARTICIPANT_LBS_SUCCESS';
export const SAVE_CLIENT_PARTICIPANT_LBS_ERROR = 'SAVE_CLIENT_PARTICIPANT_LBS_ERROR';

export const SAVE_CLIENT_PARTICIPANT_ARI = 'SAVE_CLIENT_PARTICIPANT_ARI';
export const SAVE_CLIENT_PARTICIPANT_ARI_SUCCESS = 'SAVE_CLIENT_PARTICIPANT_ARI_SUCCESS';
export const SAVE_CLIENT_PARTICIPANT_ARI_ERROR = 'SAVE_CLIENT_PARTICIPANT_ARI_ERROR';

export const SAVE_CLIENT_PARTICIPANT_HUB = 'SAVE_CLIENT_PARTICIPANT_HUB';
export const SAVE_CLIENT_PARTICIPANT_HUB_SUCCESS = 'SAVE_CLIENT_PARTICIPANT_HUB_SUCCESS';
export const SAVE_CLIENT_PARTICIPANT_HUB_ERROR = 'SAVE_CLIENT_PARTICIPANT_HUB_ERROR';

export const GET_CLIENT_PARTICIPANT_HUB = 'GET_CLIENT_PARTICIPANT_HUB';
export const GET_CLIENT_PARTICIPANT_HUB_SUCCESS = 'GET_CLIENT_PARTICIPANT_HUB_SUCCESS';
export const GET_CLIENT_PARTICIPANT_HUB_ERROR = 'GET_CLIENT_PARTICIPANT_HUB_ERROR';

export const GET_CLIENT_PARTICIPANT_ARI = 'GET_CLIENT_PARTICIPANT_ARI';
export const GET_CLIENT_PARTICIPANT_ARI_SUCCESS = 'GET_CLIENT_PARTICIPANT_ARI_SUCCESS';
export const GET_CLIENT_PARTICIPANT_ARI_ERROR = 'GET_CLIENT_PARTICIPANT_ARI_ERROR';

export const GET_CLIENT_PARTICIPANT_LBS = 'GET_CLIENT_PARTICIPANT_LBS';
export const GET_CLIENT_PARTICIPANT_LBS_SUCCESS = 'GET_CLIENT_PARTICIPANT_LBS_SUCCESS';
export const GET_CLIENT_PARTICIPANT_LBS_ERROR = 'GET_CLIENT_PARTICIPANT_LBS_ERROR';

export const SAVE_STUDENT_CHECKLIST_LBS = 'SAVE_STUDENT_CHECKLIST_LBS';
export const SAVE_STUDENT_CHECKLIST_LBS_SUCCESS = 'SAVE_STUDENT_CHECKLIST_LBS_SUCCESS';
export const SAVE_STUDENT_CHECKLIST_LBS_ERROR = 'SAVE_STUDENT_CHECKLIST_LBS_ERROR';

export const GET_STUDENT_CHECKLIST_LBS = 'GET_STUDENT_CHECKLIST_LBS';
export const GET_STUDENT_CHECKLIST_LBS_SUCCESS = 'GET_STUDENT_CHECKLIST_LBS_SUCCESS';
export const GET_STUDENT_CHECKLIST_LBS_ERROR = 'GET_STUDENT_CHECKLIST_LBS_ERROR';

export const GET_CLIENT_PARTICIPANT = 'GET_CLIENT_PARTICIPANT';
export const GET_CLIENT_PARTICIPANT_SUCCESS = 'GET_CLIENT_PARTICIPANT_SUCCESS';
export const GET_CLIENT_PARTICIPANT_ERROR = 'GET_CLIENT_PARTICIPANT_ERROR';

export const GET_CLIENT_SERVICEPLANS = 'GET_CLIENT_SERVICEPLANS';
export const GET_CLIENT_SERVICEPLANS_SUCCESS = 'GET_CLIENT_SERVICEPLANS_SUCCESS';
export const GET_CLIENT_SERVICEPLANS_ERROR = 'GET_CLIENT_SERVICEPLANS_ERROR';

export const UPDATE_CLIENT_SERVICEPLAN = 'UPDATE_CLIENT_SERVICEPLAN';
export const UPDATE_CLIENT_SERVICEPLAN_SUCCESS = 'UPDATE_CLIENT_SERVICEPLAN_SUCCESS';
export const UPDATE_CLIENT_SERVICEPLAN_ERROR = 'UPDATE_CLIENT_SERVICEPLAN_ERROR';

export const GET_CLIENT_SERVICEPLAN = 'GET_CLIENT_SERVICEPLAN';
export const GET_CLIENT_SERVICEPLAN_SUCCESS = 'GET_CLIENT_SERVICEPLAN_SUCCESS';
export const GET_CLIENT_SERVICEPLAN_ERROR = 'GET_CLIENT_SERVICEPLAN_ERROR';

export const ADD_CLIENT_SERVICEPLANS = 'ADD_CLIENT_SERVICEPLANS';
export const ADD_CLIENT_SERVICEPLANS_SUCCESS = 'ADD_CLIENT_SERVICEPLANS_SUCCESS';
export const ADD_CLIENT_SERVICEPLANS_ERROR = 'ADD_CLIENT_SERVICEPLANS_ERROR';

export const SAVE_CLIENT_ASSESSMENT = 'SAVE_CLIENT_ASSESSMENT';
export const SAVE_CLIENT_ASSESSMENT_SUCCESS = 'SAVE_CLIENT_ASSESSMENT_SUCCESS';
export const SAVE_CLIENT_ASSESSMENT_ERROR = 'SAVE_CLIENT_ASSESSMENT_ERROR';

export const SAVE_CLIENT_SPECIFIC_ASSESSMENT = 'SAVE_CLIENT_SPECIFIC_ASSESSMENT';
export const SAVE_CLIENT_SPECIFIC_ASSESSMENT_SUCCESS = 'SAVE_CLIENT_SPECIFIC_ASSESSMENT_SUCCESS';
export const SAVE_CLIENT_SPECIFIC_ASSESSMENT_ERROR = 'SAVE_CLIENT_SPECIFIC_ASSESSMENT_ERROR';

export const SAVE_CLIENT_ASSESSMENT_LBS = 'SAVE_CLIENT_ASSESSMENT_LBS';
export const SAVE_CLIENT_ASSESSMENT_LBS_SUCCESS = 'SAVE_CLIENT_ASSESSMENT_LBS_SUCCESS';
export const SAVE_CLIENT_ASSESSMENT_LBS_ERROR = 'SAVE_CLIENT_ASSESSMENT_LBS_ERROR';

export const GET_CLIENT_ASSESSMENT_LBS = 'GET_CLIENT_ASSESSMENT_LBS';
export const GET_CLIENT_ASSESSMENT_LBS_SUCCESS = 'GET_CLIENT_ASSESSMENT_LBS_SUCCESS';
export const GET_CLIENT_ASSESSMENT_LBS_ERROR = 'GET_CLIENT_ASSESSMENT_LBS_ERROR';

export const GET_CLIENT_ASSESSMENT = 'GET_CLIENT_ASSESSMENT';
export const GET_CLIENT_ASSESSMENT_SUCCESS = 'GET_CLIENT_ASSESSMENT_SUCCESS';
export const GET_CLIENT_ASSESSMENT_ERROR = 'GET_CLIENT_ASSESSMENT_ERROR';

export const GET_CLIENT_SPECIFIC_ASSESSMENT = 'GET_CLIENT_SPECIFIC_ASSESSMENT';
export const GET_CLIENT_SPECIFIC_ASSESSMENT_SUCCESS = 'GET_CLIENT_SPECIFIC_ASSESSMENT_SUCCESS';
export const GET_CLIENT_SPECIFIC_ASSESSMENT_ERROR = 'GET_CLIENT_SPECIFIC_ASSESSMENT_ERROR';

export const CLIENT_CLOSED_NOTIFICATION_UPDATE = 'CLIENT_CLOSED_NOTIFICATION_UPDATE';
export const CLIENT_CLOSED_NOTIFICATION_ERROR = 'CLIENT_CLOSED_NOTIFICATION_ERROR';

export const CLIENT_ERROR_REPORT = 'CLIENT_ERROR_REPORT';
export const CLIENT_ERROR_REPORT_SUCCESS = 'CLIENT_ERROR_REPORT_SUCCESS';
export const CLIENT_ERROR_REPORT_ERROR = 'CLIENT_ERROR_REPORT_ERROR';

export const CLIENT_SAVE_LEARNER_PLAN = 'CLIENT_SAVE_LEARNER_PLAN';
export const CLIENT_SAVE_LEARNER_PLAN_SUCCESS = 'CLIENT_SAVE_LEARNER_PLAN_SUCCESS';
export const CLIENT_SAVE_LEARNER_PLAN_ERROR = 'CLIENT_SAVE_LEARNER_PLAN_ERROR';

export const CLIENT_SAVE_LEARNER_PLAN_LBS = 'CLIENT_SAVE_LEARNER_PLAN_LBS';
export const CLIENT_SAVE_LEARNER_PLAN_LBS_SUCCESS = 'CLIENT_SAVE_LEARNER_PLAN_LBS_SUCCESS';
export const CLIENT_SAVE_LEARNER_PLAN_LBS_ERROR = 'CLIENT_SAVE_LEARNER_PLAN_LBS_ERROR';

export const CLIENT_GET_LEARNER_PLAN = 'CLIENT_GET_LEARNER_PLAN';
export const CLIENT_GET_LEARNER_PLAN_SUCCESS = 'CLIENT_GET_LEARNER_PLAN_SUCCESS';
export const CLIENT_GET_LEARNER_PLAN_ERROR = 'CLIENT_GET_LEARNER_PLAN_ERROR';

export const GET_CLIENT_KPI = 'GET_CLIENT_KPI';
export const GET_CLIENT_KPI_SUCCESS = 'GET_CLIENT_KPI_SUCCESS';

export const GET_CLIENT_LEFT_SIDEBAR = 'GET_CLIENT_LEFT_SIDEBAR';
export const GET_CLIENT_LEFT_SIDEBAR_SUCCESS = 'GET_CLIENT_LEFT_SIDEBAR_SUCCESS';
export const GET_CLIENT_LEFT_SIDEBAR_ERROR = 'GET_CLIENT_LEFT_SIDEBAR_ERROR';

export const SAVE_CLIENT_COMMENT = 'SAVE_CLIENT_COMMENT';
export const SAVE_CLIENT_COMMENT_SUCCESS = 'SAVE_CLIENT_COMMENT_SUCCESS';
export const SAVE_CLIENT_COMMENT_ERROR = 'SAVE_CLIENT_COMMENT_ERROR';

/** CLIENT Document */

export const GET_DOCUMENTS_BY_CLIENT_ID = 'GET_DOCUMENTS_BY_CLIENT_ID';
export const GET_DOCUMENTS_BY_CLIENT_ID_SUCCESS = 'GET_DOCUMENTS_BY_CLIENT_ID_SUCCESS';
export const GET_DOCUMENTS_BY_CLIENT_ID_ERROR = 'GET_DOCUMENTS_BY_CLIENT_ID_ERROR';

export const GET_DOCUMENTS_BY_ID = 'GET_DOCUMENTS_BY_ID';
export const GET_DOCUMENTS_BY_ID_SUCCESS = 'GET_DOCUMENTS_BY_ID_SUCCESS';
export const GET_DOCUMENTS_BY_ID_ERROR = 'GET_DOCUMENTS_BY_ID_ERROR';

export const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS';
export const UPDATE_DOCUMENTS_SUCCESS = 'UPDATE_DOCUMENTS_SUCCESS';
export const UPDATE_DOCUMENTS_ERROR = 'UPDATE_DOCUMENTS_ERROR';

export const SAVE_DOCUMENTS = 'SAVE_DOCUMENTS';
export const SAVE_DOCUMENTS_SUCCESS = 'SAVE_DOCUMENTS_SUCCESS';
export const SAVE_DOCUMENTS_ERROR = 'SAVE_DOCUMENTS_ERROR';

export const DELETE_DOCUMENT_BY_ID = 'DELETE_DOCUMENT_BY_ID';
export const DELETE_DOCUMENT_BY_ID_SUCCESS = 'DELETE_DOCUMENT_BY_ID_SUCCESS';
export const DELETE_DOCUMENT_BY_ID_ERROR = 'DELETE_DOCUMENT_BY_ID_ERROR';

/** CASE NOTE2 CASE_NOTE2 */
export const CASE_NOTE2_QUERY = 'CASE_NOTE2_QUERY';
export const CASE_NOTE2_QUERY_SUCCESS = 'CASE_NOTE2_QUERY_SUCCESS';
export const CASE_NOTE2_QUERY_ERROR = 'CASE_NOTE2_QUERY_ERROR';

export const CASE_NOTE2_GET_BY_ID = 'CASE_NOTE2_GET_BY_ID';
export const CASE_NOTE2_GET_BY_ID_SUCCESS = 'CASE_NOTE2_GET_BY_ID_SUCCESS';
export const CASE_NOTE2_GET_BY_ID_ERROR = 'CASE_NOTE2_GET_BY_ID_ERROR';

export const CASE_NOTE2_ADD_NEW = 'CASE_NOTE2_ADD_NEW';
export const CASE_NOTE2_ADD_NEW_SUCCESS = 'CASE_NOTE2_ADD_NEW_SUCCESS';
export const CASE_NOTE2_ADD_NEW_ERROR = 'CASE_NOTE2_ADD_NEW_ERROR';
export const CASE_NOTE2_ADD_NEW_REQUEST = 'CASE_NOTE2_ADD_NEW_REQUEST';

export const CASE_NOTE2_EDIT = 'CASE_NOTE2_EDIT';
export const CASE_NOTE2_EDIT_SUCCESS = 'CASE_NOTE2_EDIT_SUCCESS';
export const CASE_NOTE2_EDIT_ERROR = 'CASE_NOTE2_EDIT_ERROR';

export const CASE_NOTE2_DELETE = 'CASE_NOTE2_DELETE';
export const CASE_NOTE2_DELETE_SUCCESS = 'CASE_NOTE2_DELETE_SUCCESS';
export const CASE_NOTE2_DELETE_ERROR = 'CASE_NOTE2_DELETE_ERROR';

export const CASE_NOTE2_AGGREGATE = 'CASE_NOTE2_AGGREGATE';
export const CASE_NOTE2_AGGREGATE_SUCCESS = 'CASE_NOTE2_AGGREGATE_SUCCESS';
export const CASE_NOTE2_AGGREGATE_ERROR = 'CASE_NOTE2_AGGREGATE_ERROR';

export const CASE_NOTE2_TOGGLE_MODAL_ADD = 'CASE_NOTE2_TOGGLE_MODAL_ADD';
export const CASE_NOTE2_TOGGLE_MODAL_EDIT = 'CASE_NOTE2_TOGGLE_MODAL_EDIT';
export const CASE_NOTE2_TOGGLE_MODAL_EDIT_EMP = 'CASE_NOTE2_TOGGLE_MODAL_EDIT_EMP';
export const CASE_NOTE2_TOGGLE_MODAL_DELETE = 'CASE_NOTE2_TOGGLE_MODAL_DELETE';
export const CASE_NOTE2_TOGGLE_MODAL_DELETE_EMP = 'CASE_NOTE2_TOGGLE_MODAL_DELETE_EMP';

export const CASE_NOTE2_NOTIFICATION_CLOSE = 'CASE_NOTE2_NOTIFICATION_CLOSE';

export const EMPLOYER_CASE_NOTE2_EDIT = 'EMPLOYER_CASE_NOTE2_EDIT';
export const EMPLOYER_CASE_NOTE2_EDIT_SUCCESS = 'EMPLOYER_CASE_NOTE2_EDIT_SUCCESS';
export const EMPLOYER_CASE_NOTE2_EDIT_ERROR = 'EMPLOYER_CASE_NOTE2_EDIT_ERROR';

export const EMPLOYER_CASE_NOTE2_GET_BY_ID = 'EMPLOYER_CASE_NOTE2_GET_BY_ID';
export const EMPLOYER_CASE_NOTE2_GET_BY_ID_SUCCESS = 'EMPLOYER_CASE_NOTE2_GET_BY_ID_SUCCESS';
export const EMPLOYER_CASE_NOTE2_GET_BY_ID_ERROR = 'EMPLOYER_CASE_NOTE2_GET_BY_ID_ERROR';
export const CASE_NOTE2_ERROR = 'CASE_NOTE2_ERROR';

/** Additional Info */
export const ADDITIONAL_INFO_GET_LIST = 'ADDITIONAL_INFO_GET_LIST';
export const ADDITIONAL_INFO_GET_LIST_SUCCESS = 'ADDITIONAL_INFO_GET_LIST_SUCCESS';
export const ADDITIONAL_INFO_GET_LIST_ERROR = 'ADDITIONAL_INFO_GET_LIST_ERROR';

export const ADDITIONAL_INFO_GET_BY_ID = 'ADDITIONAL_INFO_GET_BY_ID';
export const ADDITIONAL_INFO_GET_BY_ID_SUCCESS = 'ADDITIONAL_INFO_GET_BY_ID_SUCCESS';
export const ADDITIONAL_INFO_GET_BY_ID_ERROR = 'ADDITIONAL_INFO_GET_BY_ID_ERROR';

export const ADDITIONAL_INFO_ADD_NEW = 'ADDITIONAL_INFO_ADD_NEW';
export const ADDITIONAL_INFO_ADD_NEW_SUCCESS = 'ADDITIONAL_INFO_ADD_NEW_SUCCESS';
export const ADDITIONAL_INFO_ADD_NEW_ERROR = 'ADDITIONAL_INFO_ADD_NEW_ERROR';
export const ADDITIONAL_INFO_ADD_NEW_REQUEST = 'ADDITIONAL_INFO_ADD_NEW_REQUEST';

export const ADDITIONAL_INFO_EDIT = 'ADDITIONAL_INFO_EDIT';
export const ADDITIONAL_INFO_EDIT_SUCCESS = 'ADDITIONAL_INFO_EDIT_SUCCESS';
export const ADDITIONAL_INFO_EDIT_ERROR = 'ADDITIONAL_INFO_EDIT_ERROR';

export const ADDITIONAL_INFO_CLOSED_NOTIFICATION_UPDATE =
  'ADDITIONAL_INFO_CLOSED_NOTIFICATION_UPDATE';

/** ACTION PLAN */
export const ACTION_PLAN_GET_LIST = 'ACTION_PLAN_GET_LIST';
export const ACTION_PLAN_GET_LIST_SUCCESS = 'ACTION_PLAN_GET_LIST_SUCCESS';
export const ACTION_PLAN_GET_LIST_ERROR = 'ACTION_PLAN_GET_LIST_ERROR';

export const ACTION_PLAN_GET_BY_ID = 'ACTION_PLAN_GET_BY_ID';
export const ACTION_PLAN_GET_BY_ID_SUCCESS = 'ACTION_PLAN_GET_BY_ID_SUCCESS';
export const ACTION_PLAN_GET_BY_ID_ERROR = 'ACTION_PLAN_GET_BY_ID_ERROR';

export const ACTION_PLAN_ADD_NEW = 'ACTION_PLAN_ADD_NEW';
export const ACTION_PLAN_ADD_NEW_SUCCESS = 'ACTION_PLAN_ADD_NEW_SUCCESS';
export const ACTION_PLAN_ADD_NEW_ERROR = 'ACTION_PLAN_ADD_NEW_ERROR';
export const ACTION_PLAN_ADD_NEW_REQUEST = 'ACTION_PLAN_ADD_NEW_REQUEST';

export const ACTION_PLAN_STATUS_CHANGE = 'ACTION_PLAN_STATUS_CHANGE';
export const ACTION_PLAN_STATUS_CHANGE_SUCCESS = 'ACTION_PLAN_STATUS_CHANGE_SUCCESS';
export const ACTION_PLAN_STATUS_CHANGE_ERROR = 'ACTION_PLAN_STATUS_CHANGE_ERROR';

export const ACTION_PLAN_EDIT = 'ACTION_PLAN_EDIT';
export const ACTION_PLAN_EDIT_SUCCESS = 'ACTION_PLAN_EDIT_SUCCESS';
export const ACTION_PLAN_EDIT_ERROR = 'ACTION_PLAN_EDIT_ERROR';

export const ACTION_PLAN_DELETE = 'ACTION_PLAN_DELETE';
export const ACTION_PLAN_DELETE_SUCCESS = 'ACTION_PLAN_DELETE_SUCCESS';
export const ACTION_PLAN_DELETE_ERROR = 'ACTION_PLAN_DELETE_ERROR';

/** ALERT */
export const ALERT_GET_LIST = 'ALERT_GET_LIST';
export const ALERT_GET_LIST_SUCCESS = 'ALERT_GET_LIST_SUCCESS';
export const ALERT_GET_LIST_ERROR = 'ALERT_GET_LIST_ERROR';

export const ALERT_GET_BY_ID = 'ALERT_GET_BY_ID';
export const ALERT_GET_BY_ID_SUCCESS = 'ALERT_GET_BY_ID_SUCCESS';
export const ALERT_GET_BY_ID_ERROR = 'ALERT_GET_BY_ID_ERROR';

export const ALERT_ADD_NEW = 'ALERT_ADD_NEW';
export const ALERT_ADD_NEW_SUCCESS = 'ALERT_ADD_NEW_SUCCESS';
export const ALERT_ADD_NEW_ERROR = 'ALERT_ADD_NEW_ERROR';
export const ALERT_ADD_NEW_REQUEST = 'ALERT_ADD_NEW_REQUEST';

export const ALERT_STATUS_CHANGE = 'ALERT_STATUS_CHANGE';
export const ALERT_STATUS_CHANGE_SUCCESS = 'ALERT_STATUS_CHANGE_SUCCESS';
export const ALERT_STATUS_CHANGE_ERROR = 'ALERT_STATUS_CHANGE_ERROR';

export const ALERT_EDIT = 'ALERT_EDIT';
export const ALERT_EDIT_SUCCESS = 'ALERT_EDIT_SUCCESS';
export const ALERT_EDIT_ERROR = 'ALERT_EDIT_ERROR';

export const ALERT_DELETE = 'ALERT_DELETE';
export const ALERT_DELETE_SUCCESS = 'ALERT_DELETE_SUCCESS';
export const ALERT_DELETE_ERROR = 'ALERT_DELETE_ERROR';

/** SERVICE_PLAN */
export const SERVICE_PLAN_GET_LIST = 'SERVICE_PLAN_GET_LIST';
export const SERVICE_PLAN_GET_LIST_SUCCESS = 'SERVICE_PLAN_GET_LIST_SUCCESS';
export const SERVICE_PLAN_GET_LIST_ERROR = 'SERVICE_PLAN_GET_LIST_ERROR';

export const SERVICE_PLAN_GET_BY_ID = 'SERVICE_PLAN_GET_BY_ID';
export const SERVICE_PLAN_GET_BY_ID_SUCCESS = 'SERVICE_PLAN_GET_BY_ID_SUCCESS';
export const SERVICE_PLAN_GET_BY_ID_ERROR = 'SERVICE_PLAN_GET_BY_ID_ERROR';

export const SERVICE_PLAN_ADD_NEW = 'SERVICE_PLAN_ADD_NEW';
export const SERVICE_PLAN_ADD_NEW_SUCCESS = 'SERVICE_PLAN_ADD_NEW_SUCCESS';
export const SERVICE_PLAN_ADD_NEW_ERROR = 'SERVICE_PLAN_ADD_NEW_ERROR';
export const SERVICE_PLAN_ADD_NEW_REQUEST = 'SERVICE_PLAN_ADD_NEW_REQUEST';

export const SERVICE_PLAN_STATUS_CHANGE = 'SERVICE_PLAN_STATUS_CHANGE';
export const SERVICE_PLAN_STATUS_CHANGE_SUCCESS = 'SERVICE_PLAN_STATUS_CHANGE_SUCCESS';
export const SERVICE_PLAN_STATUS_CHANGE_ERROR = 'SERVICE_PLAN_STATUS_CHANGE_ERROR';

export const SERVICE_PLAN_EDIT = 'SERVICE_PLAN_EDIT';
export const SERVICE_PLAN_EDIT_SUCCESS = 'SERVICE_PLAN_EDIT_SUCCESS';
export const SERVICE_PLAN_EDIT_ERROR = 'SERVICE_PLAN_EDIT_ERROR';

export const SERVICE_PLAN_DELETE = 'SERVICE_PLAN_DELETE';
export const SERVICE_PLAN_DELETE_SUCCESS = 'SERVICE_PLAN_DELETE_SUCCESS';
export const SERVICE_PLAN_DELETE_ERROR = 'SERVICE_PLAN_DELETE_ERROR';

/** CEC_CONTACT */
export const CEC_CONTACT_GET_LIST = 'CEC_CONTACT_GET_LIST';
export const CEC_CONTACT_GET_LIST_SUCCESS = 'CEC_CONTACT_GET_LIST_SUCCESS';
export const CEC_CONTACT_GET_LIST_ERROR = 'CEC_CONTACT_GET_LIST_ERROR';

export const CEC_CONTACT_GET_BY_ID = 'CEC_CONTACT_GET_BY_ID';
export const CEC_CONTACT_GET_BY_ID_SUCCESS = 'CEC_CONTACT_GET_BY_ID_SUCCESS';
export const CEC_CONTACT_GET_BY_ID_ERROR = 'CEC_CONTACT_GET_BY_ID_ERROR';

export const CEC_CONTACT_ADD_NEW = 'CEC_CONTACT_ADD_NEW';
export const CEC_CONTACT_ADD_NEW_SUCCESS = 'CEC_CONTACT_ADD_NEW_SUCCESS';
export const CEC_CONTACT_ADD_NEW_ERROR = 'CEC_CONTACT_ADD_NEW_ERROR';
export const CEC_CONTACT_ADD_NEW_REQUEST = 'CEC_CONTACT_ADD_NEW_REQUEST';

export const CEC_CONTACT_STATUS_CHANGE = 'CEC_CONTACT_STATUS_CHANGE';
export const CEC_CONTACT_STATUS_CHANGE_SUCCESS = 'CEC_CONTACT_STATUS_CHANGE_SUCCESS';
export const CEC_CONTACT_STATUS_CHANGE_ERROR = 'CEC_CONTACT_STATUS_CHANGE_ERROR';

export const CEC_CONTACT_EDIT = 'CEC_CONTACT_EDIT';
export const CEC_CONTACT_EDIT_SUCCESS = 'CEC_CONTACT_EDIT_SUCCESS';
export const CEC_CONTACT_EDIT_ERROR = 'CEC_CONTACT_EDIT_ERROR';

export const CEC_CONTACT_DELETE = 'CEC_CONTACT_DELETE';
export const CEC_CONTACT_DELETE_SUCCESS = 'CEC_CONTACT_DELETE_SUCCESS';
export const CEC_CONTACT_DELETE_ERROR = 'CEC_CONTACT_DELETE_ERROR';

export const CEC_CONTACT_BULK_DELETE = 'CEC_CONTACT_BULK_DELETE';
export const CEC_CONTACT_BULK_DELETE_SUCCESS = 'CEC_CONTACT_BULK_DELETE_SUCCESS';
export const CEC_CONTACT_BULK_DELETE_ERROR = 'CEC_CONTACT_BULK_DELETE_ERROR';

export const CEC_CONTACT_FRONT_GET_BY_ID = 'CEC_CONTACT_FRONT_GET_BY_ID';
export const CEC_CONTACT_SELECTED_ITEMS_CHANGE = 'CEC_CONTACT_SELECTED_ITEMS_CHANGE';

export const CEC_CONTACT_IMPORT_FROM_CLIENT = 'CEC_CONTACT_IMPORT_FROM_CLIENT';
export const CEC_CONTACT_IMPORT_FROM_CLIENT_SUCCESS = 'CEC_CONTACT_IMPORT_FROM_CLIENT_SUCCESS';
export const CEC_CONTACT_IMPORT_FROM_CLIENT_ERROR = 'CEC_CONTACT_IMPORT_FROM_CLIENT_ERROR';

export const CEC_CONTACT_IMPORT_FROM_CSV = 'CEC_CONTACT_IMPORT_FROM_CSV';
export const CEC_CONTACT_IMPORT_FROM_CSV_SUCCESS = 'CEC_CONTACT_IMPORT_FROM_CSV_SUCCESS';
export const CEC_CONTACT_IMPORT_FROM_CSV_ERROR = 'CEC_CONTACT_IMPORT_FROM_CSV_ERROR';

/** CEC_LIST */
export const CEC_LIST_GET_LIST = 'CEC_LIST_GET_LIST';
export const CEC_LIST_GET_LIST_SUCCESS = 'CEC_LIST_GET_LIST_SUCCESS';
export const CEC_LIST_GET_LIST_ERROR = 'CEC_LIST_GET_LIST_ERROR';

export const CEC_LIST_GET_BY_ID = 'CEC_LIST_GET_BY_ID';
export const CEC_LIST_GET_BY_ID_SUCCESS = 'CEC_LIST_GET_BY_ID_SUCCESS';
export const CEC_LIST_GET_BY_ID_ERROR = 'CEC_LIST_GET_BY_ID_ERROR';

export const CEC_LIST_ADD_NEW = 'CEC_LIST_ADD_NEW';
export const CEC_LIST_ADD_NEW_SUCCESS = 'CEC_LIST_ADD_NEW_SUCCESS';
export const CEC_LIST_ADD_NEW_ERROR = 'CEC_LIST_ADD_NEW_ERROR';
export const CEC_LIST_ADD_NEW_REQUEST = 'CEC_LIST_ADD_NEW_REQUEST';

export const CEC_LIST_STATUS_CHANGE = 'CEC_LIST_STATUS_CHANGE';
export const CEC_LIST_STATUS_CHANGE_SUCCESS = 'CEC_LIST_STATUS_CHANGE_SUCCESS';
export const CEC_LIST_STATUS_CHANGE_ERROR = 'CEC_LIST_STATUS_CHANGE_ERROR';

export const CEC_LIST_EDIT = 'CEC_LIST_EDIT';
export const CEC_LIST_EDIT_SUCCESS = 'CEC_LIST_EDIT_SUCCESS';
export const CEC_LIST_EDIT_ERROR = 'CEC_LIST_EDIT_ERROR';

export const CEC_LIST_DELETE = 'CEC_LIST_DELETE';
export const CEC_LIST_DELETE_SUCCESS = 'CEC_LIST_DELETE_SUCCESS';
export const CEC_LIST_DELETE_ERROR = 'CEC_LIST_DELETE_ERROR';

export const CEC_LIST_BULK_DELETE = 'CEC_LIST_BULK_DELETE';
export const CEC_LIST_BULK_DELETE_SUCCESS = 'CEC_LIST_BULK_DELETE_SUCCESS';
export const CEC_LIST_BULK_DELETE_ERROR = 'CEC_LIST_BULK_DELETE_ERROR';

export const CEC_LIST_FRONT_GET_BY_ID = 'CEC_LIST_FRONT_GET_BY_ID';
export const CEC_LIST_SELECTED_ITEMS_CHANGE = 'CEC_LIST_SELECTED_ITEMS_CHANGE';

/** POSTING */

/** ACTIVITY */
export const ACTIVITY_GET_LIST = 'ACTIVITY_GET_LIST';
export const ACTIVITY_GET_LIST_SUCCESS = 'ACTIVITY_GET_LIST_SUCCESS';
export const ACTIVITY_GET_LIST_ERROR = 'ACTIVITY_GET_LIST_ERROR';

export const ACTIVITY_GET_BY_ID = 'ACTIVITY_GET_BY_ID';
export const ACTIVITY_GET_BY_ID_SUCCESS = 'ACTIVITY_GET_BY_ID_SUCCESS';
export const ACTIVITY_GET_BY_ID_ERROR = 'ACTIVITY_GET_BY_ID_ERROR';

export const ACTIVITY_ADD_NEW = 'ACTIVITY_ADD_NEW';
export const ACTIVITY_ADD_NEW_SUCCESS = 'ACTIVITY_ADD_NEW_SUCCESS';
export const ACTIVITY_ADD_NEW_ERROR = 'ACTIVITY_ADD_NEW_ERROR';
export const ACTIVITY_ADD_NEW_REQUEST = 'ACTIVITY_ADD_NEW_REQUEST';

export const ACTIVITY_EDIT = 'ACTIVITY_EDIT';
export const ACTIVITY_EDIT_SUCCESS = 'ACTIVITY_EDIT_SUCCESS';
export const ACTIVITY_EDIT_ERROR = 'ACTIVITY_EDIT_ERROR';

export const ACTIVITY_DELETE = 'ACTIVITY_DELETE';
export const ACTIVITY_DELETE_SUCCESS = 'ACTIVITY_DELETE_SUCCESS';
export const ACTIVITY_DELETE_ERROR = 'ACTIVITY_DELETE_ERROR';
/* REFERRAL*/

export const REFERRAL_GET_LIST_BY_QUERY = 'REFERRAL_GET_LIST_BY_QUERY';
export const REFERRAL_GET_LIST_BY_QUERY_SUCCESS = 'REFERRAL_GET_LIST_BY_QUERY_SUCCESS';
export const REFERRAL_GET_LIST_BY_QUERY_ERROR = 'REFERRAL_GET_LIST_BY_QUERY_ERROR';

export const REFERRAL_GET_LIST = 'REFERRAL_GET_LIST';
export const REFERRAL_GET_LIST_SUCCESS = 'REFERRAL_GET_LIST_SUCCESS';
export const REFERRAL_GET_LIST_ERROR = 'REFERRAL_GET_LIST_ERROR';

export const REFERRAL_GET_BY_ID = 'REFERRAL_GET_BY_ID';
export const REFERRAL_GET_BY_ID_SUCCESS = 'REFERRAL_GET_BY_ID_SUCCESS';
export const REFERRAL_GET_BY_ID_ERROR = 'REFERRAL_GET_BY_ID_ERROR';

export const REFERRAL_ADD_NEW = 'REFERRAL_ADD_NEW';
export const REFERRAL_ADD_NEW_SUCCESS = 'REFERRAL_ADD_NEW_SUCCESS';
export const REFERRAL_ADD_NEW_ERROR = 'REFERRAL_ADD_NEW_ERROR';
export const REFERRAL_ADD_NEW_REQUEST = 'REFERRAL_ADD_NEW_REQUEST';

export const REFERRAL_EDIT = 'REFERRAL_EDIT';
export const REFERRAL_EDIT_SUCCESS = 'REFERRAL_EDIT_SUCCESS';
export const REFERRAL_EDIT_ERROR = 'REFERRAL_EDIT_ERROR';

export const REFERRAL_DELETE = 'REFERRAL_DELETE';
export const REFERRAL_DELETE_SUCCESS = 'REFERRAL_DELETE_SUCCESS';
export const REFERRAL_DELETE_ERROR = 'REFERRAL_DELETE_ERROR';

/* EVENT*/

export const EVENT_GET_LIST_BY_QUERY = 'EVENT_GET_LIST_BY_QUERY';
export const EVENT_GET_LIST_BY_QUERY_SUCCESS = 'EVENT_GET_LIST_BY_QUERY_SUCCESS';
export const EVENT_GET_LIST_BY_QUERY_ERROR = 'EVENT_GET_LIST_BY_QUERY_ERROR';

export const EVENT_GET_LIST = 'EVENT_GET_LIST';
export const EVENT_GET_LIST_SUCCESS = 'EVENT_GET_LIST_SUCCESS';
export const EVENT_GET_LIST_ERROR = 'EVENT_GET_LIST_ERROR';

export const EVENT_GET_BY_ID = 'EVENT_GET_BY_ID';
export const EVENT_GET_BY_ID_SUCCESS = 'EVENT_GET_BY_ID_SUCCESS';
export const EVENT_GET_BY_ID_ERROR = 'EVENT_GET_BY_ID_ERROR';

export const EVENT_ADD_NEW = 'EVENT_ADD_NEW';
export const EVENT_ADD_NEW_SUCCESS = 'EVENT_ADD_NEW_SUCCESS';
export const EVENT_ADD_NEW_ERROR = 'EVENT_ADD_NEW_ERROR';
export const EVENT_ADD_NEW_REQUEST = 'EVENT_ADD_NEW_REQUEST';

export const EVENT_STATUS_CHANGE = 'EVENT_STATUS_CHANGE';
export const EVENT_STATUS_CHANGE_SUCCESS = 'EVENT_STATUS_CHANGE_SUCCESS';
export const EVENT_STATUS_CHANGE_ERROR = 'EVENT_STATUS_CHANGE_ERROR';

export const EVENT_EDIT = 'EVENT_EDIT';
export const EVENT_EDIT_SUCCESS = 'EVENT_EDIT_SUCCESS';
export const EVENT_EDIT_ERROR = 'EVENT_EDIT_ERROR';

export const EVENT_DELETE = 'EVENT_DELETE';
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS';
export const EVENT_DELETE_ERROR = 'EVENT_DELETE_ERROR';

/* EVENT_PARTICIPANT*/

export const EVENT_PARTICIPANT_GET_LIST = 'EVENT_PARTICIPANT_GET_LIST';
export const EVENT_PARTICIPANT_GET_LIST_SUCCESS = 'EVENT_PARTICIPANT_GET_LIST_SUCCESS';
export const EVENT_PARTICIPANT_GET_LIST_ERROR = 'EVENT_PARTICIPANT_GET_LIST_ERROR';

export const EVENT_PARTICIPANT_GET_BY_ID = 'EVENT_PARTICIPANT_GET_BY_ID';
export const EVENT_PARTICIPANT_GET_BY_ID_SUCCESS = 'EVENT_PARTICIPANT_GET_BY_ID_SUCCESS';
export const EVENT_PARTICIPANT_GET_BY_ID_ERROR = 'EVENT_PARTICIPANT_GET_BY_ID_ERROR';

export const EVENT_PARTICIPANT_ADD_NEW = 'EVENT_PARTICIPANT_ADD_NEW';
export const EVENT_PARTICIPANT_ADD_NEW_SUCCESS = 'EVENT_PARTICIPANT_ADD_NEW_SUCCESS';
export const EVENT_PARTICIPANT_ADD_NEW_ERROR = 'EVENT_PARTICIPANT_ADD_NEW_ERROR';
export const EVENT_PARTICIPANT_ADD_NEW_REQUEST = 'EVENT_PARTICIPANT_ADD_NEW_REQUEST';

export const EVENT_PARTICIPANT_EDIT = 'EVENT_PARTICIPANT_EDIT';
export const EVENT_PARTICIPANT_EDIT_SUCCESS = 'EVENT_PARTICIPANT_EDIT_SUCCESS';
export const EVENT_PARTICIPANT_EDIT_ERROR = 'EVENT_PARTICIPANT_EDIT_ERROR';

export const EVENT_PARTICIPANT_DELETE = 'EVENT_PARTICIPANT_DELETE';
export const EVENT_PARTICIPANT_DELETE_SUCCESS = 'EVENT_PARTICIPANT_DELETE_SUCCESS';
export const EVENT_PARTICIPANT_DELETE_ERROR = 'EVENT_PARTICIPANT_DELETE_ERROR';

/** Job Seeker */
export const JOB_SEEKER_GET_LIST = 'JOB_SEEKER_GET_LIST';
export const JOB_SEEKER_GET_LIST_SUCCESS = 'JOB_SEEKER_GET_LIST_SUCCESS';
export const JOB_SEEKER_GET_LIST_ERROR = 'JOB_SEEKER_GET_LIST_ERROR';

export const JOB_SEEKER_GET_BY_ID = 'JOB_SEEKER_GET_BY_ID';
export const JOB_SEEKER_GET_BY_ID_SUCCESS = 'JOB_SEEKER_GET_BY_ID_SUCCESS';
export const JOB_SEEKER_GET_BY_ID_ERROR = 'JOB_SEEKER_GET_BY_ID_ERROR';

export const JOB_SEEKER_ADD_NEW = 'JOB_SEEKER_ADD_NEW';
export const JOB_SEEKER_ADD_NEW_SUCCESS = 'JOB_SEEKER_ADD_NEW_SUCCESS';
export const JOB_SEEKER_ADD_NEW_ERROR = 'JOB_SEEKER_ADD_NEW_ERROR';
export const JOB_SEEKER_ADD_NEW_REQUEST = 'JOB_SEEKER_ADD_NEW_REQUEST';

export const JOB_SEEKER_EDIT = 'JOB_SEEKER_EDIT';
export const JOB_SEEKER_EDIT_SUCCESS = 'JOB_SEEKER_EDIT_SUCCESS';
export const JOB_SEEKER_EDIT_ERROR = 'JOB_SEEKER_EDIT_ERROR';

export const JOB_SEEKER_DELETE = 'JOB_SEEKER_DELETE';
export const JOB_SEEKER_DELETE_SUCCESS = 'JOB_SEEKER_DELETE_SUCCESS';
export const JOB_SEEKER_DELETE_ERROR = 'JOB_SEEKER_DELETE_ERROR';

/** Client Participant */

export const CLIENT_PARTICIPANT_ADD_NEW_REQUEST = 'CLIENT_PARTICIPANT_ADD_NEW_REQUEST';

export const CLIENT_PARTICIPANT_EDIT = 'CLIENT_PARTICIPANT_EDIT';
export const CLIENT_PARTICIPANT_EDIT_SUCCESS = 'CLIENT_PARTICIPANT_EDIT_SUCCESS';
export const CLIENT_PARTICIPANT_EDIT_ERROR = 'CLIENT_PARTICIPANT_EDIT_ERROR';

/* YJC Contract */
export const YJC_CONTRACT_GET_LIST = 'YJC_CONTRACT_GET_LIST';
export const YJC_CONTRACT_GET_LIST_SUCCESS = 'YJC_CONTRACT_GET_LIST_SUCCESS';
export const YJC_CONTRACT_GET_LIST_ERROR = 'YJC_CONTRACT_GET_LIST_ERROR';

export const YJC_CONTRACT_GET_BY_EMPLOYER = 'YJC_CONTRACT_GET_BY_EMPLOYER';
export const YJC_CONTRACT_GET_BY_EMPLOYER_SUCCESS = 'YJC_CONTRACT_GET_BY_EMPLOYER_SUCCESS';
export const YJC_CONTRACT_GET_BY_EMPLOYER_ERROR = 'YJC_CONTRACT_GET_BY_EMPLOYER_ERROR';

export const YJC_CONTRACT_GET_BY_CLIENT = 'YJC_CONTRACT_GET_BY_CLIENT';
export const YJC_CONTRACT_GET_BY_CLIENT_SUCCESS = 'YJC_CONTRACT_GET_BY_CLIENT_SUCCESS';
export const YJC_CONTRACT_GET_BY_CLIENT_ERROR = 'YJC_CONTRACT_GET_BY_CLIENT_ERROR';

export const YJC_CONTRACT_GET_BY_ID = 'YJC_CONTRACT_GET_BY_ID';
export const YJC_CONTRACT_GET_BY_ID_SUCCESS = 'YJC_CONTRACT_GET_BY_ID_SUCCESS';
export const YJC_CONTRACT_GET_BY_ID_ERROR = 'YJC_CONTRACT_GET_BY_ID_ERROR';

export const YJC_CONTRACT_ADD_ITEM = 'YJC_CONTRACT_ADD_ITEM';
export const YJC_CONTRACT_ADD_ITEM_ERROR = 'YJC_CONTRACT_ADD_ITEM_ERROR';
export const YJC_CONTRACT_ADD_ITEM_SUCCESS = 'YJC_CONTRACT_ADD_ITEM_SUCCESS';

export const YJC_CONTRACT_EDIT_ITEM = 'YJC_CONTRACT_EDIT_ITEM';
export const YJC_CONTRACT_EDIT_ITEM_ERROR = 'YJC_CONTRACT_EDIT_ITEM_ERROR';
export const YJC_CONTRACT_EDIT_ITEM_SUCCESS = 'YJC_CONTRACT_EDIT_ITEM_SUCCESS';

export const YJC_CONTRACT_DELETE_ITEM = 'YJC_CONTRACT_DELETE_ITEM';
export const YJC_CONTRACT_DELETE_ITEM_ERROR = 'YJC_CONTRACT_DELETE_ITEM_ERROR';
export const YJC_CONTRACT_DELETE_ITEM_SUCCESS = 'YJC_CONTRACT_DELETE_ITEM_SUCCESS';
export const YJC_CONTRACT_SELECTED_ITEMS_CHANGE = 'YJC_CONTRACT_SELECTED_ITEMS_CHANGE';

/* YJCS_CONTRACT */
export const YJCS_CONTRACT_GET_LIST = 'YJCS_CONTRACT_GET_LIST';
export const YJCS_CONTRACT_GET_LIST_SUCCESS = 'YJCS_CONTRACT_GET_LIST_SUCCESS';
export const YJCS_CONTRACT_GET_LIST_ERROR = 'YJCS_CONTRACT_GET_LIST_ERROR';

export const YJCS_CONTRACT_GET_BY_EMPLOYER = 'YJCS_CONTRACT_GET_BY_EMPLOYER';
export const YJCS_CONTRACT_GET_BY_EMPLOYER_SUCCESS = 'YJCS_CONTRACT_GET_BY_EMPLOYER_SUCCESS';
export const YJCS_CONTRACT_GET_BY_EMPLOYER_ERROR = 'YJCS_CONTRACT_GET_BY_EMPLOYER_ERROR';

export const YJCS_CONTRACT_GET_BY_CLIENT = 'YJCS_CONTRACT_GET_BY_CLIENT';
export const YJCS_CONTRACT_GET_BY_CLIENT_SUCCESS = 'YJCS_CONTRACT_GET_BY_CLIENT_SUCCESS';
export const YJCS_CONTRACT_GET_BY_CLIENT_ERROR = 'YJCS_CONTRACT_GET_BY_CLIENT_ERROR';

export const YJCS_CONTRACT_GET_BY_ID = 'YJCS_CONTRACT_GET_BY_ID';
export const YJCS_CONTRACT_GET_BY_ID_SUCCESS = 'YJCS_CONTRACT_GET_BY_ID_SUCCESS';
export const YJCS_CONTRACT_GET_BY_ID_ERROR = 'YJCS_CONTRACT_GET_BY_ID_ERROR';

export const YJCS_CONTRACT_ADD_ITEM = 'YJCS_CONTRACT_ADD_ITEM';
export const YJCS_CONTRACT_ADD_ITEM_ERROR = 'YJCS_CONTRACT_ADD_ITEM_ERROR';
export const YJCS_CONTRACT_ADD_ITEM_SUCCESS = 'YJCS_CONTRACT_ADD_ITEM_SUCCESS';

export const YJCS_CONTRACT_EDIT_ITEM = 'YJCS_CONTRACT_EDIT_ITEM';
export const YJCS_CONTRACT_EDIT_ITEM_ERROR = 'YJCS_CONTRACT_EDIT_ITEM_ERROR';
export const YJCS_CONTRACT_EDIT_ITEM_SUCCESS = 'YJCS_CONTRACT_EDIT_ITEM_SUCCESS';

export const YJCS_CONTRACT_DELETE_ITEM = 'YJCS_CONTRACT_DELETE_ITEM';
export const YJCS_CONTRACT_DELETE_ITEM_ERROR = 'YJCS_CONTRACT_DELETE_ITEM_ERROR';
export const YJCS_CONTRACT_DELETE_ITEM_SUCCESS = 'YJCS_CONTRACT_DELETE_ITEM_SUCCESS';
export const YJCS_CONTRACT_SELECTED_ITEMS_CHANGE = 'YJCS_CONTRACT_SELECTED_ITEMS_CHANGE';

/* ES Contract */
export const ES_CONTRACT_GET_LIST = 'ES_CONTRACT_GET_LIST';
export const ES_CONTRACT_GET_LIST_SUCCESS = 'ES_CONTRACT_GET_LIST_SUCCESS';
export const ES_CONTRACT_GET_LIST_ERROR = 'ES_CONTRACT_GET_LIST_ERROR';

export const ES_CONTRACT_GET_BY_EMPLOYER = 'ES_CONTRACT_GET_BY_EMPLOYER';
export const ES_CONTRACT_GET_BY_EMPLOYER_SUCCESS = 'ES_CONTRACT_GET_BY_EMPLOYER_SUCCESS';
export const ES_CONTRACT_GET_BY_EMPLOYER_ERROR = 'ES_CONTRACT_GET_BY_EMPLOYER_ERROR';

export const ES_CONTRACT_GET_BY_CLIENT = 'ES_CONTRACT_GET_BY_CLIENT';
export const ES_CONTRACT_GET_BY_CLIENT_SUCCESS = 'ES_CONTRACT_GET_BY_CLIENT_SUCCESS';
export const ES_CONTRACT_GET_BY_CLIENT_ERROR = 'ES_CONTRACT_GET_BY_CLIENT_ERROR';

export const ES_CONTRACT_GET_BY_ID = 'ES_CONTRACT_GET_BY_ID';
export const ES_CONTRACT_GET_BY_ID_SUCCESS = 'ES_CONTRACT_GET_BY_ID_SUCCESS';
export const ES_CONTRACT_GET_BY_ID_ERROR = 'ES_CONTRACT_GET_BY_ID_ERROR';

export const ES_CONTRACT_ADD_ITEM = 'ES_CONTRACT_ADD_ITEM';
export const ES_CONTRACT_ADD_ITEM_ERROR = 'ES_CONTRACT_ADD_ITEM_ERROR';
export const ES_CONTRACT_ADD_ITEM_SUCCESS = 'ES_CONTRACT_ADD_ITEM_SUCCESS';

export const ES_CONTRACT_EDIT_ITEM = 'ES_CONTRACT_EDIT_ITEM';
export const ES_CONTRACT_EDIT_ITEM_ERROR = 'ES_CONTRACT_EDIT_ITEM_ERROR';
export const ES_CONTRACT_EDIT_ITEM_SUCCESS = 'ES_CONTRACT_EDIT_ITEM_SUCCESS';

export const ES_CONTRACT_DELETE_ITEM = 'ES_CONTRACT_DELETE_ITEM';
export const ES_CONTRACT_DELETE_ITEM_ERROR = 'ES_CONTRACT_DELETE_ITEM_ERROR';
export const ES_CONTRACT_DELETE_ITEM_SUCCESS = 'ES_CONTRACT_DELETE_ITEM_SUCCESS';

/* IES Contract */
export const IES_CONTRACT_GET_LIST = 'IES_CONTRACT_GET_LIST';
export const IES_CONTRACT_GET_LIST_SUCCESS = 'IES_CONTRACT_GET_LIST_SUCCESS';
export const IES_CONTRACT_GET_LIST_ERROR = 'IES_CONTRACT_GET_LIST_ERROR';

export const IES_CONTRACT_ADD_ITEM = 'IES_CONTRACT_ADD_ITEM';
export const IES_CONTRACT_ADD_ITEM_ERROR = 'IES_CONTRACT_ADD_ITEM_ERROR';
export const IES_CONTRACT_ADD_ITEM_SUCCESS = 'IES_CONTRACT_ADD_ITEM_SUCCESS';

export const IES_CONTRACT_GET_BY_ID = 'IES_CONTRACT_GET_BY_ID';
export const IES_CONTRACT_GET_BY_ID_SUCCESS = 'IES_CONTRACT_GET_BY_ID_SUCCESS';
export const IES_CONTRACT_GET_BY_ID_ERROR = 'IES_CONTRACT_GET_BY_ID_ERROR';

export const IES_CONTRACT_EDIT_ITEM = 'IES_CONTRACT_EDIT_ITEM';
export const IES_CONTRACT_EDIT_ITEM_ERROR = 'IES_CONTRACT_EDIT_ITEM_ERROR';
export const IES_CONTRACT_EDIT_ITEM_SUCCESS = 'IES_CONTRACT_EDIT_ITEM_SUCCESS';

export const IES_CONTRACT_DELETE_ITEM = 'IES_CONTRACT_DELETE_ITEM';
export const IES_CONTRACT_DELETE_ITEM_ERROR = 'IES_CONTRACT_DELETE_ITEM_ERROR';
export const IES_CONTRACT_DELETE_ITEM_SUCCESS = 'IES_CONTRACT_DELETE_ITEM_SUCCESS';

/* SE Contract */
export const SE_CONTRACT_GET_LIST = 'SE_CONTRACT_GET_LIST';
export const SE_CONTRACT_GET_LIST_SUCCESS = 'SE_CONTRACT_GET_LIST_SUCCESS';
export const SE_CONTRACT_GET_LIST_ERROR = 'SE_CONTRACT_GET_LIST_ERROR';

export const SE_CONTRACT_GET_BY_ID = 'SE_CONTRACT_GET_BY_ID';
export const SE_CONTRACT_GET_BY_ID_SUCCESS = 'SE_CONTRACT_GET_BY_ID_SUCCESS';
export const SE_CONTRACT_GET_BY_ID_ERROR = 'SE_CONTRACT_GET_BY_ID_ERROR';

export const SE_CONTRACT_ADD_ITEM = 'SE_CONTRACT_ADD_ITEM';
export const SE_CONTRACT_ADD_ITEM_ERROR = 'SE_CONTRACT_ADD_ITEM_ERROR';
export const SE_CONTRACT_ADD_ITEM_SUCCESS = 'SE_CONTRACT_ADD_ITEM_SUCCESS';

export const SE_CONTRACT_EDIT_ITEM = 'SE_CONTRACT_EDIT_ITEM';
export const SE_CONTRACT_EDIT_ITEM_ERROR = 'SE_CONTRACT_EDIT_ITEM_ERROR';
export const SE_CONTRACT_EDIT_ITEM_SUCCESS = 'SE_CONTRACT_EDIT_ITEM_SUCCESS';

export const SE_CONTRACT_DELETE_ITEM = 'SE_CONTRACT_DELETE_ITEM';
export const SE_CONTRACT_DELETE_ITEM_ERROR = 'SE_CONTRACT_DELETE_ITEM_ERROR';
export const SE_CONTRACT_DELETE_ITEM_SUCCESS = 'SE_CONTRACT_DELETE_ITEM_SUCCESS';
export const SE_CONTRACT_SELECTED_ITEMS_CHANGE = 'SE_CONTRACT_SELECTED_ITEMS_CHANGE';

/* COJG_CONTRACT*/
export const COJG_CONTRACT_GET_LIST = 'COJG_CONTRACT_GET_LIST';
export const COJG_CONTRACT_GET_LIST_SUCCESS = 'COJG_CONTRACT_GET_LIST_SUCCESS';
export const COJG_CONTRACT_GET_LIST_ERROR = 'COJG_CONTRACT_GET_LIST_ERROR';
export const COJG_CONTRACT_GET_LIST_WITH_FILTER = 'COJG_CONTRACT_GET_LIST_WITH_FILTER';
export const COJG_CONTRACT_GET_LIST_WITH_ORDER = 'COJG_CONTRACT_GET_LIST_WITH_ORDER';
export const COJG_CONTRACT_GET_LIST_SEARCH = 'COJG_CONTRACT_GET_LIST_SEARCH';
export const COJG_CONTRACT_ADD_ITEM = 'COJG_CONTRACT_ADD_ITEM';
export const COJG_CONTRACT_ADD_ITEM_ERROR = 'COJG_CONTRACT_ADD_ITEM_ERROR';
export const COJG_CONTRACT_ADD_ITEM_SUCCESS = 'COJG_CONTRACT_ADD_ITEM_SUCCESS';
export const COJG_CONTRACT_EDIT_ITEM = 'COJG_CONTRACT_EDIT_ITEM';
export const COJG_CONTRACT_EDIT_ITEM_ERROR = 'COJG_CONTRACT_EDIT_ITEM_ERROR';
export const COJG_CONTRACT_EDIT_ITEM_SUCCESS = 'COJG_CONTRACT_EDIT_ITEM_SUCCESS';
export const COJG_CONTRACT_DELETE_ITEM = 'COJG_CONTRACT_DELETE_ITEM';
export const COJG_CONTRACT_DELETE_ITEM_ERROR = 'COJG_CONTRACT_DELETE_ITEM_ERROR';
export const COJG_CONTRACT_DELETE_ITEM_SUCCESS = 'COJG_CONTRACT_DELETE_ITEM_SUCCESS';
export const COJG_CONTRACT_SELECTED_ITEMS_CHANGE = 'COJG_CONTRACT_SELECTED_ITEMS_CHANGE';
export const COJG_CONTRACT_GET_BY_ID = 'COJG_CONTRACT_GET_BY_ID';
export const COJG_CONTRACT_GET_BY_ID_SUCCESS = 'COJG_CONTRACT_GET_BY_ID_SUCCESS';
export const COJG_CONTRACT_GET_BY_ID_ERROR = 'COJG_CONTRACT_GET_BY_ID_ERROR';
export const COJG_CONTRACT_GET_BY_EMPLOYER = 'COJG_CONTRACT_GET_BY_EMPLOYER';
export const COJG_CONTRACT_GET_BY_EMPLOYER_SUCCESS = 'COJG_CONTRACT_GET_BY_EMPLOYER_SUCCESS';
export const COJG_CONTRACT_GET_BY_EMPLOYER_ERROR = 'COJG_CONTRACT_GET_BY_EMPLOYER_ERROR';

/* CHEQUE*/
export const CHEQUE_GET_BY_ID = 'CHEQUE_GET_BY_ID';
export const CHEQUE_GET_BY_ID_SUCCESS = 'CHEQUE_GET_BY_ID_SUCCESS';
export const CHEQUE_GET_BY_ID_ERROR = 'CHEQUE_GET_BY_ID_ERROR';

export const CHEQUE_GET_LIST = 'CHEQUE_GET_LIST';
export const CHEQUE_GET_LIST_SUCCESS = 'CHEQUE_GET_LIST_SUCCESS';
export const CHEQUE_GET_LIST_ERROR = 'CHEQUE_GET_LIST_ERROR';
export const CHEQUE_GET_LIST_WITH_FILTER = 'CHEQUE_GET_LIST_WITH_FILTER';
export const CHEQUE_GET_LIST_WITH_ORDER = 'CHEQUE_GET_LIST_WITH_ORDER';
export const CHEQUE_GET_LIST_SEARCH = 'CHEQUE_GET_LIST_SEARCH';
export const CHEQUE_ADD_ITEM = 'CHEQUE_ADD_ITEM';
export const CHEQUE_ADD_ITEM_ERROR = 'CHEQUE_ADD_ITEM_ERROR';
export const CHEQUE_ADD_ITEM_SUCCESS = 'CHEQUE_ADD_ITEM_SUCCESS';
export const CHEQUE_EDIT_ITEM = 'CHEQUE_EDIT_ITEM';
export const CHEQUE_EDIT_ITEM_ERROR = 'CHEQUE_EDIT_ITEM_ERROR';
export const CHEQUE_EDIT_ITEM_SUCCESS = 'CHEQUE_EDIT_ITEM_SUCCESS';
export const CHEQUE_DELETE_ITEM = 'CHEQUE_DELETE_ITEM';
export const CHEQUE_DELETE_ITEM_ERROR = 'CHEQUE_DELETE_ITEM_ERROR';
export const CHEQUE_DELETE_ITEM_SUCCESS = 'CHEQUE_DELETE_ITEM_SUCCESS';
export const CHEQUE_SELECTED_ITEMS_CHANGE = 'CHEQUE_SELECTED_ITEMS_CHANGE';

/* BUDGET*/
export const BUDGET_GET_LIST = 'BUDGET_GET_LIST';
export const BUDGET_GET_LIST_SUCCESS = 'BUDGET_GET_LIST_SUCCESS';
export const BUDGET_GET_LIST_ERROR = 'BUDGET_GET_LIST_ERROR';
export const BUDGET_GET_LIST_WITH_FILTER = 'BUDGET_GET_LIST_WITH_FILTER';
export const BUDGET_GET_LIST_WITH_ORDER = 'BUDGET_GET_LIST_WITH_ORDER';
export const BUDGET_GET_LIST_SEARCH = 'BUDGET_GET_LIST_SEARCH';
export const BUDGET_ADD_ITEM = 'BUDGET_ADD_ITEM';
export const BUDGET_ADD_ITEM_SUCCESS = 'BUDGET_ADD_ITEM_SUCCESS';
export const BUDGET_ADD_ITEM_ERROR = 'BUDGET_ADD_ITEM_ERROR';
export const BUDGET_EDIT_ITEM = 'BUDGET_EDIT_ITEM';
export const BUDGET_EDIT_ITEM_ERROR = 'BUDGET_EDIT_ITEM_ERROR';
export const BUDGET_EDIT_ITEM_SUCCESS = 'BUDGET_EDIT_ITEM_SUCCESS';
export const BUDGET_DELETE_ITEM = 'BUDGET_DELETE_ITEM';
export const BUDGET_DELETE_ITEM_ERROR = 'BUDGET_DELETE_ITEM_ERROR';
export const BUDGET_DELETE_ITEM_SUCCESS = 'BUDGET_DELETE_ITEM_SUCCESS';
export const BUDGET_SELECTED_ITEMS_CHANGE = 'BUDGET_SELECTED_ITEMS_CHANGE';

/* GEOGRAPHY*/
export const GEOGRAPHY_GET_LIST = 'GEOGRAPHY_GET_LIST';
export const GEOGRAPHY_GET_LIST_SUCCESS = 'GEOGRAPHY_GET_LIST_SUCCESS';
export const GEOGRAPHY_GET_LIST_ERROR = 'GEOGRAPHY_GET_LIST_ERROR';
export const GEOGRAPHY_GET_LIST_WITH_FILTER = 'GEOGRAPHY_GET_LIST_WITH_FILTER';
export const GEOGRAPHY_GET_LIST_WITH_ORDER = 'GEOGRAPHY_GET_LIST_WITH_ORDER';
export const GEOGRAPHY_GET_LIST_SEARCH = 'GEOGRAPHY_GET_LIST_SEARCH';
export const GEOGRAPHY_ADD_OR_UPDATE_ITEM = 'GEOGRAPHY_ADD_OR_UPDATE_ITEM';
export const GEOGRAPHY_ADD_OR_UPDATE_ITEM_ERROR = 'GEOGRAPHY_ADD_OR_UPDATE_ITEM_ERROR';
export const GEOGRAPHY_ADD_OR_UPDATE_ITEM_SUCCESS = 'GEOGRAPHY_ADD_OR_UPDATE_ITEM_SUCCESS';
export const GEOGRAPHY_DELETE_ITEM = 'GEOGRAPHY_DELETE_ITEM';
export const GEOGRAPHY_DELETE_ITEM_ERROR = 'GEOGRAPHY_DELETE_ITEM_ERROR';
export const GEOGRAPHY_DELETE_ITEM_SUCCESS = 'GEOGRAPHY_DELETE_ITEM_SUCCESS';
export const GEOGRAPHY_SELECTED_ITEMS_CHANGE = 'GEOGRAPHY_SELECTED_ITEMS_CHANGE';

/* SHIFTS*/
export const SHIFTS_GET_LIST = 'SHIFTS_GET_LIST';
export const SHIFTS_GET_LIST_SUCCESS = 'SHIFTS_GET_LIST_SUCCESS';
export const SHIFTS_GET_LIST_ERROR = 'SHIFTS_GET_LIST_ERROR';
export const SHIFTS_GET_LIST_WITH_FILTER = 'SHIFTS_GET_LIST_WITH_FILTER';
export const SHIFTS_GET_LIST_WITH_ORDER = 'SHIFTS_GET_LIST_WITH_ORDER';
export const SHIFTS_GET_LIST_SEARCH = 'SHIFTS_GET_LIST_SEARCH';
export const SHIFTS_EDIT_ITEM = 'SHIFTS_EDIT_ITEM';
export const SHIFTS_EDIT_ITEM_ERROR = 'SHIFTS_EDIT_ITEM_ERROR';
export const SHIFTS_EDIT_ITEM_SUCCESS = 'SHIFTS_EDIT_ITEM_SUCCESS';
export const SHIFTS_DELETE_ITEM = 'SHIFTS_DELETE_ITEM';
export const SHIFTS_DELETE_ITEM_ERROR = 'SHIFTS_DELETE_ITEM_ERROR';
export const SHIFTS_DELETE_ITEM_SUCCESS = 'SHIFTS_DELETE_ITEM_SUCCESS';
export const SHIFTS_SELECTED_ITEMS_CHANGE = 'SHIFTS_SELECTED_ITEMS_CHANGE';

/* JOBTYPE*/
export const JOBTYPE_GET_LIST = 'JOBTYPE_GET_LIST';
export const JOBTYPE_GET_LIST_SUCCESS = 'JOBTYPE_GET_LIST_SUCCESS';
export const JOBTYPE_GET_LIST_ERROR = 'JOBTYPE_GET_LIST_ERROR';
export const JOBTYPE_GET_LIST_WITH_FILTER = 'JOBTYPE_GET_LIST_WITH_FILTER';
export const JOBTYPE_GET_LIST_WITH_ORDER = 'JOBTYPE_GET_LIST_WITH_ORDER';
export const JOBTYPE_GET_LIST_SEARCH = 'JOBTYPE_GET_LIST_SEARCH';
export const JOBTYPE_EDIT_ITEM = 'JOBTYPE_EDIT_ITEM';
export const JOBTYPE_EDIT_ITEM_ERROR = 'JOBTYPE_EDIT_ITEM_ERROR';
export const JOBTYPE_EDIT_ITEM_SUCCESS = 'JOBTYPE_EDIT_ITEM_SUCCESS';
export const JOBTYPE_DELETE_ITEM = 'JOBTYPE_DELETE_ITEM';
export const JOBTYPE_DELETE_ITEM_ERROR = 'JOBTYPE_DELETE_ITEM_ERROR';
export const JOBTYPE_DELETE_ITEM_SUCCESS = 'JOBTYPE_DELETE_ITEM_SUCCESS';
export const JOBTYPE_SELECTED_ITEMS_CHANGE = 'JOBTYPE_SELECTED_ITEMS_CHANGE';

/* SECTOR*/
export const SECTOR_GET_LIST = 'SECTOR_GET_LIST';
export const SECTOR_GET_LIST_SUCCESS = 'SECTOR_GET_LIST_SUCCESS';
export const SECTOR_GET_LIST_ERROR = 'SECTOR_GET_LIST_ERROR';
export const SECTOR_GET_LIST_WITH_FILTER = 'SECTOR_GET_LIST_WITH_FILTER';
export const SECTOR_GET_LIST_WITH_ORDER = 'SECTOR_GET_LIST_WITH_ORDER';
export const SECTOR_GET_LIST_SEARCH = 'SECTOR_GET_LIST_SEARCH';
export const SECTOR_EDIT_ITEM = 'SECTOR_EDIT_ITEM';
export const SECTOR_EDIT_ITEM_ERROR = 'SECTOR_EDIT_ITEM_ERROR';
export const SECTOR_EDIT_ITEM_SUCCESS = 'SECTOR_EDIT_ITEM_SUCCESS';
export const SECTOR_DELETE_ITEM = 'SECTOR_DELETE_ITEM';
export const SECTOR_DELETE_ITEM_ERROR = 'SECTOR_DELETE_ITEM_ERROR';
export const SECTOR_DELETE_ITEM_SUCCESS = 'SECTOR_DELETE_ITEM_SUCCESS';
export const SECTOR_SELECTED_ITEMS_CHANGE = 'SECTOR_SELECTED_ITEMS_CHANGE';

/* REPORT*/
export const COJG_FINANCIAL_GET_LIST = 'COJG_FINANCIAL_GET_LIST';
export const COJG_FINANCIAL_GET_LIST_SUCCESS = 'COJG_FINANCIAL_GET_LIST_SUCCESS';
export const COJG_FINANCIAL_GET_LIST_ERROR = 'COJG_FINANCIAL_GET_LIST_ERROR';
export const COJG_FINANCIAL_GET_LIST_WITH_FILTER = 'COJG_FINANCIAL_GET_LIST_WITH_FILTER';
export const COJG_FINANCIAL_GET_LIST_WITH_ORDER = 'COJG_FINANCIAL_GET_LIST_WITH_ORDER';
export const COJG_FINANCIAL_GET_LIST_SEARCH = 'COJG_FINANCIAL_GET_LIST_SEARCH';

export const CONTRACTS_PAID_GET_LIST = 'CONTRACTS_PAID_GET_LIST';
export const CONTRACTS_PAID_GET_LIST_SUCCESS = 'CONTRACTS_PAID_GET_LIST_SUCCESS';
export const CONTRACTS_PAID_GET_LIST_ERROR = 'CONTRACTS_PAID_GET_LIST_ERROR';
export const CONTRACTS_PAID_GET_LIST_WITH_FILTER = 'CONTRACTS_PAID_GET_LIST_WITH_FILTER';
export const CONTRACTS_PAID_GET_LIST_WITH_ORDER = 'CONTRACTS_PAID_GET_LIST_WITH_ORDER';
export const CONTRACTS_PAID_GET_LIST_SEARCH = 'CONTRACTS_PAID_GET_LIST_SEARCH';

export const COJG_FUND_GET_LIST = 'COJG_FUND_GET_LIST';
export const COJG_FUND_GET_LIST_SUCCESS = 'COJG_FUND_GET_LIST_SUCCESS';
export const COJG_FUND_GET_LIST_ERROR = 'COJG_FUND_GET_LIST_ERROR';
export const COJG_FUND_GET_LIST_WITH_FILTER = 'COJG_FUND_GET_LIST_WITH_FILTER';
export const COJG_FUND_GET_LIST_WITH_ORDER = 'COJG_FUND_GET_LIST_WITH_ORDER';
export const COJG_FUND_GET_LIST_SEARCH = 'COJG_FUND_GET_LIST_SEARCH';

export const COJG_RECONCILIATION_GET_LIST = 'COJG_RECONCILIATION_GET_LIST';
export const COJG_RECONCILIATION_GET_LIST_SUCCESS = 'COJG_RECONCILIATION_GET_LIST_SUCCESS';
export const COJG_RECONCILIATION_GET_LIST_ERROR = 'COJG_RECONCILIATION_GET_LIST_ERROR';
export const COJG_RECONCILIATION_GET_LIST_WITH_FILTER = 'COJG_RECONCILIATION_GET_LIST_WITH_FILTER';
export const COJG_RECONCILIATION_GET_LIST_WITH_ORDER = 'COJG_RECONCILIATION_GET_LIST_WITH_ORDER';
export const COJG_RECONCILIATION_GET_LIST_SEARCH = 'COJG_RECONCILIATION_GET_LIST_SEARCH';

export const ES_CONTRACT_REPORT_GET_LIST = 'ES_CONTRACT_REPORT_GET_LIST';
export const ES_CONTRACT_REPORT_GET_LIST_SUCCESS = 'ES_CONTRACT_REPORT_GET_LIST_SUCCESS';
export const ES_CONTRACT_REPORT_GET_LIST_ERROR = 'ES_CONTRACT_REPORT_GET_LIST_ERROR';
export const ES_CONTRACT_REPORT_GET_LIST_WITH_FILTER = 'ES_CONTRACT_REPORT_GET_LIST_WITH_FILTER';
export const ES_CONTRACT_REPORT_GET_LIST_WITH_ORDER = 'ES_CONTRACT_REPORT_GET_LIST_WITH_ORDER';
export const ES_CONTRACT_REPORT_GET_LIST_SEARCH = 'ES_CONTRACT_REPORT_GET_LIST_SEARCH';

// SE_CONTRACT
export const SE_CONTRACT_REPORT_GET_LIST = 'SE_CONTRACT_REPORT_GET_LIST';
export const SE_CONTRACT_REPORT_GET_LIST_SUCCESS = 'SE_CONTRACT_REPORT_GET_LIST_SUCCESS';
export const SE_CONTRACT_REPORT_GET_LIST_ERROR = 'SE_CONTRACT_REPORT_GET_LIST_ERROR';
export const SE_CONTRACT_REPORT_GET_LIST_WITH_FILTER = 'SE_CONTRACT_REPORT_GET_LIST_WITH_FILTER';
export const SE_CONTRACT_REPORT_GET_LIST_WITH_ORDER = 'SE_CONTRACT_REPORT_GET_LIST_WITH_ORDER';
export const SE_CONTRACT_REPORT_GET_LIST_SEARCH = 'SE_CONTRACT_REPORT_GET_LIST_SEARCH';

export const ALL_ES_CONTRACT_OUSTANDING_GET_LIST = 'ALL_ES_CONTRACT_OUSTANDING_GET_LIST';
export const ALL_ES_CONTRACT_OUSTANDING_GET_LIST_SUCCESS =
  'ALL_ES_CONTRACT_OUSTANDING_GET_LIST_SUCCESS';
export const ALL_ES_CONTRACT_OUSTANDING_GET_LIST_ERROR =
  'ALL_ES_CONTRACT_OUSTANDING_GET_LIST_ERROR';
export const ALL_ES_CONTRACT_OUSTANDING_GET_LIST_WITH_FILTER =
  'ALL_ES_CONTRACT_OUSTANDING_GET_LIST_WITH_FILTER';
export const ALL_ES_CONTRACT_OUSTANDING_GET_LIST_WITH_ORDER =
  'ALL_ES_CONTRACT_OUSTANDING_GET_LIST_WITH_ORDER';
export const ALL_ES_CONTRACT_OUSTANDING_GET_LIST_SEARCH =
  'ALL_ES_CONTRACT_OUSTANDING_GET_LIST_SEARCH';

export const NOTIFICATION_REPORT_GET_LIST = 'NOTIFICATION_REPORT_GET_LIST';
export const NOTIFICATION_REPORT_GET_LIST_SUCCESS = 'NOTIFICATION_REPORT_GET_LIST_SUCCESS';
export const NOTIFICATION_REPORT_GET_LIST_ERROR = 'NOTIFICATION_REPORT_GET_LIST_ERROR';

/* USER */
export const ROLE_GET_LIST = 'ROLE_GET_LIST';
export const ROLE_GET_LIST_SUCCESS = 'ROLE_GET_LIST_SUCCESS';
export const ROLE_GET_LIST_ERROR = 'ROLE_GET_LIST_ERROR';
export const ROLE_GET_LIST_SEARCH = 'ROLE_GET_LIST_SEARCH';
export const ROLE_GET_BY_ID = 'ROLE_GET_BY_ID';
export const ROLE_GET_BY_ID_SUCCESS = 'ROLE_GET_BY_ID_SUCCESS';
export const ROLE_GET_BY_ID_ERROR = 'ROLE_GET_BY_ID_ERROR';
export const ROLE_ADD_OR_UPDATE_ITEM = 'ROLE_ADD_OR_UPDATE_ITEM';
export const ROLE_ADD_OR_UPDATE_ITEM_ERROR = 'ROLE_ADD_OR_UPDATE_ITEM_ERROR';
export const ROLE_ADD_OR_UPDATE_ITEM_SUCCESS = 'ROLE_ADD_OR_UPDATE_ITEM_SUCCESS';
export const ROLE_DELETE_ITEM = 'ROLE_DELETE_ITEM';
export const ROLE_DELETE_ITEM_ERROR = 'ROLE_DELETE_ITEM_ERROR';
export const ROLE_DELETE_ITEM_SUCCESS = 'ROLE_DELETE_ITEM_SUCCESS';
export const CLOSE_ROLE_ERROR_NOTIFICATION = 'CLOSE_ROLE_ERROR_NOTIFICATION';

export const GET_PERMISSION_LIST = 'GET_PERMISSION_LIST';
export const GET_PERMISSION_LIST_SUCCESS = 'GET_PERMISSION_LIST_SUCCESS';
export const GET_PERMISSION_LIST_ERROR = 'GET_PERMISSION_LIST_ERROR';

export const USER_GET_LIST = 'USER_GET_LIST';
export const USER_GET_LIST_SUCCESS = 'USER_GET_LIST_SUCCESS';
export const USER_GET_LIST_ERROR = 'USER_GET_LIST_ERROR';

export const ALL_USER_GET_LIST = 'ALL_USER_GET_LIST';
export const ALL_USER_GET_LIST_SUCCESS = 'ALL_USER_GET_LIST_SUCCESS';
export const ALL_USER_GET_LIST_ERROR = 'ALL_USER_GET_LIST_ERROR';

export const USER_GET_LIST_SEARCH = 'USER_GET_LIST_SEARCH';
export const USER_ADD_OR_UPDATE_ITEM = 'USER_ADD_OR_UPDATE_ITEM';
export const USER_ADD_OR_UPDATE_ITEM_ERROR = 'USER_ADD_OR_UPDATE_ITEM_ERROR';
export const USER_ADD_OR_UPDATE_ITEM_SUCCESS = 'USER_ADD_OR_UPDATE_ITEM_SUCCESS';
export const USER_DELETE_ITEM = 'USER_DELETE_ITEM';
export const USER_DELETE_ITEM_ERROR = 'USER_DELETE_ITEM_ERROR';
export const USER_DELETE_ITEM_SUCCESS = 'USER_DELETE_ITEM_SUCCESS';
export const USER_SELECTED_ITEMS_CHANGE = 'USER_SELECTED_ITEMS_CHANGE';
export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const USER_RESET_PASSWORD_ERROR = 'USER_RESET_PASSWORD_ERROR';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_PROFILE_CLOSE_ERROR_MESSAGE = 'USER_PROFILE_CLOSE_ERROR_MESSAGE';

export const USER_ACTIVATE = 'USER_ACTIVATE';

export const USER_ROLE_GET_BY_ID = 'USER_ROLE_GET_BY_ID';
export const USER_ROLE_GET_BY_ID_SUCCESS = 'USER_ROLE_GET_BY_ID_SUCCESS';
export const USER_ROLE_GET_BY_ID_ERROR = 'USER_ROLE_GET_BY_ID_ERROR';
export const USER_ROLE_UPDATE = 'USER_ROLE_UPDATE';
export const USER_ROLE_UPDATE_ERROR = 'USER_RESET_PASSWORD_ERROR';
export const USER_ROLE_UPDATE_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';

export const USER_ROLE_GET_LIST = 'USER_ROLE_GET_LIST';
export const USER_ROLE_GET_LIST_SUCCESS = 'USER_GET_LIST_SUCCESS';
export const USER_ROLE_GET_LIST_ERROR = 'USER_GET_LIST_ERROR';

export const USER_ROLE_DELETE_LIST = 'USER_ROLE_GET_LIST';
export const USER_ROLE_DELETE_LIST_SUCCESS = 'USER_GET_LIST_SUCCESS';
export const USER_ROLE_DELETE_LIST_ERROR = 'USER_GET_LIST_ERROR';

export const USER_DISABLED_OR_ENABLED = 'USER_DISABLED_OR_ENABLED';
export const USER_DISABLED_OR_ENABLED_ERROR = 'USER_DISABLED_OR_ENABLED_ERROR';
export const USER_DISABLED_OR_ENABLED_SUCCESS = 'USER_DISABLED_OR_ENABLED_SUCCESS';

export const FORCE_GLOBAL_SIGNOUT = 'FORCE_GLOBAL_SIGNOUT';
export const FORCE_GLOBAL_SIGNOUT_ERROR = 'FORCE_GLOBAL_SIGNOUT_ERROR';
export const FORCE_GLOBAL_SIGNOUT_SUCCESS = 'FORCE_GLOBAL_SIGNOUT_SUCCESS';

export const UPDATE_USER_IS_ADMIN = 'UPDATE_USER_IS_ADMIN';

/** ASSESSEMENT */
export const ASSESSEMENT_GET_LIST = 'ASSESSEMENT_GET_LIST';
export const ASSESSEMENT_GET_LIST_SUCCESS = 'ASSESSEMENT_GET_LIST_SUCCESS';
export const ASSESSEMENT_GET_LIST_ERROR = 'ASSESSEMENT_GET_LIST_ERROR';

export const ASSESSEMENT_GET_BY_ID = 'ASSESSEMENT_GET_BY_ID';
export const ASSESSEMENT_GET_BY_ID_SUCCESS = 'ASSESSEMENT_GET_BY_ID_SUCCESS';
export const ASSESSEMENT_GET_BY_ID_ERROR = 'ASSESSEMENT_GET_BY_ID_ERROR';

export const ASSESSEMENT_ADD_NEW = 'ASSESSEMENT_ADD_NEW';
export const ASSESSEMENT_ADD_NEW_SUCCESS = 'ASSESSEMENT_ADD_NEW_SUCCESS';
export const ASSESSEMENT_ADD_NEW_ERROR = 'ASSESSEMENT_ADD_NEW_ERROR';
export const ASSESSEMENT_ADD_NEW_REQUEST = 'ASSESSEMENT_ADD_NEW_REQUEST';

export const ASSESSEMENT_EDIT = 'ASSESSEMENT_EDIT';
export const ASSESSEMENT_EDIT_SUCCESS = 'ASSESSEMENT_EDIT_SUCCESS';
export const ASSESSEMENT_EDIT_ERROR = 'ASSESSEMENT_EDIT_ERROR';

export const ASSESSEMENT_DELETE = 'ASSESSEMENT_DELETE';
export const ASSESSEMENT_DELETE_SUCCESS = 'ASSESSEMENT_DELETE_SUCCESS';
export const ASSESSEMENT_DELETE_ERROR = 'ASSESSEMENT_DELETE_ERROR';

/*MY PROFILE*/
export const USER_PROFILE_GET_BY_ID = 'USER_PROFILE_GET_BY_ID';
export const USER_PROFILE_GET_BY_ID_SUCCESS = 'USER_PROFILE_GET_BY_ID_SUCCESS';
export const USER_PROFILE_GET_BY_ID_ERROR = 'USER_PROFILE_GET_BY_ID_ERROR';
export const USER_PROFILE_UPDATE = 'USER_PROFILE_UPDATE';
export const USER_PROFILE_UPDATE_ERROR = 'USER_RESET_PASSWORD_ERROR';
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS';

export const USER_PROFILE_GET_LIST = 'USER_PROFILE_GET_LIST';
export const USER_PROFILE_GET_LIST_SUCCESS = 'USER_PROFILE_GET_LIST_SUCCESS';
export const USER_PROFILE_GET_LIST_ERROR = 'USER_GET_LIST_ERROR';

export const USER_PROFILE_DELETE_LIST = 'USER_PROFILE_GET_LIST';
export const USER_PROFILE_DELETE_LIST_SUCCESS = 'USER_PROFILE_DELETE_LIST_SUCCESS';
export const USER_PROFILE_DELETE_LIST_ERROR = 'USER_PROFILE_DELETE_LIST_ERROR';

export const USER_PROFILE_PICTURE_UPDATE = 'USER_PROFILE_PICTURE_UPDATE';
export const USER_PROFILE_PICTURE_UPDATE_ERROR = 'USER_PROFILE_PICTURE_UPDATE_ERROR';
export const USER_PROFILE_PICTURE_UPDATE_SUCCESS = 'USER_PROFILE_PICTURE_UPDATE_SUCCESS';

export const CLIENT_AGGREGATE_UPDATE = 'CLIENT_AGGREGATE_UPDATE';
export const CLIENT_AGGREGATE_UPDATE_ERROR = 'CLIENT_AGGREGATE_UPDATE_ERROR';
export const CLIENT_AGGREGATE_UPDATE_SUCCESS = 'CLIENT_AGGREGATE_UPDATE_SUCCESS';

export const CLIENT_AGGREGATE_GET_BY_ID = 'CLIENT_AGGREGATE_GET_BY_ID';
export const CLIENT_AGGREGATE_GET_BY_ID_SUCCESS = 'CLIENT_AGGREGATE_GET_BY_ID_SUCCESS';
export const CLIENT_AGGREGATE_GET_BY_ID_ERROR = 'CLIENT_AGGREGATE_GET_BY_ID_ERROR';

// Aggregate

export const AGGREGATE_REPORT_GET_BY_ID = 'AGGREGATE_REPORT_GET_BY_ID';
export const AGGREGATE_REPORT_GET_BY_ID_SUCCESS = 'AGGREGATE_REPORT_GET_BY_ID_SUCCESS';
export const AGGREGATE_REPORT_GET_BY_ID_ERROR = 'AGGREGATE_REPORT_GET_BY_ID_ERROR';

export const AGGREGATE_REPORT_GET_LIST = 'AGGREGATE_REPORT_GET_LIST';
export const AGGREGATE_REPORT_GET_LIST_SUCCESS = 'AGGREGATE_REPORT_GET_LIST_SUCCESS';
export const AGGREGATE_REPORT_GET_LIST_ERROR = 'AGGREGATE_REPORT_GET_LIST_ERROR';

export const AGGREGATE_REPORT_EDIT = 'AGGREGATE_REPORT_EDIT';
export const AGGREGATE_REPORT_EDIT_SUCCESS = 'AGGREGATE_REPORT_EDIT_SUCCESS';
export const AGGREGATE_REPORT_EDIT_ERROR = 'AGGREGATE_REPORT_EDIT_ERROR';

export const AGGREGATE_GET_LIST = 'AGGREGATE_GET_LIST';
export const AGGREGATE_GET_LIST_SUCCESS = 'AGGREGATE_GET_LIST_SUCCESS';
export const AGGREGATE_GET_LIST_ERROR = 'AGGREGATE_GET_LIST_ERROR';

export const AGGREGATE_GET_BY_ID = 'AGGREGATE_GET_BY_ID';
export const AGGREGATE_GET_BY_ID_SUCCESS = 'AGGREGATE_GET_BY_ID_SUCCESS';
export const AGGREGATE_GET_BY_ID_ERROR = 'AGGREGATE_GET_BY_ID_ERROR';

export const AGGREGATE_ADD_NEW = 'AGGREGATE_ADD_NEW';
export const AGGREGATE_ADD_NEW_SUCCESS = 'AGGREGATE_ADD_NEW_SUCCESS';
export const AGGREGATE_ADD_NEW_ERROR = 'AGGREGATE_ADD_NEW_ERROR';
export const AGGREGATE_ADD_NEW_REQUEST = 'AGGREGATE_ADD_NEW_REQUEST';

export const AGGREGATE_EDIT = 'AGGREGATE_EDIT';
export const AGGREGATE_EDIT_SUCCESS = 'AGGREGATE_EDIT_SUCCESS';
export const AGGREGATE_EDIT_ERROR = 'AGGREGATE_EDIT_ERROR';

export const AGGREGATE_DELETE = 'AGGREGATE_DELETE';
export const AGGREGATE_DELETE_SUCCESS = 'AGGREGATE_DELETE_SUCCESS';
export const AGGREGATE_DELETE_ERROR = 'AGGREGATE_DELETE_ERROR';

/* NOTIFICATION*/
export const NOTIFICATION_GET_LIST = 'NOTIFICATION_GET_LIST';
export const NOTIFICATION_GET_LIST_SUCCESS = 'NOTIFICATION_GET_LIST_SUCCESS';
export const NOTIFICATION_GET_LIST_ERROR = 'NOTIFICATION_GET_LIST_ERROR';

/** Client Fund */
export const CLIENT_FUND_GET_BY_ID = 'CLIENT_FUND_GET_BY_ID';
export const CLIENT_FUND_GET_BY_ID_SUCCESS = 'CLIENT_FUND_GET_BY_ID_SUCCESS';
export const CLIENT_FUND_GET_BY_ID_ERROR = 'CLIENT_FUND_GET_BY_ID_ERROR';

export const CLIENT_FUND_GET_LIST = 'CLIENT_FUND_GET_LIST';
export const CLIENT_FUND_GET_LIST_SUCCESS = 'CLIENT_FUND_GET_LIST_SUCCESS';
export const CLIENT_FUND_GET_LIST_ERROR = 'CLIENT_FUND_GET_LIST_ERROR';

export const CLIENT_FUND_EDIT = 'CLIENT_FUND_EDIT';
export const CLIENT_FUND_EDIT_SUCCESS = 'CLIENT_FUND_EDIT_SUCCESS';
export const CLIENT_FUND_EDIT_ERROR = 'CLIENT_FUND_EDIT_ERROR';

export const CLIENT_FUND_ADD_NEW = 'CLIENT_FUND_ADD_NEW';
export const CLIENT_FUND_ADD_NEW_SUCCESS = 'CLIENT_FUND_ADD_NEW_SUCCESS';
export const CLIENT_FUND_ADD_NEW_ERROR = 'CLIENT_FUND_ADD_NEW_ERROR';
export const CLIENT_FUND_ADD_NEW_REQUEST = 'CLIENT_FUND_ADD_NEW_REQUEST';

export const CLIENT_FUND_DELETE = 'CLIENT_FUND_DELETE';
export const CLIENT_FUND_DELETE_SUCCESS = 'CLIENT_FUND_DELETE_SUCCESS';
export const CLIENT_FUND_DELETE_ERROR = 'CLIENT_FUND_DELETE_ERROR';

export const CLIENT_FUND_LOG_LIST = 'CLIENT_FUND_LOG_LIST';
export const CLIENT_FUND_LOG_LIST_SUCCESS = 'CLIENT_FUND_LOG_LIST_SUCCESS';
export const CLIENT_FUND_LOG_LIST_ERROR = 'CLIENT_FUND_LOG_LIST_ERROR';

export const CLIENT_FUND_DELETE_ATTACHMENT = 'CLIENT_FUND_DELETE_ATTACHMENT';
export const CLIENT_FUND_DELETE_ATTACHMENT_SUCCESS = 'CLIENT_FUND_DELETE_ATTACHMENT_SUCCESS';
export const CLIENT_FUND_DELETE_ATTACHMENT_ERROR = 'CLIENT_FUND_DELETE_ATTACHMENT_ERROR';

export const CLIENT_FUND_REPORT = 'CLIENT_FUND_REPORT';
export const CLIENT_FUND_REPORT_SUCCESS = 'CLIENT_FUND_REPORT_SUCCESS';
export const CLIENT_FUND_REPORT_ERROR = 'CLIENT_FUND_REPORT_ERROR';

/** RESUME */
export const RESUME_ADD_NEW = 'RESUME_ADD_NEW';
export const RESUME_ADD_NEW_SUCCESS = 'RESUME_ADD_NEW_SUCCESS';
export const RESUME_ADD_NEW_ERROR = 'RESUME_ADD_NEW_ERROR';
export const RESUME_ADD_NEW_REQUEST = 'RESUME_ADD_NEW_REQUEST';

export const RESUME_DELETE = 'RESUME_DELETE';
export const RESUME_DELETE_ERROR = 'RESUME_DELETE_ERROR';
export const RESUME_DELETE_SUCCESS = 'RESUME_DELETE_SUCCESS';

export const RESUME_GET_BY_CLIENT_ID = 'RESUME_GET_BY_CLIENT_ID';
export const RESUME_GET_BY_CLIENT_ID_ERROR = 'RESUME_GET_BY_CLIENT_ID_ERROR';
export const RESUME_GET_BY_CLIENT_ID_SUCCESS = 'RESUME_GET_BY_CLIENT_ID_SUCCESS';

/** CaMSImport */

export const CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS = 'CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS';
export const CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS_SUCCESS =
  'CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS_SUCCESS';
export const CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS_ERROR =
  'CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS_ERROR';

export const CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS = 'CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS';
export const CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS_SUCCESS =
  'CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS_SUCCESS';
export const CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS_ERROR =
  'CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS_ERROR';

export const CAMS_IMPORT_GET_LIST = 'CAMS_IMPORT_GET_LIST';
export const CAMS_IMPORT_GET_LIST_SUCCESS = 'CAMS_IMPORT_GET_LIST_SUCCESS';
export const CAMS_IMPORT_GET_LIST_ERROR = 'CAMS_IMPORT_GET_LIST_ERROR';

export const CAMS_IMPORT_GET_BY_ID = 'CASE_NOTE2_GET_BY_ID';
export const CAMS_IMPORT_GET_BY_ID_SUCCESS = 'CAMS_IMPORT_GET_BY_ID_SUCCESS';
export const CAMS_IMPORT_GET_BY_ID_ERROR = 'CAMS_IMPORT_GET_BY_ID_ERROR';

export const CAMS_IMPORT_DETAIL = 'CAMS_IMPORT_DETAIL';
export const CAMS_IMPORT_DETAIL_SUCCESS = 'CAMS_IMPORT_DETAIL_SUCCESS';
export const CAMS_IMPORT_DETAIL_ERROR = 'CAMS_IMPORT_DETAIL_ERROR';

export const CAMS_IMPORT_ADD_NEW = 'CAMS_IMPORT_ADD_NEW';
export const CAMS_IMPORT_ADD_NEW_SUCCESS = 'CAMS_IMPORT_ADD_NEW_SUCCESS';
export const CAMS_IMPORT_ADD_NEW_ERROR = 'CAMS_IMPORT_ADD_NEW_ERROR';
export const CAMS_IMPORT_ADD_NEW_REQUEST = 'CAMS_IMPORT_ADD_NEW_REQUEST';

export const CAMS_IMPORT_EDIT = 'CAMS_IMPORT_EDIT';
export const CAMS_IMPORT_EDIT_SUCCESS = 'CAMS_IMPORT_EDIT_SUCCESS';
export const CAMS_IMPORT_EDIT_ERROR = 'CAMS_IMPORT_EDIT_ERROR';

export const CAMS_IMPORT_DELETE = 'CAMS_IMPORT_DELETE';
export const CAMS_IMPORT_DELETE_SUCCESS = 'CAMS_IMPORT_DELETE_SUCCESS';
export const CAMS_IMPORT_DELETE_ERROR = 'CAMS_IMPORT_DELETE_ERROR';

export const CAMS_IMPORT_READ_FILENAME = 'CAMS_IMPORT_READ_FILENAME';
export const CAMS_IMPORT_READ_FILENAME_SUCCESS = 'CAMS_IMPORT_READ_FILENAME_SUCCESS';
export const CAMS_IMPORT_READ_FILENAME_ERROR = 'CAMS_IMPORT_READ_FILENAME_ERROR';

export const CAMS_IMPORT_UPDATE_CLIENT = 'CAMS_IMPORT_UPDATE_CLIENT';
export const CAMS_IMPORT_IMPORT_ALL_CLIENTS = 'CAMS_IMPORT_IMPORT_ALL_CLIENTS';
export const CAMS_IMPORT_IMPORT_ALL_CLIENTS_SUCCESS = 'CAMS_IMPORT_IMPORT_ALL_CLIENTS_SUCCESS';
export const CAMS_IMPORT_IMPORT_ALL_CLIENTS_ERROR = 'CAMS_IMPORT_IMPORT_ALL_CLIENTS_ERROR';
export const CAMS_IMPORT_CLOSE_NOTIFICATION = 'CAMS_IMPORT_CLOSE_NOTIFICATION';
export const CAMS_IMPORT_FILTER_CLIENT_DATA = 'CAMS_IMPORT_FILTER_CLIENT_DATA';

export const CAMS_IMPORT_IMPORT_DFECLT = 'CAMS_IMPORT_IMPORT_DFECLT';
export const CAMS_IMPORT_IMPORT_DFECLT_SUCCESS = 'CAMS_IMPORT_IMPORT_DFECLT_SUCCESS';
export const CAMS_IMPORT_IMPORT_DFECLT_ERROR = 'CAMS_IMPORT_IMPORT_DFECLT_ERROR';
export const CAMS_IMPORT_UPDATE_DFECLT = 'CAMS_IMPORT_UPDATE_DFECLT';
export const CAMS_IMPORT_FILTER_DFECLT_DATA = 'CAMS_IMPORT_FILTER_DFECLT_DATA';

export const CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS = 'CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS';
export const CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS_SUCCESS =
  'CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS_SUCCESS';
export const CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS_ERROR = 'CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS_ERROR';

export const CAMS_IMPORT_IMPORT_DFESPN = 'CAMS_IMPORT_IMPORT_DFESPN';
export const CAMS_IMPORT_IMPORT_DFESPN_SUCCESS = 'CAMS_IMPORT_IMPORT_DFESPN_SUCCESS';
export const CAMS_IMPORT_IMPORT_DFESPN_ERROR = 'CAMS_IMPORT_IMPORT_DFESPN_ERROR';
export const CAMS_IMPORT_UPDATE_DFESPN = 'CAMS_IMPORT_UPDATE_DFESPN';

export const CAMS_SYNC_DATA_COJG_DFTSPN = 'CAMS_SYNC_DATA_COJG_DFTSPN';
export const CAMS_SYNC_DATA_COJG_DFTSPN_SUCCESS = 'CAMS_SYNC_DATA_COJG_DFTSPN_SUCCESS';
export const CAMS_SYNC_DATA_COJG_DFTSPN_ERROR = 'CAMS_SYNC_DATA_COJG_DFTSPN_ERROR';

export const CAMS_IMPORT_IMPORT_DFFOL = 'CAMS_IMPORT_IMPORT_DFFOL';
export const CAMS_IMPORT_IMPORT_DFFOL_SUCCESS = 'CAMS_IMPORT_IMPORT_DFFOL_SUCCESS';
export const CAMS_IMPORT_IMPORT_DFFOL_ERROR = 'CAMS_IMPORT_IMPORT_DFFOL_ERROR';
export const CAMS_IMPORT_UPDATE_DFFOL = 'CAMS_IMPORT_UPDATE_DFFOL';

export const CAMS_IMPORT_IMPORT_ESDFRIP = 'CAMS_IMPORT_IMPORT_ESDFRIP';
export const CAMS_IMPORT_IMPORT_ESDFRIP_SUCCESS = 'CAMS_IMPORT_IMPORT_ESDFRIP_SUCCESS';
export const CAMS_IMPORT_IMPORT_ESDFRIP_ERROR = 'CAMS_IMPORT_IMPORT_ESDFRIP_ERROR';
export const CAMS_IMPORT_UPDATE_ESDFRIP = 'CAMS_IMPORT_UPDATE_ESDFRIP';

export const CAMS_SYNC_DATA_ES_DFSPN = 'CAMS_SYNC_DATA_ES_DFSPN';
export const CAMS_SYNC_DATA_ES_DFSPN_SUCCESS = 'CAMS_SYNC_DATA_ES_DFSPN_SUCCESS';
export const CAMS_SYNC_DATA_ES_DFSPN_ERROR = 'CAMS_SYNC_DATA_ES_DFSPN_ERROR';

export const CAMS_IMPORT_IMPORT_ESDFRIC = 'CAMS_IMPORT_IMPORT_ESDFRIC';
export const CAMS_IMPORT_IMPORT_ESDFRIC_SUCCESS = 'CAMS_IMPORT_IMPORT_ESDFRIC_SUCCESS';
export const CAMS_IMPORT_IMPORT_ESDFRIC_ERROR = 'CAMS_IMPORT_IMPORT_ESDFRIC_ERROR';
export const CAMS_IMPORT_UPDATE_ESDFRIC = 'CAMS_IMPORT_UPDATE_ESDFRIC';

export const CAMS_SYNC_DATA_ES_DFSPI = 'CAMS_SYNC_DATA_ES_DFSPI';
export const CAMS_SYNC_DATA_ES_DFSPI_SUCCESS = 'CAMS_SYNC_DATA_ES_DFSPI_SUCCESS';
export const CAMS_SYNC_DATA_ES_DFSPI_ERROR = 'CAMS_SYNC_DATA_ES_DFSPI_ERROR';
export const CAMS_IMPORT_UPDATE_ESDFSPI = 'CAMS_IMPORT_UPDATE_ESDFSPI';

export const CAMS_SYNC_DATA_ES_DFRIS = 'CAMS_SYNC_DATA_ES_DFRIS';
export const CAMS_SYNC_DATA_ES_DFRIS_SUCCESS = 'CAMS_SYNC_DATA_ES_DFRIS_SUCCESS';
export const CAMS_SYNC_DATA_ES_DFRIS_ERROR = 'CAMS_SYNC_DATA_ES_DFRIS_ERROR';

/** EMPLOYER CASE NOTE */

export const EMPLOYER_CASE_NOTE_QUERY = 'EMPLOYER_CASE_NOTE_QUERY';
export const EMPLOYER_CASE_NOTE_QUERY_SUCCESS = 'EMPLOYER_CASE_NOTE_QUERY_SUCCESS';
export const EMPLOYER_CASE_NOTE_QUERY_ERROR = 'EMPLOYER_CASE_NOTE_QUERY_ERROR';

export const EMPLOYER_CASE_NOTE_GET_LIST = 'EMPLOYER_CASE_NOTE_GET_LIST';
export const EMPLOYER_CASE_NOTE_GET_LIST_SUCCESS = 'EMPLOYER_CASE_NOTE_GET_LIST_SUCCESS';
export const EMPLOYER_CASE_NOTE_GET_LIST_ERROR = 'EMPLOYER_CASE_NOTE_GET_LIST_ERROR';

/** COJG_CASE_NOTE */
export const COJG_CASE_NOTE_QUERY = 'COJG_CASE_NOTE_QUERY';
export const COJG_CASE_NOTE_QUERY_SUCCESS = 'COJG_CASE_NOTE_QUERY_SUCCESS';
export const COJG_CASE_NOTE_QUERY_ERROR = 'COJG_CASE_NOTE_QUERY_ERROR';

export const COJG_CASE_NOTE_GET_LIST = 'COJG_CASE_NOTE_GET_LIST';
export const COJG_CASE_NOTE_GET_LIST_SUCCESS = 'COJG_CASE_NOTE_GET_LIST_SUCCESS';
export const COJG_CASE_NOTE_GET_LIST_ERROR = 'COJG_CASE_NOTE_GET_LIST_ERROR';

export const COJG_CASE_NOTE_GET_BY_ID = 'COJG_CASE_NOTE_GET_BY_ID';
export const COJG_CASE_NOTE_GET_BY_ID_SUCCESS = 'COJG_CASE_NOTE_GET_BY_ID_SUCCESS';
export const COJG_CASE_NOTE_GET_BY_ID_ERROR = 'COJG_CASE_NOTE_GET_BY_ID_ERROR';

export const COJG_CASE_NOTE_ADD_NEW = 'COJG_CASE_NOTE_ADD_NEW';
export const COJG_CASE_NOTE_ADD_NEW_SUCCESS = 'COJG_CASE_NOTE_ADD_NEW_SUCCESS';
export const COJG_CASE_NOTE_ADD_NEW_ERROR = 'COJG_CASE_NOTE_ADD_NEW_ERROR';
export const COJG_CASE_NOTE_ADD_NEW_REQUEST = 'COJG_CASE_NOTE_ADD_NEW_REQUEST';

export const COJG_CASE_NOTE_EDIT = 'COJG_CASE_NOTE_EDIT';
export const COJG_CASE_NOTE_EDIT_SUCCESS = 'COJG_CASE_NOTE_EDIT_SUCCESS';
export const COJG_CASE_NOTE_EDIT_ERROR = 'COJG_CASE_NOTE_EDIT_ERROR';

export const COJG_CASE_NOTE_DELETE = 'COJG_CASE_NOTE_DELETE';
export const COJG_CASE_NOTE_DELETE_SUCCESS = 'COJG_CASE_NOTE_DELETE_SUCCESS';
export const COJG_CASE_NOTE_DELETE_ERROR = 'COJG_CASE_NOTE_DELETE_ERROR';

/** EMPLOYMENT_EARNING */
export const EMPLOYMENT_EARNING_QUERY = 'EMPLOYMENT_EARNING_QUERY';
export const EMPLOYMENT_EARNING_QUERY_SUCCESS = 'EMPLOYMENT_EARNING_QUERY_SUCCESS';
export const EMPLOYMENT_EARNING_QUERY_ERROR = 'EMPLOYMENT_EARNING_QUERY_ERROR';

export const EMPLOYMENT_EARNING_GET_LIST = 'EMPLOYMENT_EARNING_GET_LIST';
export const EMPLOYMENT_EARNING_GET_LIST_SUCCESS = 'EMPLOYMENT_EARNING_GET_LIST_SUCCESS';
export const EMPLOYMENT_EARNING_GET_LIST_ERROR = 'EMPLOYMENT_EARNING_GET_LIST_ERROR';

export const EMPLOYMENT_EARNING_GET_BY_ID = 'EMPLOYMENT_EARNING_GET_BY_ID';
export const EMPLOYMENT_EARNING_GET_BY_ID_SUCCESS = 'EMPLOYMENT_EARNING_GET_BY_ID_SUCCESS';
export const EMPLOYMENT_EARNING_GET_BY_ID_ERROR = 'EMPLOYMENT_EARNING_GET_BY_ID_ERROR';

export const EMPLOYMENT_EARNING_ADD_NEW = 'EMPLOYMENT_EARNING_ADD_NEW';
export const EMPLOYMENT_EARNING_ADD_NEW_SUCCESS = 'EMPLOYMENT_EARNING_ADD_NEW_SUCCESS';
export const EMPLOYMENT_EARNING_ADD_NEW_ERROR = 'EMPLOYMENT_EARNING_ADD_NEW_ERROR';
export const EMPLOYMENT_EARNING_ADD_NEW_REQUEST = 'EMPLOYMENT_EARNING_ADD_NEW_REQUEST';

export const EMPLOYMENT_EARNING_EDIT = 'EMPLOYMENT_EARNING_EDIT';
export const EMPLOYMENT_EARNING_EDIT_SUCCESS = 'EMPLOYMENT_EARNING_EDIT_SUCCESS';
export const EMPLOYMENT_EARNING_EDIT_ERROR = 'EMPLOYMENT_EARNING_EDIT_ERROR';

export const EMPLOYMENT_EARNING_DELETE = 'EMPLOYMENT_EARNING_DELETE';
export const EMPLOYMENT_EARNING_DELETE_SUCCESS = 'EMPLOYMENT_EARNING_DELETE_SUCCESS';
export const EMPLOYMENT_EARNING_DELETE_ERROR = 'EMPLOYMENT_EARNING_DELETE_ERROR';

/** EMPLOYMENT_ACTION_PLAN */
export const EMPLOYMENT_ACTION_PLAN_QUERY = 'EMPLOYMENT_ACTION_PLAN_QUERY';
export const EMPLOYMENT_ACTION_PLAN_QUERY_SUCCESS = 'EMPLOYMENT_ACTION_PLAN_QUERY_SUCCESS';
export const EMPLOYMENT_ACTION_PLAN_QUERY_ERROR = 'EMPLOYMENT_ACTION_PLAN_QUERY_ERROR';

export const EMPLOYMENT_ACTION_PLAN_BY_CLIENT = 'EMPLOYMENT_ACTION_PLAN_BY_CLIENT';
export const EMPLOYMENT_ACTION_PLAN_BY_CLIENT_SUCCESS = 'EMPLOYMENT_ACTION_PLAN_BY_CLIENT_SUCCESS';
export const EMPLOYMENT_ACTION_PLAN_BY_CLIENT_ERROR = 'EMPLOYMENT_ACTION_PLAN_BY_CLIENT_ERROR';

export const EMPLOYMENT_ACTION_PLAN_GET_LIST = 'EMPLOYMENT_ACTION_PLAN_GET_LIST';
export const EMPLOYMENT_ACTION_PLAN_GET_LIST_SUCCESS = 'EMPLOYMENT_ACTION_PLAN_GET_LIST_SUCCESS';
export const EMPLOYMENT_ACTION_PLAN_GET_LIST_ERROR = 'EMPLOYMENT_ACTION_PLAN_GET_LIST_ERROR';

export const EMPLOYMENT_ACTION_PLAN_GET_BY_ID = 'EMPLOYMENT_ACTION_PLAN_GET_BY_ID';
export const EMPLOYMENT_ACTION_PLAN_GET_BY_ID_SUCCESS = 'EMPLOYMENT_ACTION_PLAN_GET_BY_ID_SUCCESS';
export const EMPLOYMENT_ACTION_PLAN_GET_BY_ID_ERROR = 'EMPLOYMENT_ACTION_PLAN_GET_BY_ID_ERROR';

export const EMPLOYMENT_ACTION_PLAN_ADD_NEW = 'EMPLOYMENT_ACTION_PLAN_ADD_NEW';
export const EMPLOYMENT_ACTION_PLAN_ADD_NEW_SUCCESS = 'EMPLOYMENT_ACTION_PLAN_ADD_NEW_SUCCESS';
export const EMPLOYMENT_ACTION_PLAN_ADD_NEW_ERROR = 'EMPLOYMENT_ACTION_PLAN_ADD_NEW_ERROR';
export const EMPLOYMENT_ACTION_PLAN_ADD_NEW_REQUEST = 'EMPLOYMENT_ACTION_PLAN_ADD_NEW_REQUEST';

export const EMPLOYMENT_ACTION_PLAN_EDIT = 'EMPLOYMENT_ACTION_PLAN_EDIT';
export const EMPLOYMENT_ACTION_PLAN_EDIT_SUCCESS = 'EMPLOYMENT_ACTION_PLAN_EDIT_SUCCESS';
export const EMPLOYMENT_ACTION_PLAN_EDIT_ERROR = 'EMPLOYMENT_ACTION_PLAN_EDIT_ERROR';

export const EMPLOYMENT_ACTION_PLAN_DELETE = 'EMPLOYMENT_ACTION_PLAN_DELETE';
export const EMPLOYMENT_ACTION_PLAN_DELETE_SUCCESS = 'EMPLOYMENT_ACTION_PLAN_DELETE_SUCCESS';
export const EMPLOYMENT_ACTION_PLAN_DELETE_ERROR = 'EMPLOYMENT_ACTION_PLAN_DELETE_ERROR';

export const EMPLOYMENT_ACTION_PLAN_NOTIFICATION_CLOSED =
  'EMPLOYMENT_ACTION_PLAN_NOTIFICATION_CLOSED';

/** YJCS_CHEQUE */
export const YJCS_CHEQUE_QUERY = 'YJCS_CHEQUE_QUERY';
export const YJCS_CHEQUE_QUERY_SUCCESS = 'YJCS_CHEQUE_QUERY_SUCCESS';
export const YJCS_CHEQUE_QUERY_ERROR = 'YJCS_CHEQUE_QUERY_ERROR';

export const YJCS_CHEQUE_GET_LIST = 'YJCS_CHEQUE_GET_LIST';
export const YJCS_CHEQUE_GET_LIST_SUCCESS = 'YJCS_CHEQUE_GET_LIST_SUCCESS';
export const YJCS_CHEQUE_GET_LIST_ERROR = 'YJCS_CHEQUE_GET_LIST_ERROR';

export const YJCS_CHEQUE_GET_BY_ID = 'YJCS_CHEQUE_GET_BY_ID';
export const YJCS_CHEQUE_GET_BY_ID_SUCCESS = 'YJCS_CHEQUE_GET_BY_ID_SUCCESS';
export const YJCS_CHEQUE_GET_BY_ID_ERROR = 'YJCS_CHEQUE_GET_BY_ID_ERROR';

export const YJCS_CHEQUE_ADD_NEW = 'YJCS_CHEQUE_ADD_NEW';
export const YJCS_CHEQUE_ADD_NEW_SUCCESS = 'YJCS_CHEQUE_ADD_NEW_SUCCESS';
export const YJCS_CHEQUE_ADD_NEW_ERROR = 'YJCS_CHEQUE_ADD_NEW_ERROR';
export const YJCS_CHEQUE_ADD_NEW_REQUEST = 'YJCS_CHEQUE_ADD_NEW_REQUEST';

export const YJCS_CHEQUE_EDIT = 'YJCS_CHEQUE_EDIT';
export const YJCS_CHEQUE_EDIT_SUCCESS = 'YJCS_CHEQUE_EDIT_SUCCESS';
export const YJCS_CHEQUE_EDIT_ERROR = 'YJCS_CHEQUE_EDIT_ERROR';

export const YJCS_CHEQUE_DELETE = 'YJCS_CHEQUE_DELETE';
export const YJCS_CHEQUE_DELETE_SUCCESS = 'YJCS_CHEQUE_DELETE_SUCCESS';
export const YJCS_CHEQUE_DELETE_ERROR = 'YJCS_CHEQUE_DELETE_ERROR';

/** YJC_CHEQUE */
export const YJC_CHEQUE_QUERY = 'YJC_CHEQUE_QUERY';
export const YJC_CHEQUE_QUERY_SUCCESS = 'YJC_CHEQUE_QUERY_SUCCESS';
export const YJC_CHEQUE_QUERY_ERROR = 'YJC_CHEQUE_QUERY_ERROR';

export const YJC_CHEQUE_GET_LIST = 'YJC_CHEQUE_GET_LIST';
export const YJC_CHEQUE_GET_LIST_SUCCESS = 'YJC_CHEQUE_GET_LIST_SUCCESS';
export const YJC_CHEQUE_GET_LIST_ERROR = 'YJC_CHEQUE_GET_LIST_ERROR';

export const YJC_CHEQUE_GET_BY_ID = 'YJC_CHEQUE_GET_BY_ID';
export const YJC_CHEQUE_GET_BY_ID_SUCCESS = 'YJC_CHEQUE_GET_BY_ID_SUCCESS';
export const YJC_CHEQUE_GET_BY_ID_ERROR = 'YJC_CHEQUE_GET_BY_ID_ERROR';

export const YJC_CHEQUE_ADD_NEW = 'YJC_CHEQUE_ADD_NEW';
export const YJC_CHEQUE_ADD_NEW_SUCCESS = 'YJC_CHEQUE_ADD_NEW_SUCCESS';
export const YJC_CHEQUE_ADD_NEW_ERROR = 'YJC_CHEQUE_ADD_NEW_ERROR';
export const YJC_CHEQUE_ADD_NEW_REQUEST = 'YJC_CHEQUE_ADD_NEW_REQUEST';

export const YJC_CHEQUE_EDIT = 'YJC_CHEQUE_EDIT';
export const YJC_CHEQUE_EDIT_SUCCESS = 'YJC_CHEQUE_EDIT_SUCCESS';
export const YJC_CHEQUE_EDIT_ERROR = 'YJC_CHEQUE_EDIT_ERROR';

export const YJC_CHEQUE_DELETE = 'YJC_CHEQUE_DELETE';
export const YJC_CHEQUE_DELETE_SUCCESS = 'YJC_CHEQUE_DELETE_SUCCESS';
export const YJC_CHEQUE_DELETE_ERROR = 'YJC_CHEQUE_DELETE_ERROR';

/** ES_CASE_NOTE */
export const ES_CASE_NOTE_QUERY = 'ES_CASE_NOTE_QUERY';
export const ES_CASE_NOTE_QUERY_SUCCESS = 'ES_CASE_NOTE_QUERY_SUCCESS';
export const ES_CASE_NOTE_QUERY_ERROR = 'ES_CASE_NOTE_QUERY_ERROR';

export const ES_CASE_NOTE_GET_LIST = 'ES_CASE_NOTE_GET_LIST';
export const ES_CASE_NOTE_GET_LIST_SUCCESS = 'ES_CASE_NOTE_GET_LIST_SUCCESS';
export const ES_CASE_NOTE_GET_LIST_ERROR = 'ES_CASE_NOTE_GET_LIST_ERROR';

export const ES_CASE_NOTE_GET_BY_ID = 'ES_CASE_NOTE_GET_BY_ID';
export const ES_CASE_NOTE_GET_BY_ID_SUCCESS = 'ES_CASE_NOTE_GET_BY_ID_SUCCESS';
export const ES_CASE_NOTE_GET_BY_ID_ERROR = 'ES_CASE_NOTE_GET_BY_ID_ERROR';

export const ES_CASE_NOTE_ADD_NEW = 'ES_CASE_NOTE_ADD_NEW';
export const ES_CASE_NOTE_ADD_NEW_SUCCESS = 'ES_CASE_NOTE_ADD_NEW_SUCCESS';
export const ES_CASE_NOTE_ADD_NEW_ERROR = 'ES_CASE_NOTE_ADD_NEW_ERROR';
export const ES_CASE_NOTE_ADD_NEW_REQUEST = 'ES_CASE_NOTE_ADD_NEW_REQUEST';

export const ES_CASE_NOTE_EDIT = 'ES_CASE_NOTE_EDIT';
export const ES_CASE_NOTE_EDIT_SUCCESS = 'ES_CASE_NOTE_EDIT_SUCCESS';
export const ES_CASE_NOTE_EDIT_ERROR = 'ES_CASE_NOTE_EDIT_ERROR';

export const ES_CASE_NOTE_DELETE = 'ES_CASE_NOTE_DELETE';
export const ES_CASE_NOTE_DELETE_SUCCESS = 'ES_CASE_NOTE_DELETE_SUCCESS';
export const ES_CASE_NOTE_DELETE_ERROR = 'ES_CASE_NOTE_DELETE_ERROR';

/** SE_CASE_NOTE */
export const SE_CASE_NOTE_QUERY = 'SE_CASE_NOTE_QUERY';
export const SE_CASE_NOTE_QUERY_SUCCESS = 'SE_CASE_NOTE_QUERY_SUCCESS';
export const SE_CASE_NOTE_QUERY_ERROR = 'SE_CASE_NOTE_QUERY_ERROR';

export const SE_CASE_NOTE_GET_LIST = 'SE_CASE_NOTE_GET_LIST';
export const SE_CASE_NOTE_GET_LIST_SUCCESS = 'SE_CASE_NOTE_GET_LIST_SUCCESS';
export const SE_CASE_NOTE_GET_LIST_ERROR = 'SE_CASE_NOTE_GET_LIST_ERROR';

export const SE_CASE_NOTE_GET_BY_ID = 'SE_CASE_NOTE_GET_BY_ID';
export const SE_CASE_NOTE_GET_BY_ID_SUCCESS = 'SE_CASE_NOTE_GET_BY_ID_SUCCESS';
export const SE_CASE_NOTE_GET_BY_ID_ERROR = 'SE_CASE_NOTE_GET_BY_ID_ERROR';

export const SE_CASE_NOTE_ADD_NEW = 'SE_CASE_NOTE_ADD_NEW';
export const SE_CASE_NOTE_ADD_NEW_SUCCESS = 'SE_CASE_NOTE_ADD_NEW_SUCCESS';
export const SE_CASE_NOTE_ADD_NEW_ERROR = 'SE_CASE_NOTE_ADD_NEW_ERROR';
export const SE_CASE_NOTE_ADD_NEW_REQUEST = 'SE_CASE_NOTE_ADD_NEW_REQUEST';

export const SE_CASE_NOTE_EDIT = 'SE_CASE_NOTE_EDIT';
export const SE_CASE_NOTE_EDIT_SUCCESS = 'SE_CASE_NOTE_EDIT_SUCCESS';
export const SE_CASE_NOTE_EDIT_ERROR = 'SE_CASE_NOTE_EDIT_ERROR';

export const SE_CASE_NOTE_DELETE = 'SE_CASE_NOTE_DELETE';
export const SE_CASE_NOTE_DELETE_SUCCESS = 'SE_CASE_NOTE_DELETE_SUCCESS';
export const SE_CASE_NOTE_DELETE_ERROR = 'SE_CASE_NOTE_DELETE_ERROR';

/** SITE */
export const SITE_QUERY = 'SITE_QUERY';
export const SITE_QUERY_SUCCESS = 'SITE_QUERY_SUCCESS';
export const SITE_QUERY_ERROR = 'SITE_QUERY_ERROR';

export const SITE_GET_LIST = 'SITE_GET_LIST';
export const SITE_GET_LIST_SUCCESS = 'SITE_GET_LIST_SUCCESS';
export const SITE_GET_LIST_ERROR = 'SITE_GET_LIST_ERROR';

export const SITE_GET_BY_ID = 'SITE_GET_BY_ID';
export const SITE_GET_BY_ID_SUCCESS = 'SITE_GET_BY_ID_SUCCESS';
export const SITE_GET_BY_ID_ERROR = 'SITE_GET_BY_ID_ERROR';

export const SITE_ADD_NEW = 'SITE_ADD_NEW';
export const SITE_ADD_NEW_SUCCESS = 'SITE_ADD_NEW_SUCCESS';
export const SITE_ADD_NEW_ERROR = 'SITE_ADD_NEW_ERROR';
export const SITE_ADD_NEW_REQUEST = 'SITE_ADD_NEW_REQUEST';

export const SITE_EDIT = 'SITE_EDIT';
export const SITE_EDIT_SUCCESS = 'SITE_EDIT_SUCCESS';
export const SITE_EDIT_ERROR = 'SITE_EDIT_ERROR';

export const SITE_DELETE = 'SITE_DELETE';
export const SITE_DELETE_SUCCESS = 'SITE_DELETE_SUCCESS';
export const SITE_DELETE_ERROR = 'SITE_DELETE_ERROR';

/** FORM_DOCUMENT */
export const FORM_DOCUMENT_GET_LIST = 'FORM_DOCUMENT_GET_LIST';
export const FORM_DOCUMENT_GET_LIST_SUCCESS = 'FORM_DOCUMENT_GET_LIST_SUCCESS';
export const FORM_DOCUMENT_GET_LIST_ERROR = 'FORM_DOCUMENT_GET_LIST_ERROR';

export const FORM_DOCUMENT_GET_BY_ID = 'FORM_DOCUMENT_GET_BY_ID';
export const FORM_DOCUMENT_GET_BY_ID_SUCCESS = 'FORM_DOCUMENT_GET_BY_ID_SUCCESS';
export const FORM_DOCUMENT_GET_BY_ID_ERROR = 'FORM_DOCUMENT_GET_BY_ID_ERROR';

export const FORM_DOCUMENT_ADD_NEW = 'FORM_DOCUMENT_ADD_NEW';
export const FORM_DOCUMENT_ADD_NEW_SUCCESS = 'FORM_DOCUMENT_ADD_NEW_SUCCESS';
export const FORM_DOCUMENT_ADD_NEW_ERROR = 'FORM_DOCUMENT_ADD_NEW_ERROR';
export const FORM_DOCUMENT_ADD_NEW_REQUEST = 'FORM_DOCUMENT_ADD_NEW_REQUEST';

export const FORM_DOCUMENT_EDIT = 'FORM_DOCUMENT_EDIT';
export const FORM_DOCUMENT_EDIT_SUCCESS = 'FORM_DOCUMENT_EDIT_SUCCESS';
export const FORM_DOCUMENT_EDIT_ERROR = 'FORM_DOCUMENT_EDIT_ERROR';

export const FORM_DOCUMENT_DELETE = 'FORM_DOCUMENT_DELETE';
export const FORM_DOCUMENT_DELETE_SUCCESS = 'FORM_DOCUMENT_DELETE_SUCCESS';
export const FORM_DOCUMENT_DELETE_ERROR = 'FORM_DOCUMENT_DELETE_ERROR';

/** EVENT_CALENDAR */
export const EVENT_CALENDAR_GET_LIST = 'EVENT_CALENDAR_GET_LIST';
export const EVENT_CALENDAR_GET_LIST_SUCCESS = 'EVENT_CALENDAR_GET_LIST_SUCCESS';
export const EVENT_CALENDAR_GET_LIST_ERROR = 'EVENT_CALENDAR_GET_LIST_ERROR';

export const EVENT_CALENDAR_GET_BY_ID = 'EVENT_CALENDAR_GET_BY_ID';
export const EVENT_CALENDAR_GET_BY_ID_SUCCESS = 'EVENT_CALENDAR_GET_BY_ID_SUCCESS';
export const EVENT_CALENDAR_GET_BY_ID_ERROR = 'EVENT_CALENDAR_GET_BY_ID_ERROR';

export const EVENT_CALENDAR_ADD_NEW = 'EVENT_CALENDAR_ADD_NEW';
export const EVENT_CALENDAR_ADD_NEW_SUCCESS = 'EVENT_CALENDAR_ADD_NEW_SUCCESS';
export const EVENT_CALENDAR_ADD_NEW_ERROR = 'EVENT_CALENDAR_ADD_NEW_ERROR';
export const EVENT_CALENDAR_ADD_NEW_REQUEST = 'EVENT_CALENDAR_ADD_NEW_REQUEST';

export const EVENT_CALENDAR_EDIT = 'EVENT_CALENDAR_EDIT';
export const EVENT_CALENDAR_EDIT_SUCCESS = 'EVENT_CALENDAR_EDIT_SUCCESS';
export const EVENT_CALENDAR_EDIT_ERROR = 'EVENT_CALENDAR_EDIT_ERROR';
export const EVENT_CALENDAR_EDIT_REQUEST = 'EVENT_CALENDAR_EDIT_REQUEST';

export const EVENT_CALENDAR_DELETE = 'EVENT_CALENDAR_DELETE';
export const EVENT_CALENDAR_DELETE_SUCCESS = 'EVENT_CALENDAR_DELETE_SUCCESS';
export const EVENT_CALENDAR_DELETE_ERROR = 'EVENT_CALENDAR_DELETE_ERROR';

/* TODOAPP*/
export const TODO_GET_LIST = 'TODO_GET_LIST';
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';
export const TODO_UPDATE_STATUS_ITEM = 'TODO_UPDATE_STATUS_ITEM';
export const TODO_UPDATE_STATUS_ITEM_SUCCESS = 'TODO_UPDATE_STATUS_ITEM_SUCCESS';
export const TODO_UPDATE_STATUS_ITEM_ERROR = 'TODO_UPDATE_STATUS_ITEM_ERROR';

/** CASE SUMMARY */
export const CASESUMMARY_GET_LIST = 'CASESUMMARY_GET_LIST';
export const CASESUMMARY_GET_LIST_SUCCESS = 'CASESUMMARY_GET_LIST_SUCCESS';
export const CASESUMMARY_GET_LIST_ERROR = 'CASESUMMARY_GET_LIST_ERROR';

export const JOB_TRACKING_GET_LIST_BY_CLIENT = 'JOB_TRACKING_GET_LIST_BY_CLIENT';
export const JOB_TRACKING_GET_LIST_BY_CLIENT_SUCCESS = 'JOB_TRACKING_GET_LIST_BY_CLIENT_SUCCESS';
export const JOB_TRACKING_GET_LIST_BY_CLIENT_ERROR = 'JOB_TRACKING_GET_LIST_BY_CLIENT_ERROR';

export const JOB_TRACKING_GET_LIST = 'JOB_TRACKING_GET_LIST';
export const JOB_TRACKING_GET_LIST_SUCCESS = 'JOB_TRACKING_GET_LIST_SUCCESS';
export const JOB_TRACKING_GET_LIST_ERROR = 'JOB_TRACKING_GET_LIST_ERROR';

export const JOB_TRACKING_ADD_NEW = 'JOB_TRACKING_ADD_NEW';
export const JOB_TRACKING_ADD_NEW_SUCCESS = 'JOB_TRACKING_ADD_NEW_SUCCESS';
export const JOB_TRACKING_ADD_NEW_ERROR = 'JOB_TRACKING_ADD_NEW_ERROR';

export const JOB_TRACKING_DELETE = 'JOB_TRACKING_DELETE';
export const JOB_TRACKING_DELETE_SUCCESS = 'JOB_TRACKING_DELETE_SUCCESS';
export const JOB_TRACKING_DELETE_ERROR = 'JOB_TRACKING_DELETE_ERROR';

export const JOB_TRACKING_GET_BY_ID = 'JOB_TRACKING_GET_BY_ID';
export const JOB_TRACKING_GET_BY_ID_SUCCESS = 'JOB_TRACKING_GET_BY_ID_SUCCESS';
export const JOB_TRACKING_GET_BY_ID_ERROR = 'JOB_TRACKING_GET_BY_ID_ERROR';

export const JOB_TRACKING_EDIT = 'JOB_TRACKING_EDIT';
export const JOB_TRACKING_EDIT_SUCCESS = 'JOB_TRACKING_EDIT_SUCCESS';
export const JOB_TRACKING_EDIT_ERROR = 'JOB_TRACKING_EDIT_ERROR';

/** Expense and Expense Entry **/

export const EXPENSE_GET_BY_QUERY = 'EXPENSE_GET_BY_QUERY';
export const EXPENSE_GET_BY_QUERY_SUCCESS = 'EXPENSE_GET_BY_QUERY_SUCCESS';
export const EXPENSE_GET_BY_QUERY_ERROR = 'EXPENSE_GET_BY_QUERY_ERROR';

export const EXPENSE_GET_LIST = 'EXPENSE_GET_LIST';
export const EXPENSE_GET_LIST_SUCCESS = 'EXPENSE_GET_LIST_SUCCESS';
export const EXPENSE_GET_LIST_ERROR = 'EXPENSE_GET_LIST_ERROR';

export const EXPENSE_GET_LIST_BY_STATUS = 'EXPENSE_GET_LIST_BY_STATUS';
export const EXPENSE_GET_LIST_BY_STATUS_SUCCESS = 'EXPENSE_GET_LIST_BY_STATUS_SUCCESS';
export const EXPENSE_GET_LIST_BY_STATUS_ERROR = 'EXPENSE_GET_LIST_BY_STATUS_ERROR';

export const EXPENSE_GET_LIST_BY_USER_ID = 'EXPENSE_GET_LIST_BY_USER_ID';
export const EXPENSE_GET_LIST_BY_USER_ID_SUCCESS = 'EXPENSE_GET_LIST_BY_USER_ID_SUCCESS';
export const EXPENSE_GET_LIST_BY_USER_ID_ERROR = 'EXPENSE_GET_LIST_BY_USER_ID_ERROR';

export const EXPENSE_GET_BY_ID = 'EXPENSE_GET_BY_ID';
export const EXPENSE_GET_BY_ID_SUCCESS = 'EXPENSE_GET_BY_ID_SUCCESS';
export const EXPENSE_GET_BY_ID_ERROR = 'EXPENSE_GET_BY_ID_ERROR';

export const EXPENSE_ADD_NEW = 'EXPENSE_ADD_NEW';
export const EXPENSE_ADD_NEW_SUCCESS = 'EXPENSE_ADD_NEW_SUCCESS';
export const EXPENSE_ADD_NEW_ERROR = 'EXPENSE_ADD_NEW_ERROR';
export const EXPENSE_ADD_NEW_REQUEST = 'EXPENSE_ADD_NEW_REQUEST';

export const EXPENSE_EDIT = 'EXPENSE_EDIT';
export const EXPENSE_EDIT_SUCCESS = 'EXPENSE_EDIT_SUCCESS';
export const EXPENSE_EDIT_ERROR = 'EXPENSE_EDIT_ERROR';

export const EXPENSE_DELETE = 'EXPENSE_DELETE';
export const EXPENSE_DELETE_SUCCESS = 'EXPENSE_DELETE_SUCCESS';
export const EXPENSE_DELETE_ERROR = 'EXPENSE_DELETE_ERROR';

export const EXPENSE_SUBMIT = 'EXPENSE_SUBMIT';
export const EXPENSE_SUBMIT_SUCCESS = 'EXPENSE_SUBMIT_SUCCESS';
export const EXPENSE_SUBMIT_ERROR = 'EXPENSE_SUBMIT_ERROR';

export const EXPENSE_APPROVE_SUBMIT = 'EXPENSE_APPROVE_SUBMIT';
export const EXPENSE_APPROVE_SUBMIT_SUCCESS = 'EXPENSE_APPROVE_SUBMIT_SUCCESS';
export const EXPENSE_APPROVE_SUBMIT_ERROR = 'EXPENSE_APPROVE_SUBMIT_ERROR';

export const EXPENSE_SECTION_GET_LIST = 'EXPENSE_SECTION_GET_LIST';
export const EXPENSE_SECTION_GET_LIST_SUCCESS = 'EXPENSE_SECTION_GET_LIST_SUCCESS';
export const EXPENSE_SECTION_GET_LIST_ERROR = 'EXPENSE_SECTION_GET_LIST_ERROR';

export const EXPENSE_SECTION_GET_BY_ID = 'EXPENSE_SECTION_GET_BY_ID';
export const EXPENSE_SECTION_GET_BY_ID_SUCCESS = 'EXPENSE_SECTION_GET_BY_ID_SUCCESS';
export const EXPENSE_SECTION_GET_BY_ID_ERROR = 'EXPENSE_SECTION_GET_BY_ID_ERROR';

export const EXPENSE_SECTION_ADD_NEW = 'EXPENSE_SECTION_ADD_NEW';
export const EXPENSE_SECTION_ADD_NEW_SUCCESS = 'EXPENSE_SECTION_ADD_NEW_SUCCESS';
export const EXPENSE_SECTION_ADD_NEW_ERROR = 'EXPENSE_SECTION_ADD_NEW_ERROR';
export const EXPENSE_SECTION_ADD_NEW_REQUEST = 'EXPENSE_SECTION_ADD_NEW_REQUEST';

export const EXPENSE_SECTION_EDIT = 'EXPENSE_SECTION_EDIT';
export const EXPENSE_SECTION_EDIT_SUCCESS = 'EXPENSE_SECTION_EDIT_SUCCESS';
export const EXPENSE_SECTION_EDIT_ERROR = 'EXPENSE_SECTION_EDIT_ERROR';

export const EXPENSE_SECTION_DELETE = 'EXPENSE_SECTION_DELETE';
export const EXPENSE_SECTION_DELETE_SUCCESS = 'EXPENSE_SECTION_DELETE_SUCCESS';
export const EXPENSE_SECTION_DELETE_ERROR = 'EXPENSE_SECTION_DELETE_ERROR';

export const EXPENSE_ENTRY_GET_LIST_BY_EXPENSE_ID = 'EXPENSE_ENTRY_GET_LIST_BY_EXPENSE_ID';
export const EXPENSE_ENTRY_GET_LIST_BY_EXPENSE_ID_SUCCESS =
  'EXPENSE_ENTRY_GET_LIST_BY_EXPENSE_ID_SUCCESS';
export const EXPENSE_ENTRY_GET_LIST_BY_EXPENSE_ID_ERROR =
  'EXPENSE_ENTRY_GET_LIST_BY_EXPENSE_ID_ERROR';

export const EXPENSE_ENTRY_GET_BY_ID = 'EXPENSE_ENTRY_GET_BY_ID';
export const EXPENSE_ENTRY_GET_BY_ID_SUCCESS = 'EXPENSE_ENTRY_GET_BY_ID_SUCCESS';
export const EXPENSE_ENTRY_GET_BY_ID_ERROR = 'EXPENSE_ENTRY_GET_BY_ID_ERROR';

export const EXPENSE_ENTRY_ADD_NEW = 'EXPENSE_ENTRY_ADD_NEW';
export const EXPENSE_ENTRY_ADD_NEW_SUCCESS = 'EXPENSE_ENTRY_ADD_NEW_SUCCESS';
export const EXPENSE_ENTRY_ADD_NEW_ERROR = 'EXPENSE_ENTRY_ADD_NEW_ERROR';
export const EXPENSE_ENTRY_ADD_NEW_REQUEST = 'EXPENSE_ENTRY_ADD_NEW_REQUEST';

export const EXPENSE_ENTRY_EDIT = 'EXPENSE_ENTRY_EDIT';
export const EXPENSE_ENTRY_EDIT_SUCCESS = 'EXPENSE_ENTRY_EDIT_SUCCESS';
export const EXPENSE_ENTRY_EDIT_ERROR = 'EXPENSE_ENTRY_EDIT_ERROR';

export const EXPENSE_ENTRY_DELETE = 'EXPENSE_ENTRY_DELETE';
export const EXPENSE_ENTRY_DELETE_SUCCESS = 'EXPENSE_ENTRY_DELETE_SUCCESS';
export const EXPENSE_ENTRY_DELETE_ERROR = 'EXPENSE_ENTRY_DELETE_ERROR';

export const EXPENSE_ENTRY_RECEIPT_DELETE = 'EXPENSE_ENTRY_RECEIPT_DELETE';
export const EXPENSE_ENTRY_RECEIPT_DELETE_SUCCESS = 'EXPENSE_ENTRY_RECEIPT_DELETE_SUCCESS';
export const EXPENSE_ENTRY_RECEIPT_DELETE_ERROR = 'EXPENSE_ENTRY_RECEIPT_DELETE_ERROR';

export const EXPENSE_CALCULATE_DISTANCE = 'EXPENSE_CALCULATE_DISTANCE';
export const EXPENSE_CALCULATE_DISTANCE_SUCCESS = 'EXPENSE_CALCULATE_DISTANCE_SUCCESS';
export const EXPENSE_CALCULATE_DISTANCE_ERROR = 'EXPENSE_CALCULATE_DISTANCE_ERROR';

export const EXPENSE_REPORT_GET_LIST = 'EXPENSE_REPORT_GET_LIST';
export const EXPENSE_REPORT_GET_LIST_SUCCESS = 'EXPENSE_REPORT_GET_LIST_SUCCESS';
export const EXPENSE_REPORT_GET_LIST_ERROR = 'EXPENSE_REPORT_GET_LIST_ERROR';

export const EXPENSE_REPORT_GET_BY_ID = 'EXPENSE_REPORT_GET_BY_ID';
export const EXPENSE_REPORT_GET_BY_ID_SUCCESS = 'EXPENSE_REPORT_GET_BY_ID_SUCCESS';
export const EXPENSE_REPORT_GET_BY_ID_ERROR = 'EXPENSE_REPORT_GET_BY_ID_ERROR';

export const EXPENSE_REPORT_ADD_NEW = 'EXPENSE_REPORT_ADD_NEW';
export const EXPENSE_REPORT_ADD_NEW_SUCCESS = 'EXPENSE_REPORT_ADD_NEW_SUCCESS';
export const EXPENSE_REPORT_ADD_NEW_ERROR = 'EXPENSE_REPORT_ADD_NEW_ERROR';
export const EXPENSE_REPORT_ADD_NEW_REQUEST = 'EXPENSE_REPORT_ADD_NEW_REQUEST';

export const EXPENSE_REPORT_EDIT = 'EXPENSE_REPORT_EDIT';
export const EXPENSE_REPORT_EDIT_SUCCESS = 'EXPENSE_REPORT_EDIT_SUCCESS';
export const EXPENSE_REPORT_EDIT_ERROR = 'EXPENSE_REPORT_EDIT_ERROR';

export const EXPENSE_REPORT_DELETE = 'EXPENSE_REPORT_DELETE';
export const EXPENSE_REPORT_DELETE_SUCCESS = 'EXPENSE_REPORT_DELETE_SUCCESS';
export const EXPENSE_REPORT_DELETE_ERROR = 'EXPENSE_REPORT_DELETE_ERROR';

/** MileageSection */
export const MILEAGE_GET_BY_QUERY = 'MILEAGE_GET_BY_QUERY';
export const MILEAGE_GET_BY_QUERY_SUCCESS = 'MILEAGE_GET_BY_QUERY_SUCCESS';
export const MILEAGE_GET_BY_QUERY_ERROR = 'MILEAGE_GET_BY_QUERY_ERROR';

export const MILEAGE_GET_LIST = 'MILEAGE_GET_LIST';
export const MILEAGE_GET_LIST_SUCCESS = 'MILEAGE_GET_LIST_SUCCESS';
export const MILEAGE_GET_LIST_ERROR = 'MILEAGE_GET_LIST_ERROR';

export const MILEAGE_GET_LIST_BY_STATUS = 'MILEAGE_GET_LIST_BY_STATUS';
export const MILEAGE_GET_LIST_BY_STATUS_SUCCESS = 'MILEAGE_GET_LIST_BY_STATUS_SUCCESS';
export const MILEAGE_GET_LIST_BY_STATUS_ERROR = 'MILEAGE_GET_LIST_BY_STATUS_ERROR';

export const MILEAGE_GET_LIST_BY_USER_ID = 'MILEAGE_GET_LIST_BY_USER_ID';
export const MILEAGE_GET_LIST_BY_USER_ID_SUCCESS = 'MILEAGE_GET_LIST_BY_USER_ID_SUCCESS';
export const MILEAGE_GET_LIST_BY_USER_ID_ERROR = 'MILEAGE_GET_LIST_BY_USER_ID_ERROR';

export const MILEAGE_GET_BY_ID = 'MILEAGE_GET_BY_ID';
export const MILEAGE_GET_BY_ID_SUCCESS = 'MILEAGE_GET_BY_ID_SUCCESS';
export const MILEAGE_GET_BY_ID_ERROR = 'MILEAGE_GET_BY_ID_ERROR';

export const MILEAGE_ADD_NEW = 'MILEAGE_ADD_NEW';
export const MILEAGE_ADD_NEW_SUCCESS = 'MILEAGE_ADD_NEW_SUCCESS';
export const MILEAGE_ADD_NEW_ERROR = 'MILEAGE_ADD_NEW_ERROR';
export const MILEAGE_ADD_NEW_REQUEST = 'MILEAGE_ADD_NEW_REQUEST';

export const MILEAGE_EDIT = 'MILEAGE_EDIT';
export const MILEAGE_EDIT_SUCCESS = 'MILEAGE_EDIT_SUCCESS';
export const MILEAGE_EDIT_ERROR = 'MILEAGE_EDIT_ERROR';

export const MILEAGE_DELETE = 'MILEAGE_DELETE';
export const MILEAGE_DELETE_SUCCESS = 'MILEAGE_DELETE_SUCCESS';
export const MILEAGE_DELETE_ERROR = 'MILEAGE_DELETE_ERROR';

export const MILEAGE_SUBMIT = 'MILEAGE_SUBMIT';
export const MILEAGE_SUBMIT_SUCCESS = 'MILEAGE_SUBMIT_SUCCESS';
export const MILEAGE_SUBMIT_ERROR = 'MILEAGE_SUBMIT_ERROR';

export const MILEAGE_APPROVE_SUBMIT = 'MILEAGE_APPROVE_SUBMIT';
export const MILEAGE_APPROVE_SUBMIT_SUCCESS = 'MILEAGE_APPROVE_SUBMIT_SUCCESS';
export const MILEAGE_APPROVE_SUBMIT_ERROR = 'MILEAGE_APPROVE_SUBMIT_ERROR';

export const MILEAGE_REJECT_SUBMIT = 'MILEAGE_REJECT_SUBMIT';
export const MILEAGE_REJECT_SUBMIT_SUCCESS = 'MILEAGE_REJECT_SUBMIT_SUCCESS';
export const MILEAGE_REJECT_SUBMIT_ERROR = 'MILEAGE_REJECT_SUBMIT_ERROR';

export const MILEAGE_PREVIOUS_SUBMISSION = 'MILEAGE_PREVIOUS_SUBMISSION';
export const MILEAGE_PREVIOUS_SUBMISSION_SUCCESS = 'MILEAGE_PREVIOUS_SUBMISSION_SUCCESS';
export const MILEAGE_PREVIOUS_SUBMISSION_ERROR = 'MILEAGE_PREVIOUS_SUBMISSION_ERROR';

export const MILEAGE_PAID_OR_UNPAID = 'MILEAGE_PAID_OR_UNPAID';
export const MILEAGE_PAID_OR_UNPAID_SUCCESS = 'MILEAGE_PAID_OR_UNPAID_SUCCESS';
export const MILEAGE_PAID_OR_UNPAID_ERROR = 'MILEAGE_PAID_OR_UNPAID_ERROR';

export const MILEAGE_SECTION_GET_LIST = 'MILEAGE_SECTION_GET_LIST';
export const MILEAGE_SECTION_GET_LIST_SUCCESS = 'MILEAGE_SECTION_GET_LIST_SUCCESS';
export const MILEAGE_SECTION_GET_LIST_ERROR = 'MILEAGE_SECTION_GET_LIST_ERROR';

export const MILEAGE_SECTION_GET_BY_ID = 'MILEAGE_SECTION_GET_BY_ID';
export const MILEAGE_SECTION_GET_BY_ID_SUCCESS = 'MILEAGE_SECTION_GET_BY_ID_SUCCESS';
export const MILEAGE_SECTION_GET_BY_ID_ERROR = 'MILEAGE_SECTION_GET_BY_ID_ERROR';

export const MILEAGE_SECTION_ADD_NEW = 'MILEAGE_SECTION_ADD_NEW';
export const MILEAGE_SECTION_ADD_NEW_SUCCESS = 'MILEAGE_SECTION_ADD_NEW_SUCCESS';
export const MILEAGE_SECTION_ADD_NEW_ERROR = 'MILEAGE_SECTION_ADD_NEW_ERROR';
export const MILEAGE_SECTION_ADD_NEW_REQUEST = 'MILEAGE_SECTION_ADD_NEW_REQUEST';

export const MILEAGE_SECTION_EDIT = 'MILEAGE_SECTION_EDIT';
export const MILEAGE_SECTION_EDIT_SUCCESS = 'MILEAGE_SECTION_EDIT_SUCCESS';
export const MILEAGE_SECTION_EDIT_ERROR = 'MILEAGE_SECTION_EDIT_ERROR';

export const MILEAGE_SECTION_DELETE = 'MILEAGE_SECTION_DELETE';
export const MILEAGE_SECTION_DELETE_SUCCESS = 'MILEAGE_SECTION_DELETE_SUCCESS';
export const MILEAGE_SECTION_DELETE_ERROR = 'MILEAGE_SECTION_DELETE_ERROR';

export const MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID = 'MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID';
export const MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID_SUCCESS =
  'MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID_SUCCESS';
export const MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID_ERROR =
  'MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID_ERROR';

export const MILEAGE_ENTRY_GET_BY_ID = 'MILEAGE_ENTRY_GET_BY_ID';
export const MILEAGE_ENTRY_GET_BY_ID_SUCCESS = 'MILEAGE_ENTRY_GET_BY_ID_SUCCESS';
export const MILEAGE_ENTRY_GET_BY_ID_ERROR = 'MILEAGE_ENTRY_GET_BY_ID_ERROR';

export const MILEAGE_ENTRY_ADD_NEW = 'MILEAGE_ENTRY_ADD_NEW';
export const MILEAGE_ENTRY_ADD_NEW_SUCCESS = 'MILEAGE_ENTRY_ADD_NEW_SUCCESS';
export const MILEAGE_ENTRY_ADD_NEW_ERROR = 'MILEAGE_ENTRY_ADD_NEW_ERROR';
export const MILEAGE_ENTRY_ADD_NEW_REQUEST = 'MILEAGE_ENTRY_ADD_NEW_REQUEST';

export const MILEAGE_ENTRY_EDIT = 'MILEAGE_ENTRY_EDIT';
export const MILEAGE_ENTRY_EDIT_SUCCESS = 'MILEAGE_ENTRY_EDIT_SUCCESS';
export const MILEAGE_ENTRY_EDIT_ERROR = 'MILEAGE_ENTRY_EDIT_ERROR';

export const MILEAGE_ENTRY_DELETE = 'MILEAGE_ENTRY_DELETE';
export const MILEAGE_ENTRY_DELETE_SUCCESS = 'MILEAGE_ENTRY_DELETE_SUCCESS';
export const MILEAGE_ENTRY_DELETE_ERROR = 'MILEAGE_ENTRY_DELETE_ERROR';

export const MILEAGE_CALCULATE_DISTANCE = 'MILEAGE_CALCULATE_DISTANCE';
export const MILEAGE_CALCULATE_DISTANCE_SUCCESS = 'MILEAGE_CALCULATE_DISTANCE_SUCCESS';
export const MILEAGE_CALCULATE_DISTANCE_ERROR = 'MILEAGE_CALCULATE_DISTANCE_ERROR';

export const MILEAGE_REPORT_GET_LIST = 'MILEAGE_REPORT_GET_LIST';
export const MILEAGE_REPORT_GET_LIST_SUCCESS = 'MILEAGE_REPORT_GET_LIST_SUCCESS';
export const MILEAGE_REPORT_GET_LIST_ERROR = 'MILEAGE_REPORT_GET_LIST_ERROR';

export const MILEAGE_REPORT_GET_BY_ID = 'MILEAGE_REPORT_GET_BY_ID';
export const MILEAGE_REPORT_GET_BY_ID_SUCCESS = 'MILEAGE_REPORT_GET_BY_ID_SUCCESS';
export const MILEAGE_REPORT_GET_BY_ID_ERROR = 'MILEAGE_REPORT_GET_BY_ID_ERROR';

export const MILEAGE_REPORT_ADD_NEW = 'MILEAGE_REPORT_ADD_NEW';
export const MILEAGE_REPORT_ADD_NEW_SUCCESS = 'MILEAGE_REPORT_ADD_NEW_SUCCESS';
export const MILEAGE_REPORT_ADD_NEW_ERROR = 'MILEAGE_REPORT_ADD_NEW_ERROR';
export const MILEAGE_REPORT_ADD_NEW_REQUEST = 'MILEAGE_REPORT_ADD_NEW_REQUEST';

export const MILEAGE_REPORT_EDIT = 'MILEAGE_REPORT_EDIT';
export const MILEAGE_REPORT_EDIT_SUCCESS = 'MILEAGE_REPORT_EDIT_SUCCESS';
export const MILEAGE_REPORT_EDIT_ERROR = 'MILEAGE_REPORT_EDIT_ERROR';

export const MILEAGE_REPORT_DELETE = 'MILEAGE_REPORT_DELETE';
export const MILEAGE_REPORT_DELETE_SUCCESS = 'MILEAGE_REPORT_DELETE_SUCCESS';
export const MILEAGE_REPORT_DELETE_ERROR = 'MILEAGE_REPORT_DELETE_ERROR';

/* REPORT MILEAGE SECTION*/
export const REPORT_MILEAGE_SECTION_GET_BY_ID = 'REPORT_MILEAGE_SECTION_GET_BY_ID';
export const REPORT_MILEAGE_SECTION_GET_BY_ID_SUCCESS = 'REPORT_MILEAGE_SECTION_GET_BY_ID_SUCCESS';
export const REPORT_MILEAGE_SECTION_GET_BY_ID_ERROR = 'REPORT_MILEAGE_SECTION_GET_BY_ID_ERROR';
export const REPORT_MILEAGE_SECTION_GET_LIST = 'REPORT_MILEAGE_SECTION_GET_LIST';
export const REPORT_MILEAGE_SECTION_GET_LIST_SUCCESS = 'REPORT_MILEAGE_SECTION_GET_LIST_SUCCESS';
export const REPORT_MILEAGE_SECTION_GET_LIST_ERROR = 'REPORT_MILEAGE_SECTION_GET_LIST_ERROR';
export const REPORT_MILEAGE_SECTION_GET_LIST_WITH_FILTER =
  'REPORT_MILEAGE_SECTION_GET_LIST_WITH_FILTER';
export const REPORT_MILEAGE_SECTION_GET_LIST_WITH_ORDER =
  'REPORT_MILEAGE_SECTION_GET_LIST_WITH_ORDER';
export const REPORT_MILEAGE_SECTION_GET_LIST_SEARCH = 'REPORT_MILEAGE_SECTION_GET_LIST_SEARCH';
export const REPORT_MILEAGE_SECTION_ADD_ITEM = 'REPORT_MILEAGE_SECTION_ADD_ITEM';
export const REPORT_MILEAGE_SECTION_ADD_ITEM_SUCCESS = 'REPORT_MILEAGE_SECTION_ADD_ITEM_SUCCESS';
export const REPORT_MILEAGE_SECTION_ADD_ITEM_ERROR = 'REPORT_MILEAGE_SECTION_ADD_ITEM_ERROR';
export const REPORT_MILEAGE_SECTION_ADD_ITEM_REQUEST = 'REPORT_MILEAGE_SECTION_ADD_ITEM_REQUEST';
export const REPORT_MILEAGE_SECTION_EDIT_ITEM = 'REPORT_MILEAGE_SECTION_EDIT_ITEM';
export const REPORT_MILEAGE_SECTION_EDIT_ITEM_ERROR = 'REPORT_MILEAGE_SECTION_EDIT_ITEM_ERROR';
export const REPORT_MILEAGE_SECTION_EDIT_ITEM_SUCCESS = 'REPORT_MILEAGE_SECTION_EDIT_ITEM_SUCCESS';
export const REPORT_MILEAGE_SECTION_DELETE_ITEM = 'REPORT_MILEAGE_SECTION_DELETE_ITEM';
export const REPORT_MILEAGE_SECTION_DELETE_ITEM_ERROR = 'REPORT_MILEAGE_SECTION_DELETE_ITEM_ERROR';
export const REPORT_MILEAGE_SECTION_DELETE_ITEM_SUCCESS =
  'REPORT_MILEAGE_SECTION_DELETE_ITEM_SUCCESS';
export const REPORT_MILEAGE_SECTION_SELECTED_ITEMS_CHANGE =
  'REPORT_MILEAGE_SECTION_SELECTED_ITEMS_CHANGE';

/* MILEAGE SETTING*/
export const MILEAGE_SETTING_GET_LIST = 'MILEAGE_SETTING_GET_LIST';
export const MILEAGE_SETTING_GET_LIST_SUCCESS = 'MILEAGE_SETTING_GET_LIST_SUCCESS';
export const MILEAGE_SETTING_GET_LIST_ERROR = 'MILEAGE_SETTING_GET_LIST_ERROR';

export const MILEAGE_SETTING_GET_BY_ID = 'MILEAGE_SETTING_GET_BY_ID';
export const MILEAGE_SETTING_GET_BY_ID_SUCCESS = 'MILEAGE_SETTING_GET_BY_ID_SUCCESS';
export const MILEAGE_SETTING_GET_BY_ID_ERROR = 'MILEAGE_SETTING_GET_BY_ID_ERROR';

export const MILEAGE_SETTING_ADD_NEW = 'MILEAGE_SETTING_ADD_NEW';
export const MILEAGE_SETTING_ADD_NEW_SUCCESS = 'MILEAGE_SETTING_ADD_NEW_SUCCESS';
export const MILEAGE_SETTING_ADD_NEW_ERROR = 'MILEAGE_SETTING_ADD_NEW_ERROR';
export const MILEAGE_SETTING_ADD_NEW_REQUEST = 'MILEAGE_SETTING_ADD_NEW_REQUEST';

export const MILEAGE_SETTING_EDIT = 'MILEAGE_SETTING_EDIT';
export const MILEAGE_SETTING_EDIT_SUCCESS = 'MILEAGE_SETTING_EDIT_SUCCESS';
export const MILEAGE_SETTING_EDIT_ERROR = 'MILEAGE_SETTING_EDIT_ERROR';

export const MILEAGE_SETTING_DELETE = 'MILEAGE_SETTING_DELETE';
export const MILEAGE_SETTING_DELETE_SUCCESS = 'MILEAGE_SETTING_DELETE_SUCCESS';
export const MILEAGE_SETTING_DELETE_ERROR = 'MILEAGE_SETTING_DELETE_ERROR';

/* CLIENT FUND SETTING*/
export const CLIENT_FUND_SETTING_GET_LIST = 'CLIENT_FUND_SETTING_GET_LIST';
export const CLIENT_FUND_SETTING_GET_LIST_SUCCESS = 'CLIENT_FUND_SETTING_GET_LIST_SUCCESS';
export const CLIENT_FUND_SETTING_GET_LIST_ERROR = 'CLIENT_FUND_SETTING_GET_LIST_ERROR';

export const CLIENT_FUND_SETTING_GET_BY_ID = 'CLIENT_FUND_SETTING_GET_BY_ID';
export const CLIENT_FUND_SETTING_GET_BY_ID_SUCCESS = 'CLIENT_FUND_SETTING_GET_BY_ID_SUCCESS';
export const CLIENT_FUND_SETTING_GET_BY_ID_ERROR = 'CLIENT_FUND_SETTING_GET_BY_ID_ERROR';

export const CLIENT_FUND_SETTING_ADD_NEW = 'CLIENT_FUND_SETTING_ADD_NEW';
export const CLIENT_FUND_SETTING_ADD_NEW_SUCCESS = 'CLIENT_FUND_SETTING_ADD_NEW_SUCCESS';
export const CLIENT_FUND_SETTING_ADD_NEW_ERROR = 'CLIENT_FUND_SETTING_ADD_NEW_ERROR';
export const CLIENT_FUND_SETTING_ADD_NEW_REQUEST = 'CLIENT_FUND_SETTING_ADD_NEW_REQUEST';

export const CLIENT_FUND_SETTING_EDIT = 'CLIENT_FUND_SETTING_EDIT';
export const CLIENT_FUND_SETTING_EDIT_SUCCESS = 'CLIENT_FUND_SETTING_EDIT_SUCCESS';
export const CLIENT_FUND_SETTING_EDIT_ERROR = 'CLIENT_FUND_SETTING_EDIT_ERROR';

export const CLIENT_FUND_SETTING_DELETE = 'CLIENT_FUND_SETTING_DELETE';
export const CLIENT_FUND_SETTING_DELETE_SUCCESS = 'CLIENT_FUND_SETTING_DELETE_SUCCESS';
export const CLIENT_FUND_SETTING_DELETE_ERROR = 'CLIENT_FUND_SETTING_DELETE_ERROR';

export const SYSTEM_SETTING_GET_BY_ID = 'SYSTEM_SETTING_GET_BY_ID';
export const SYSTEM_SETTING_GET_BY_ID_SUCCESS = 'SYSTEM_SETTING_GET_BY_ID_SUCCESS';
export const SYSTEM_SETTING_GET_BY_ID_ERROR = 'SYSTEM_SETTING_GET_BY_ID_ERROR';

export const SYSTEM_SETTING_SAVE = 'SYSTEM_SETTING_SAVE';
export const SYSTEM_SETTING_SAVE_SUCCESS = 'SYSTEM_SETTING_SAVE_SUCCESS';
export const SYSTEM_SETTING_SAVE_ERROR = 'SYSTEM_SETTING_SAVE_ERROR';

/** ES_CHEQUE */

export const ES_CHEQUE_GET_BY_ESCONTRACT_ID = 'ES_CHEQUE_GET_BY_ESCONTRACT_ID';
export const ES_CHEQUE_GET_BY_ESCONTRACT_ID_SUCCESS = 'ES_CHEQUE_GET_BY_ESCONTRACT_ID_SUCCESS';
export const ES_CHEQUE_GET_BY_ESCONTRACT_ID_ERROR = 'ES_CHEQUE_GET_BY_ESCONTRACT_ID_ERROR';

export const ES_CHEQUE_GET_BY_ID = 'ES_CHEQUE_GET_BY_ID';
export const ES_CHEQUE_GET_BY_ID_SUCCESS = 'ES_CHEQUE_GET_BY_ID_SUCCESS';
export const ES_CHEQUE_GET_BY_ID_ERROR = 'ES_CHEQUE_GET_BY_ID_ERROR';

export const ES_CHEQUE_ADD_NEW = 'ES_CHEQUE_ADD_NEW';
export const ES_CHEQUE_ADD_NEW_SUCCESS = 'ES_CHEQUE_ADD_NEW_SUCCESS';
export const ES_CHEQUE_ADD_NEW_ERROR = 'ES_CHEQUE_ADD_NEW_ERROR';

export const ES_CHEQUE_EDIT = 'ES_CHEQUE_EDIT';
export const ES_CHEQUE_EDIT_SUCCESS = 'ES_CHEQUE_EDIT_SUCCESS';
export const ES_CHEQUE_EDIT_ERROR = 'ES_CHEQUE_EDIT_ERROR';

export const ES_CHEQUE_DELETE = 'ES_CHEQUE_DELETE';
export const ES_CHEQUE_DELETE_SUCCESS = 'ES_CHEQUE_DELETE_SUCCESS';
export const ES_CHEQUE_DELETE_ERROR = 'ES_CHEQUE_DELETE_ERROR';

/** SE_CHEQUE */

export const SE_CHEQUE_GET_BY_ESCONTRACT_ID = 'SE_CHEQUE_GET_BY_ESCONTRACT_ID';
export const SE_CHEQUE_GET_BY_ESCONTRACT_ID_SUCCESS = 'SE_CHEQUE_GET_BY_ESCONTRACT_ID_SUCCESS';
export const SE_CHEQUE_GET_BY_ESCONTRACT_ID_ERROR = 'SE_CHEQUE_GET_BY_ESCONTRACT_ID_ERROR';

export const SE_CHEQUE_GET_BY_ID = 'SE_CHEQUE_GET_BY_ID';
export const SE_CHEQUE_GET_BY_ID_SUCCESS = 'SE_CHEQUE_GET_BY_ID_SUCCESS';
export const SE_CHEQUE_GET_BY_ID_ERROR = 'SE_CHEQUE_GET_BY_ID_ERROR';

export const SE_CHEQUE_ADD_NEW = 'SE_CHEQUE_ADD_NEW';
export const SE_CHEQUE_ADD_NEW_SUCCESS = 'SE_CHEQUE_ADD_NEW_SUCCESS';
export const SE_CHEQUE_ADD_NEW_ERROR = 'SE_CHEQUE_ADD_NEW_ERROR';

export const SE_CHEQUE_EDIT = 'SE_CHEQUE_EDIT';
export const SE_CHEQUE_EDIT_SUCCESS = 'SE_CHEQUE_EDIT_SUCCESS';
export const SE_CHEQUE_EDIT_ERROR = 'SE_CHEQUE_EDIT_ERROR';

export const SE_CHEQUE_DELETE = 'SE_CHEQUE_DELETE';
export const SE_CHEQUE_DELETE_SUCCESS = 'SE_CHEQUE_DELETE_SUCCESS';
export const SE_CHEQUE_DELETE_ERROR = 'SE_CHEQUE_DELETE_ERROR';

/** CLIENT_FUND_SUPERVISOR */
export const CLIENT_FUND_SUPERVISOR_GET_ALL = 'CLIENT_FUND_SUPERVISOR_GET_ALL';
export const CLIENT_FUND_SUPERVISOR_GET_ALL_SUCCESS = 'CLIENT_FUND_SUPERVISOR_GET_ALL_SUCCESS';
export const CLIENT_FUND_SUPERVISOR_GET_ALL_ERROR = 'CLIENT_FUND_SUPERVISOR_GET_ALL_ERROR';

export const CLIENT_FUND_SUPERVISOR_GET_BY_ID = 'CLIENT_FUND_SUPERVISOR_GET_BY_ID';
export const CLIENT_FUND_SUPERVISOR_GET_BY_ID_SUCCESS = 'CLIENT_FUND_SUPERVISOR_GET_BY_ID_SUCCESS';
export const CLIENT_FUND_SUPERVISOR_GET_BY_ID_ERROR = 'CLIENT_FUND_SUPERVISOR_GET_BY_ID_ERROR';

export const CLIENT_FUND_SUPERVISOR_ADD_NEW = 'CLIENT_FUND_SUPERVISOR_ADD_NEW';
export const CLIENT_FUND_SUPERVISOR_ADD_NEW_SUCCESS = 'CLIENT_FUND_SUPERVISOR_ADD_NEW_SUCCESS';
export const CLIENT_FUND_SUPERVISOR_ADD_NEW_ERROR = 'CLIENT_FUND_SUPERVISOR_ADD_NEW_ERROR';

export const CLIENT_FUND_SUPERVISOR_EDIT = 'CLIENT_FUND_SUPERVISOR_EDIT';
export const CLIENT_FUND_SUPERVISOR_EDIT_SUCCESS = 'CLIENT_FUND_SUPERVISOR_EDIT_SUCCESS';
export const CLIENT_FUND_SUPERVISOR_EDIT_ERROR = 'CLIENT_FUND_SUPERVISOR_EDIT_ERROR';

export const CLIENT_FUND_SUPERVISOR_DELETE = 'CLIENT_FUND_SUPERVISOR_DELETE';
export const CLIENT_FUND_SUPERVISOR_DELETE_SUCCESS = 'CLIENT_FUND_SUPERVISOR_DELETE_SUCCESS';
export const CLIENT_FUND_SUPERVISOR_DELETE_ERROR = 'CLIENT_FUND_SUPERVISOR_DELETE_ERROR';

export const CLIENT_FUND_SUPERVISOR_EMAIL_NOTIFICATION =
  'CLIENT_FUND_SUPERVISOR_EMAIL_NOTIFICATION';

/** MIlEAGE_SUPERVISOR */
export const MIlEAGE_SUPERVISOR_GET_ALL = 'MIlEAGE_SUPERVISOR_GET_ALL';
export const MIlEAGE_SUPERVISOR_GET_ALL_SUCCESS = 'MIlEAGE_SUPERVISOR_GET_ALL_SUCCESS';
export const MIlEAGE_SUPERVISOR_GET_ALL_ERROR = 'MIlEAGE_SUPERVISOR_GET_ALL_ERROR';

export const MIlEAGE_SUPERVISOR_GET_BY_ID = 'MIlEAGE_SUPERVISOR_GET_BY_ID';
export const MIlEAGE_SUPERVISOR_GET_BY_ID_SUCCESS = 'MIlEAGE_SUPERVISOR_GET_BY_ID_SUCCESS';
export const MIlEAGE_SUPERVISOR_GET_BY_ID_ERROR = 'MIlEAGE_SUPERVISOR_GET_BY_ID_ERROR';

export const MIlEAGE_SUPERVISOR_ADD_NEW = 'MIlEAGE_SUPERVISOR_ADD_NEW';
export const MIlEAGE_SUPERVISOR_ADD_NEW_SUCCESS = 'MIlEAGE_SUPERVISOR_ADD_NEW_SUCCESS';
export const MIlEAGE_SUPERVISOR_ADD_NEW_ERROR = 'MIlEAGE_SUPERVISOR_ADD_NEW_ERROR';

export const MIlEAGE_SUPERVISOR_EDIT = 'MIlEAGE_SUPERVISOR_EDIT';
export const MIlEAGE_SUPERVISOR_EDIT_SUCCESS = 'MIlEAGE_SUPERVISOR_EDIT_SUCCESS';
export const MIlEAGE_SUPERVISOR_EDIT_ERROR = 'MIlEAGE_SUPERVISOR_EDIT_ERROR';

export const MIlEAGE_SUPERVISOR_DELETE = 'MIlEAGE_SUPERVISOR_DELETE';
export const MIlEAGE_SUPERVISOR_DELETE_SUCCESS = 'MIlEAGE_SUPERVISOR_DELETE_SUCCESS';
export const MIlEAGE_SUPERVISOR_DELETE_ERROR = 'MIlEAGE_SUPERVISOR_DELETE_ERROR';

/** PROSPECTS */
export const PROSPECT_GET_LIST = 'PROSPECT_GET_LIST';
export const PROSPECT_GET_LIST_SUCCESS = 'PROSPECT_GET_LIST_SUCCESS';
export const PROSPECT_GET_LIST_ERROR = 'PROSPECT_GET_LIST_ERROR';
export const PROSPECT_ARCHIVE = 'PROSPECT_ARCHIVE';

/** PROSPECT_RECEIVE_NOTIFICATION */
export const PROSPECT_RECEIVE_NOTIFICATION_GET_ALL = 'PROSPECT_RECEIVE_NOTIFICATION_GET_ALL';
export const PROSPECT_RECEIVE_NOTIFICATION_GET_ALL_SUCCESS =
  'PROSPECT_RECEIVE_NOTIFICATION_GET_ALL_SUCCESS';
export const PROSPECT_RECEIVE_NOTIFICATION_GET_ALL_ERROR =
  'PROSPECT_RECEIVE_NOTIFICATION_GET_ALL_ERROR';

export const PROSPECT_RECEIVE_NOTIFICATION_GET_BY_ID = 'PROSPECT_RECEIVE_NOTIFICATION_GET_BY_ID';
export const PROSPECT_RECEIVE_NOTIFICATION_GET_BY_ID_SUCCESS =
  'PROSPECT_RECEIVE_NOTIFICATION_GET_BY_ID_SUCCESS';
export const PROSPECT_RECEIVE_NOTIFICATION_GET_BY_ID_ERROR =
  'PROSPECT_RECEIVE_NOTIFICATION_GET_BY_ID_ERROR';

export const PROSPECT_RECEIVE_NOTIFICATION_ADD_NEW = 'PROSPECT_RECEIVE_NOTIFICATION_ADD_NEW';
export const PROSPECT_RECEIVE_NOTIFICATION_ADD_NEW_SUCCESS =
  'PROSPECT_RECEIVE_NOTIFICATION_ADD_NEW_SUCCESS';
export const PROSPECT_RECEIVE_NOTIFICATION_ADD_NEW_ERROR =
  'PROSPECT_RECEIVE_NOTIFICATION_ADD_NEW_ERROR';

export const PROSPECT_RECEIVE_NOTIFICATION_EDIT = 'PROSPECT_RECEIVE_NOTIFICATION_EDIT';
export const PROSPECT_RECEIVE_NOTIFICATION_EDIT_SUCCESS =
  'PROSPECT_RECEIVE_NOTIFICATION_EDIT_SUCCESS';
export const PROSPECT_RECEIVE_NOTIFICATION_EDIT_ERROR = 'PROSPECT_RECEIVE_NOTIFICATION_EDIT_ERROR';

export const PROSPECT_RECEIVE_NOTIFICATION_DELETE = 'PROSPECT_RECEIVE_NOTIFICATION_DELETE';
export const PROSPECT_RECEIVE_NOTIFICATION_DELETE_SUCCESS =
  'PROSPECT_RECEIVE_NOTIFICATION_DELETE_SUCCESS';
export const PROSPECT_RECEIVE_NOTIFICATION_DELETE_ERROR =
  'PROSPECT_RECEIVE_NOTIFICATION_DELETE_ERROR';

export const GET_CLIENT_REDIRECT = 'GET_CLIENT_REDIRECT';
export const GET_CLIENT_REDIRECT_ERROR = 'GET_CLIENT_REDIRECT_ERROR';

export const CAMS_CLIENT_FETCH = 'CAMS_CLIENT_FETCH';
export const CAMS_CLIENT_FETCH_SUCCESS = 'CAMS_CLIENT_FETCH_SUCCESS';
export const CAMS_CLIENT_FETCH_ERROR = 'CAMS_CLIENT_FETCH_ERROR';

export const CAMS_OVERWRITE_UPDATE = 'CAMS_OVERWRITE_UPDATE';
export const CAMS_OVERWRITE_UPDATE_SUCCESS = 'CAMS_OVERWRITE_UPDATE_SUCCESS';
export const CAMS_OVERWRITE_UPDATE_ERROR = 'CAMS_OVERWRITE_UPDATE_ERROR';

export const CAMS_EMPLOYER_FETCH = 'CAMS_EMPLOYER_FETCH';
export const CAMS_EMPLOYER_FETCH_SUCCESS = 'CAMS_EMPLOYER_FETCH_SUCCESS';
export const CAMS_EMPLOYER_FETCH_ERROR = 'CAMS_EMPLOYER_FETCH_ERROR';
export const CAMS_EMPLOYER_OVERWRITE_UPDATE = 'CAMS_EMPLOYER_OVERWRITE_UPDATE';
export const CAMS_EMPLOYER_OVERWRITE_UPDATE_SUCCESS = 'CAMS_EMPLOYER_OVERWRITE_UPDATE_SUCCESS';
export const CAMS_EMPLOYER_OVERWRITE_UPDATE_ERROR = 'CAMS_EMPLOYER_OVERWRITE_UPDATE_ERROR';

export const CAMS_SELECTED_PLANNED_ITEM = 'CAMS_SELECTED_PLANNED_ITEM';
export const CAMS_SELECTED_ACTIVE_TAB = 'CAMS_SELECTED_ACTIVE_TAB';
export const CAMS_TOGGLE_SUBGOAL = 'CAMS_TOGGLE_SUBGOAL';
export const CAMS_SELECT_OUTCOME_JOB = 'CAMS_SELECT_OUTCOME_JOB';
export const CAMS_SELECT_CHECKPOINT_JOB = 'CAMS_SELECT_CHECKPOINT_JOB';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './employers/actions';
export * from './client/actions';
export * from './action-plan/actions';
export * from './additional-info/actions';
export * from './activity/actions';
export * from './job-seeker/actions';
export * from './event/actions';
export * from './contract/cojg/actions';
export * from './contract/budget/actions';
export * from './geography/actions';
export * from './shifts/actions';
export * from './job-type/actions';
export * from './sector/actions';
export * from './report/cojg/actions';
export * from './report/contracts-paid/actions';
export * from './report/cojg-fund/actions';
export * from './report/cojg-reconciliation/actions';
export * from './report/es/actions';
export * from './report/all-es-contract-oustanding/actions';
export * from './role/actions';
export * from './user/actions';
export * from './assessement/actions';
export * from './client-aggregate/actions';
export * from './aggregate/actions';
export * from './report/notification/actions';
export * from './client-fund/actions';
export * from './case-note2/actions';
export * from './resume/actions';
export * from './cams-import/actions';
export * from './employer-case-note/actions';
export * from './todo/actions';
export * from './case-summary/actions';
export * from './mileage-setting/actions';
export * from './client-fund-setting/actions';
export * from './mileage/actions';
export * from './expense/actions';
export * from './mileage-entry/actions';
export * from './expense-entry/actions';
export * from './cojg-case-note/actions';
export * from './se-contract/actions';
export * from './es-contract/actions';
export * from './ies-contract/actions';
export * from './document/actions';
export * from './system-setting/actions';
export * from './es-cheque/actions';
export * from './se-cheque/actions';
export * from './es-case-note/actions';
export * from './se-case-note/actions';
export * from './mileage-supervisor/actions';
export * from './event-calendar/actions';
export * from './form-document/actions';
export * from './site/actions';
export * from './employment-earning/actions';
export * from './contact/actions';
export * from './posting/actions';
export * from './event-participant/actions';
export * from './cec-contact/actions';
export * from './cec-list/actions';
export * from './yjc-contract/actions';
export * from './yjc-cheque/actions';
export * from './yjcs-contract/actions';
export * from './yjcs-cheque/actions';
export * from './prospect-receive-notification/actions';
export * from './service-plan/actions';
export * from './alert/actions';
export * from './referral/actions';
export * from './client-fund-supervisor/actions';
export * from './employment-action-plan/actions';
export * from './prospect/actions';
export * from './case-audit/actions';
export * from './client-info/actions';
export * from './eap/actions';
export * from './ca-referral/actions';
export * from './outcome-questionaire/actions';
export * from './outcome-checkpoint/actions';
export * from './cams/actions';
export * from './employer-info/actions';
export * from './client-job-match/actions';
export * from './client-new-matching/actions';
export * from './posting-sent-to-client/actions';
export * from './posting-new-matching/actions';
export * from './posting-matching-sent-to-client/actions';
export * from './task-management/actions';
export * from './note/actions';
export * from './invitation/actions';
export * from './esuite/actions';
export * from './esuite-activity/actions';
export * from './transaction-notification/actions';
export * from './ies-dashboard/actions';
export * from './ies-chart-detail-report/actions';
export * from './target-scheme/actions';
export * from './user-notification/actions';
export * from './intervention/actions';
export * from './job-seeker-retained-employer/actions';
export * from './action-plan-for-staff/actions';
export * from './action-planning-tool/actions';
export * from './jmpi-matrix/actions';
export * from './stake-holder/actions';
export * from './stake-holder-contact/actions';
export * from './stake-holder-email/actions';
export * from './stake-holder-action/actions';
export * from './stake-holder-meeting-note/actions';
export * from './stake-holder-document/actions';
export * from './community-partner/actions';
export * from './email-template/actions';
export * from './location/actions';
export * from './campaign/actions';
export * from './camp-dashboard/actions';
export * from './trf-ca-referral/actions';
export * from './report/ies-weekly/actions';
export * from './report/ies-caseload/actions';
export * from './budget-tracking/actions';
export * from './cp-referrer/actions';
export * from './workshop-event/actions';
export * from './user-document/actions';
export * from './journey-ack/actions';
