import { logger } from '../../logger';
import axios from 'axios';
import { authHeader, handleResponse } from '../../helpers/auth-header';
import { getSettings } from '../../services/settings-service';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { hasAccess } from '../../helpers/Utils';
import { Auth } from 'aws-amplify';
import Config from '../../config';
import { get } from 'lodash';
import {
  LOGIN_USER,
  RECONNECT_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VERIFY_CODE,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  reconnectingUser,
  reconnectingUserDone,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  verifyCodeSuccess,
  verifyCodeError,
  forgotPasswordError,
  userResetPasswordSuccess,
  userResetPasswordError,
} from './actions';

import { getCompanyClass } from '../../helpers/Utils';

import { NotificationManager } from '../../components/common/react-notifications';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchReconnectUser() {
  yield takeEvery(RECONNECT_USER, loginWithoutEmailPassword);
}

const escasesRegisterAndAuthenticate = async () => {
  return axios.post(`${Config.apiServerHost}/api/account/register`, {}, await authHeader());
};

const escasesIsPasswordExpired = async () => {
  return axios.get(`${Config.apiServerHost}/api/account/isPasswordExpired`, await authHeader());
};

const escasesForcePasswordChangeOnNextLogin = async currentPassword => {
  return axios.put(
    `${Config.apiServerHost}/api/account/forcePasswordChange`,
    currentPassword,
    await authHeader()
  );
};

export const escasesUpdateLastChangePasswordDate = async () => {
  return axios.put(
    `${Config.apiServerHost}/api/account/updateLastChangePasswordDate`,
    {},
    await authHeader()
  );
};

const refreshToken = async () => {
  await authHeader();
};

const cognitoLoginUser = async (username, password) => {
  let cognitoUser = await Auth.signIn(username, password);
  return cognitoUser;
};

const cognitoCompleteFirstLogin = async (cognitoUser, newPassword) => {
  let cognitoUser2 = await Auth.completeNewPassword(cognitoUser, newPassword);
  return cognitoUser2;
};

const verifyMFACode = async (cognitoUser, code) => {
  // logger.debug({ cognitoUser, code });

  if (cognitoUser.challengeName == 'CUSTOM_CHALLENGE') {
    logger.debug('Verifying code for CUSTOM_CHALLENGE...');
    const response = await Auth.sendCustomChallengeAnswer(cognitoUser, code);
    // logger.debug('Cognito User Data ---->', response);
    const session = await Auth.currentSession();
    // logger.debug('Cognito User Access Token --->', session.getAccessToken().getJwtToken());
    // logger.debug('Cognito User Identity Token ---->', session.getIdToken().getJwtToken());
    return response;
  } else if (cognitoUser.challengeName == 'SOFTWARE_TOKEN_MFA') {
    // logger.debug('Verifying code for SOFTWARE_TOKEN_MFA...');

    // const session = await Auth.currentSession();
    // logger.debug({ session });

    // var poolData = {
    //   UserPoolId: cognitoUser.pool.userPoolId,
    //   ClientId: cognitoUser.pool.clientId,
    // };
    // const userPool = new CognitoUserPool(poolData);
    // const userData = {
    //   Username: cognitoUser.username,
    //   Pool: userPool,
    // };
    // const cognitoUser2 = new CognitoUser(userData);

    // cognitoUser2.respondToAuthChallenge()

    //   respondToAuthChallenge(code, {
    //   onSuccess: result => logger.debug('WORKS', result),
    //   onFailure: err => logger.debug('ERR', err),
    // });

    const response = await Auth.confirmSignIn(cognitoUser, code, 'SOFTWARE_TOKEN_MFA');
    // logger.debug('Cognito User Data ---->', response);
    const session = await Auth.currentSession();
    // logger.debug('Cognito User Access Token --->', session.getAccessToken().getJwtToken());
    // logger.debug('Cognito User Identity Token ---->', session.getIdToken().getJwtToken());
    return response;
  }
};

function* loginWithEmailPassword({ payload }) {
  // logger.debug('loginWithEmailPassword', { val: logPayload.user });

  const { username, password, code, cognitoUser, awsConfig, newPassword } = payload.user;
  var currentPassword = password;
  const { history } = payload;
  try {
    if (!cognitoUser || !code) {
      logger.debug(
        '1. Initial login with username and password, this will send the OTP password via email'
      );

      //RULES for having both email otp, and authenticator app totp
      //---------------------------------------------------------------
      //Do it once, if the result is:
      //      CUSTOM_CHALLENGE + CUSTOM_AUTH =  Correct - email will be sent with OTP (Do nothing)
      //      SOFTWARE_TOKEN_MFA + USER_SRP_AUTH = Correct - email not send (Do nothing)

      //      CUSTOM_CHALLENGE + USER_SRP_AUTH = Incorrect - email not sent (redo call)
      //      SOFTWARE_TOKEN_MFA + CUSTOM_AUTH = Incorrect - email not send (redo call)

      if (newPassword && cognitoUser && !code) {
        logger.debug('2a. First time login - replacing temp password');

        var cognitoCompleteFirstLoginResponse = yield call(
          cognitoCompleteFirstLogin,
          cognitoUser,
          newPassword
        );
        logger.debug({ cognitoCompleteFirstLoginResponse });
        currentPassword = newPassword;
        yield call(escasesUpdateLastChangePasswordDate);
      }

      let cognitoLoginResponse = yield call(cognitoLoginUser, username, currentPassword);
      logger.debug({ cognitoLoginResponse });

      if (
        cognitoLoginResponse.challengeName == 'NEW_PASSWORD_REQUIRED' &&
        cognitoLoginResponse.authenticationFlowType === 'CUSTOM_AUTH'
      ) {
        yield put(
          loginUserSuccess({
            mfaCodeRequired: 'NEW_PASSWORD_REQUIRED',
            cognitoUser: cognitoLoginResponse,
          })
        );
        return false;
      }

      // If incorrect combo, change auth type and redo
      if (
        (cognitoLoginResponse.challengeName === 'SOFTWARE_TOKEN_MFA' &&
          cognitoLoginResponse.authenticationFlowType === 'CUSTOM_AUTH') ||
        (cognitoLoginResponse.challengeName === 'CUSTOM_CHALLENGE' &&
          cognitoLoginResponse.authenticationFlowType === 'USER_SRP_AUTH') ||
        !cognitoLoginResponse.challengeName
      ) {
        // Change authenticationFlowType to correct type
        // var awsConfigure = {
        //   identityPoolId: 'ca-central-1:dd1751e3-169e-41d0-a52c-ab19452f920a',
        //   region: 'ca-central-1',
        //   userPoolId: 'ca-central-1_XxJsPBVfU',
        //   userPoolWebClientId: '50q40u258jcrlr3h9qa9v0dbjd',
        //   mandatorySignIn: true,

        //   authenticationFlowType:
        //     cognitoLoginResponse.challengeName === 'SOFTWARE_TOKEN_MFA'
        //       ? 'USER_SRP_AUTH'
        //       : 'CUSTOM_AUTH',
        // };

        awsConfig.authenticationFlowType =
          cognitoLoginResponse.challengeName === 'SOFTWARE_TOKEN_MFA'
            ? 'USER_SRP_AUTH'
            : 'CUSTOM_AUTH';
        Auth.configure(awsConfig);

        //Redo the initial call with correct authenticationFlowType
        cognitoLoginResponse = yield call(cognitoLoginUser, username, currentPassword);
        logger.debug('redo', { cognitoLoginResponse });
      }

      if (cognitoLoginResponse.challengeName === 'CUSTOM_CHALLENGE' && !code) {
        logger.debug('This requires an email OTP code');
        yield put(
          loginUserSuccess({
            mfaCodeRequired: 'CUSTOM_CHALLENGE',
            cognitoUser: cognitoLoginResponse,
          })
        );
        return false;
      }
      if (cognitoLoginResponse.challengeName === 'SOFTWARE_TOKEN_MFA' && !code) {
        logger.debug('This requires an authenticator OTP code');
        yield put(
          loginUserSuccess({
            mfaCodeRequired: 'SOFTWARE_TOKEN_MFA',
            cognitoUser: cognitoLoginResponse,
          })
        );
        return false;
      }
    }

    logger.debug(
      '2b. Submit the cognitoUser that was previously returned + the OTP code to finalize the login'
    );

    logger.debug(
      'verify challenge',
      { cognitoUser },
      { code },
      { challengeName: cognitoUser.challengeName }
    );

    let challengeResponse = yield call(verifyMFACode, cognitoUser, code);

    // handle register & login
    logger.debug('register/login to escases');
    const response = yield call(escasesRegisterAndAuthenticate);

    let isPasswordExpiredResp = yield call(escasesIsPasswordExpired);
    if (Boolean(isPasswordExpiredResp.data) == true) {
      yield call(escasesForcePasswordChangeOnNextLogin, password);
    }

    if (response.data) {
      const data = response.data;

      //Re-login to cognito (new claims were added in register call)
      // logger.debug('Re-login to cognito');
      // cognitoLoginResponse = yield call(cognitoLoginUser, username, password);
      // logger.debug({ cognitoLoginResponse });

      logger.debug('refresh and save token');
      yield call(refreshToken);

      //adjust token
      data.token = localStorage.getItem('token');

      data.mfaCodeRequired = null;
      data.cognitoUser = challengeResponse;
      yield put(loginUserSuccess(data));
      console.log(data);
      localStorage.setItem('enableBatchProcessing', data.enableBatchProcessing);
      localStorage.setItem('hasClientServices', data.hasClientServices);
      localStorage.setItem('backgroundServerBaseDomain', data.backgroundServerBaseDomain);
      localStorage.setItem('exportBaseDomain', data.exportBaseDomain);

      getSettings()
        .then(res => {
          const { AppRegistrationId, AppRegistrationRedirectUrl } = res.data;
          localStorage.setItem('AppRegistrationId', AppRegistrationId);
          localStorage.setItem('AppRegistrationRedirectUrl', AppRegistrationRedirectUrl);
        })
        .catch(err => {
          logger.debug(err);
        });

      logger.debug('done auth, redirect to main');

      //Pop-up security message
      NotificationManager.success(
        `All individuals accessing Government of Ontario computing resources and networked computing systems of the strict prohibition against unauthorized access. The Government of Ontario is committed to safeguarding the security and integrity of its digital infrastructure, and we rely on your cooperation to maintain a secure computing environment.
        Unauthorized access to Government of Ontario computing resources, including networked computing systems, is strictly prohibited under our Acceptable Use Policy (AUP). This policy sets forth the guidelines and rules governing the use of our computing resources and ensures that they are used responsibly, ethically, and in compliance with all applicable laws and regulations.`,
        `Notice of Prohibition of Unauthorized Access`,
        10000,
        null,
        null,
        ''
      );

      if (data.isCommunityPartner) {
        history.push('/app/community-partner/default');
        return;
      }

      // Go to the respective client search page
      if (getCompanyClass() == 'fedcap' && hasAccess(data?.permissions, 'JobPostingMenu.Enable')) {
        history.push('/app/job-posting/default');
        return;
      }

      if (hasAccess(data?.permissions, 'ManageIESProgram.Enable')) {
        history.push('/app/client/ssm/ies');
      } else if (hasAccess(data?.permissions, 'ManageESProgram.Enable')) {
        history.push('/app/client/program/es/default');
      } else {
        history.push('/app/no-permission');
      }
    } else {
      yield put(loginUserError(response.message));
    }
  } catch (error) {
    console.log({ error });
    logger.debug({ error });
    const message = get(
      error,
      'response.data.message',
      get(error, 'message', 'Sorry, there was a problem logging in. Please try again.')
    );
    yield put(loginUserError(message));
  }
}

function* loginWithoutEmailPassword({ payload }) {
  logger.debug('loginWithoutEmailPassword');
  const { history } = payload;
  try {
    yield put(reconnectingUser());
    // handle register & login
    logger.debug('register/login to escases');
    const response = yield call(escasesRegisterAndAuthenticate);

    if (response.data) {
      const data = response.data;

      yield call(refreshToken);

      //adjust token
      data.token = localStorage.getItem('token');

      yield put(loginUserSuccess(data));

      localStorage.setItem('enableBatchProcessing', data.enableBatchProcessing);
      localStorage.setItem('hasClientServices', data.hasClientServices);
      localStorage.setItem('backgroundServerBaseDomain', data.backgroundServerBaseDomain);
      localStorage.setItem('exportBaseDomain', data.exportBaseDomain);

      yield put(reconnectingUserDone());

      getSettings()
        .then(res => {
          const { AppRegistrationId, AppRegistrationRedirectUrl } = res.data;
          localStorage.setItem('AppRegistrationId', AppRegistrationId);
          localStorage.setItem('AppRegistrationRedirectUrl', AppRegistrationRedirectUrl);
        })
        .catch(err => {
          logger.debug(err);
        });

      logger.debug('Reconnect successful.');
    } else {
      yield put(loginUserError(response.message));
      yield put(reconnectingUserDone());
      history.push('/user/login');
    }
  } catch (error) {
    console.log({ error });
    const message = get(
      error,
      'response.data.message',
      get(error, 'message', 'Sorry, there was a problem logging in. Please try again.')
    );
    yield put(loginUserError(message));
    yield put(reconnectingUserDone());
    history.push('/user/login');
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerUser);
}

const registerUserRequest = async user => {
  // return await Auth.signUp({
  //   username: user.username,
  //   password: user.password,
  //   attributes: {
  //     email: user.email,
  //     given_name: user.firstName,
  //     family_name: user.lastName,
  //   },
  // });

  const lang = localStorage.getItem('currentLanguage')
    ? localStorage.getItem('currentLanguage')
    : 'en';

  return axios.post(
    `${Config.apiServerHost}/api/account/register/${user.activationCode}?lang=${lang}`,
    user,
    await authHeader()
  );
};

// const checkEmailAvailableRequest = async email => {
//   const requestOptions = {
//     method: 'GET',
//   };

//   return await fetch(
//     `${Config.apiServerHost}/api/account/available?email=${email}`,
//     requestOptions
//   ).then(handleResponse);
// };

// const checkUsernameAvailableRequest = async username => {
//   const requestOptions = {
//     method: 'GET',
//   };

//   return await fetch(
//     `${Config.apiServerHost}/api/account/available?username=${username}`,
//     requestOptions
//   ).then(handleResponse);
// };

// const checkUsernameAndEmailAvailableRequest = async ({ username, email }) => {
//   const requestOptions = {
//     method: 'GET',
//   };

//   return await fetch(
//     `${Config.apiServerHost}/api/account/available?usernamecheck=${username}&emailcheck=${email}`,
//     requestOptions
//   ).then(handleResponse);
// };

const checkUsernameAndEmailAvailableRequest = async ({ username, email }) => {
  const encodedUsername = encodeURIComponent(username);
  const encodedEmail = encodeURIComponent(email);

  const requestOptions = {
    method: 'GET',
  };

  return await fetch(
    `${Config.apiServerHost}/api/account/available?usernamecheck=${encodedUsername}&emailcheck=${encodedEmail}`,
    requestOptions
  ).then(handleResponse);
};

function* registerUser({ payload }) {
  try {
    const { user, history } = payload;
    const emailIsAvailable = yield call(checkUsernameAndEmailAvailableRequest, {
      username: user.username,
      email: user.email,
    });
    if (emailIsAvailable.isAvailable) {
      const response = yield call(registerUserRequest, user);
      yield put(registerUserSuccess(response));
      history.push(`/user/login?username=${user.username}`);
    } else {
      yield put(registerUserError(emailIsAvailable.message));
    }

    // const response = yield call(registerUserRequest, user);
    // yield put(registerUserSuccess(response));
    // history.push(`/user/login?username=${user.username}`);
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async history => {
  try {
    await Auth.signOut();
  } catch (error) {
    logger.debug('error signing out: ', error);
  }
  logger.debug('Logging out! Navigate to login page.');
  history.push('/user/login');
};

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield call(logoutAsync, history);
    localStorage.removeItem('token');
    localStorage.removeItem('AppRegistrationId');
    localStorage.removeItem('AppRegistrationRedirectUrl');
    const keys = Object.keys(localStorage);
    keys.forEach(k => {
      if (k.indexOf('Cognito') === 0) {
        localStorage.removeItem(k);
      }
    });
  } catch (error) {}
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async email => {
  return await Auth.forgotPassword(email);
};

function* forgotPassword({ payload }) {
  const { email, history } = payload;
  try {
    const response = yield call(forgotPasswordAsync, email);
    if (response) {
      yield put(forgotPasswordSuccess('Please check your e-mail'));
      history.push('/user/confirm');
    } else {
      yield put(forgotPasswordError('Invalid'));
    }
  } catch (error) {
    logger.debug(error);
    if (error && error.code === 'UserNotFoundException') {
      yield put(forgotPasswordError('User not found'));
    } else {
      yield put(forgotPasswordError(error));
    }
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const verifyCodeRequest = async code => {
  return axios.get(`${Config.apiServerHost}/api/account/verifyCode/${code}`, await authHeader());
};

function* verifyCode({ payload }) {
  const { code, history } = payload;
  try {
    const response = yield call(verifyCodeRequest, code);
    yield put(verifyCodeSuccess(response.data));
    history.push('/user/reset-password');
  } catch (err) {
    const { data, statusText } = err.response;
    const error = {
      message: data,
      statusText,
    };
    yield put(verifyCodeError(error));
  }
}

export function* watchVerifyCode() {
  yield takeEvery(VERIFY_CODE, verifyCode);
}

const resetPasswordAsync = async (username, newPassword, code) => {
  return await Auth.forgotPasswordSubmit(username, code, newPassword);
};

function* resetPassword({ payload }) {
  const { password, history } = payload;
  try {
    yield call(resetPasswordAsync, password.username, password.newPassword, password.code);
    yield put(userResetPasswordSuccess('Password updated'));
    history.push('/user/login');
  } catch (error) {
    logger.debug(error);
    if (error && error.code === 'UserNotFoundException') {
      yield put(userResetPasswordError('User not found'));
    } else {
      yield put(userResetPasswordError(error));
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchReconnectUser),
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchVerifyCode),
  ]);
}
