import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  CLIENT_INFO_GET_BY_ID,
  CLIENT_INFO_UPDATE,
  CLIENT_INFO_SYNC,
  CLIENT_INFO_REPAIR,
  CLIENT_INFO_RESET,
  CLIENT_INFO_DETECH_DIFFERENCE,
} from './types';

import {
  getClientInfoByIdError,
  getClientInfoByIdSuccess,
  updateClientInfoError,
  updateClientInfoSuccess,
  syncClientInfoError,
  syncClientInfoSuccess,
  repairClientInfoByIdSuccess,
  repairClientInfoByIdError,
  resetClientInfoSuccess,
  resetClientInfoError,
  detectDifferenceClientInfoSuccess,
} from './actions';

import { buildUrl } from '../../helpers/Utils';

const getClientInfoByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/ClientProfile/client/${id}`, await authHeader());
};

const repairClientInfoByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/ClientProfile/${id}/repair`, await authHeader());
};

const resetClientInfoRequest = async (payload, eapMapping) => {
  const {
    id,
    servicePlanHeaderId,
    clientReferenceNumber,
    eapReferenceNumber,
    keepName,
    keepAddresses,
    keepPhoneNumbers,
    keepEmails,
  } = payload;

  const params = {
    servicePlanHeaderId,
    clientReferenceNumber,
    eapReferenceNumber,
    keepName,
    keepAddresses,
    keepPhoneNumbers,
    keepEmails,
  };
  return await axios.post(`${Config.apiServerHost}/api/ClientProfile/${id}/reset`, eapMapping, {
    params: params,
    headers: { ...(await authHeader()).headers },
  });
};

const detectDifferenceClientInfoRequest = async ({
  clientReferenceNumber,
  clientId,
  servicePlanHeaderId,
}) => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/detectDifference/${clientReferenceNumber}?clientId=${clientId}&servicePlanHeaderId=${servicePlanHeaderId}`,
    {
      headers: { ...(await authHeader()).headers },
    }
  );
};

const getClientInfoByClientReferenceNumberRequest = async clientReferenceNumber => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/clientReferenceNumber/${clientReferenceNumber}`,
    await authHeader()
  );
};

const getTransactionLogsByClientIdRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/client/${id}/transaction/logs`,
    await authHeader()
  );
};

const getLiveClientTransactions = async () => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/transaction/live`,
    await authHeader()
  );
};

const getTransactionErrorsByClientIdRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/client/${id}/transaction/live`,
    await authHeader()
  );
};

const getValidateRequest = async ({ clientId, servicePlanHeaderId }) => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/validate?clientId=${clientId}&servicePlanHeaderId=${servicePlanHeaderId}`,
    await authHeader()
  );
};

const getBusPackagesLogsByClientIdRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/client/${id}/buspackages/logs`,
    await authHeader()
  );
};

const getBusPackagesByClientIdRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/client/${id}/buspackages/live`,
    await authHeader()
  );
};

let getBatchProcessingPendingByClientIdRequestCancelToken;
const getBatchProcessingPendingByClientIdRequest = async id => {
  // If there's an ongoing request, cancel it
  if (getBatchProcessingPendingByClientIdRequestCancelToken) {
    getBatchProcessingPendingByClientIdRequestCancelToken.cancel(
      'Operation canceled due to new request.'
    );
  }

  // Create a new CancelToken source
  getBatchProcessingPendingByClientIdRequestCancelToken = axios.CancelToken.source();

  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/client/${id}/batchProcessingPending/pending`,
    {
      cancelToken: getBatchProcessingPendingByClientIdRequestCancelToken.token,
      headers: { ...(await authHeader()).headers },
    }
  );
};

const getBatchProcessingPendingSearchRequest = async data => {
  let url = `${Config.apiServerHost}/api/ClientProfile/batchProcessingPending/search`;
  return await axios.get(buildUrl(url, data), await authHeader());
};

const postBatchProcessingPendingSyncNowRequest = async ({ clientId, pendingId }) => {
  return axios.post(
    `${Config.apiServerHost}/api/ClientProfile/client/${clientId}/batchProcessingPending/${pendingId}/syncnow `,
    null,
    await authHeader()
  );
};

const getBatchProcessingPendingIgnoreRequest = async data => {
  let url = `${Config.apiServerHost}/api/ClientProfile/batchProcessingPending/${data.pendingId}/ignore`;
  return await axios.get(url, await authHeader());
};

const getBatchProcessingPendingResolvedRequest = async data => {
  let url = `${Config.apiServerHost}/api/ClientProfile/batchProcessingPending/${data.pendingId}/resolved`;
  return await axios.get(url, await authHeader());
};

const postBatchProcessingPendingSyncAllRequest = async () => {
  return axios.post(
    `${Config.apiServerHost}/api/ClientProfile/batchProcessingPending/sync-all `,
    null,
    await authHeader()
  );
};

const getGoverningTransactionByQueryRequest = async query => {
  return axios.get(`${Config.apiServerHost}/api/ClientProfile/governingTransactions`, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const putClientInfoTransactionIgnoreRequest = async id => {
  return axios.put(
    `${Config.apiServerHost}/api/ClientProfile/client/transaction/${id}/ignore`,
    null,
    await authHeader()
  );
};

const putBusPackageIgnoreRequest = async id => {
  return axios.put(
    `${Config.apiServerHost}/api/ClientProfile/client/buspackage/${id}/ignore`,
    null,
    await authHeader()
  );
};

const validateUpdateClientInfoRequest = async data => {
  return axios.post(`${Config.apiServerHost}/api/ClientProfile/validate`, data, await authHeader());
};

function* getClientInfoById({ payload }) {
  try {
    let response = yield call(getClientInfoByIdRequest, payload);
    yield put(getClientInfoByIdSuccess(response.data));
  } catch (error) {
    const { data } = error.response;
    let errorMessage = {
      title: error?.response?.statusText ?? 'Error',
      message: data?.message ?? 'Something was wrong.',
    };
    yield put(getClientInfoByIdError(errorMessage));
  }
}

function* detectDifferenceClientInfo({ payload }) {
  try {
    let response = yield call(detectDifferenceClientInfoRequest, payload);
    yield put(detectDifferenceClientInfoSuccess(response.data));
  } catch (error) {
    const { data } = error.response;
    let errorMessage = {
      title: error?.response?.statusText ?? 'Error',
      message: data?.message ?? 'Something was wrong.',
    };
    yield put(getClientInfoByIdError(errorMessage));
  }
}

function* repairClientInfoById({ payload }) {
  try {
    let response = yield call(repairClientInfoByIdRequest, payload);
    yield put(repairClientInfoByIdSuccess(response.data));
    yield put(getClientInfoByIdSuccess(response.data));
  } catch (error) {
    const { data } = error.response;
    let errorMessage = {
      title: error?.response?.statusText ?? 'Error',
      message: data?.message ?? 'Something was wrong.',
    };
    yield put(repairClientInfoByIdError(errorMessage));
  }
}

function* resetClientInfo({ payload }) {
  try {
    let response = yield call(resetClientInfoRequest, payload);
    yield put(resetClientInfoSuccess(response.data));
    yield put(getClientInfoByIdSuccess(response.data));
    window.location.reload(); //TBD - Refetch everything within the app rather than a refresh of the browser.
  } catch (error) {
    const { data } = error.response;
    let errorMessage = {
      title: error?.response?.statusText ?? 'Error',
      message: data?.message ?? 'Something was wrong.',
    };
    yield put(resetClientInfoError(errorMessage));
  }
}

const updateClientInfoRequest = async ({ servicePlanHeaderId, clientInfo }) => {
  return axios.put(
    `${Config.apiServerHost}/api/ClientProfile/client/${clientInfo.clientId}?servicePlanHeaderId=${servicePlanHeaderId}`,
    clientInfo,
    await authHeader()
  );
};

const syncClientInfoRequest = async data => {
  return axios.post(
    `${Config.apiServerHost}/api/ClientProfile/client/${data.clientId}/sync?servicePlanHeaderId=${data.servicePlanHeaderId}&clientReferenceNumberOverride=${data.clientReferenceNumberOverride}`,
    '{}',
    await authHeader()
  );
};

function* updateClientInfo({ payload }) {
  try {
    let response = yield call(updateClientInfoRequest, payload);
    yield put(updateClientInfoSuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Update Client Info',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(updateClientInfoError(message));
  }
}

function* syncClientInfo({ payload }) {
  try {
    const { clientId, servicePlanHeaderId, clientReferenceNumberOverride } = payload;
    let response = yield call(syncClientInfoRequest, {
      clientId,
      servicePlanHeaderId,
      clientReferenceNumberOverride,
    });
    yield put(syncClientInfoSuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Sync Client Info',
      text: error.response?.data?.message ?? 'something went wrong.',
    };

    yield put(syncClientInfoError(message));
  }
}

export default function* rootSaga() {
  yield takeEvery(CLIENT_INFO_GET_BY_ID, getClientInfoById);
  yield takeEvery(CLIENT_INFO_REPAIR, repairClientInfoById);
  yield takeEvery(CLIENT_INFO_UPDATE, updateClientInfo);
  yield takeEvery(CLIENT_INFO_SYNC, syncClientInfo);
  yield takeEvery(CLIENT_INFO_DETECH_DIFFERENCE, detectDifferenceClientInfo);
  yield takeEvery(CLIENT_INFO_RESET, resetClientInfo);
}

export {
  getTransactionLogsByClientIdRequest,
  getTransactionErrorsByClientIdRequest,
  getLiveClientTransactions,
  putClientInfoTransactionIgnoreRequest,
  getClientInfoByClientReferenceNumberRequest,
  getClientInfoByIdRequest,
  detectDifferenceClientInfoRequest,
  getGoverningTransactionByQueryRequest,
  resetClientInfoRequest,
  getBusPackagesByClientIdRequest,
  putBusPackageIgnoreRequest,
  getBusPackagesLogsByClientIdRequest,
  getValidateRequest,
  getBatchProcessingPendingByClientIdRequest,
  postBatchProcessingPendingSyncNowRequest,
  getBatchProcessingPendingSearchRequest,
  postBatchProcessingPendingSyncAllRequest,
  getBatchProcessingPendingIgnoreRequest,
  getBatchProcessingPendingResolvedRequest,
  validateUpdateClientInfoRequest,
};
