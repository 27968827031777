import {
  GET_CASE_LOAD_REPORT,
  GET_CASE_LOAD_REPORT_SUCCESS,
  GET_CASE_LOAD_REPORT_ERROR,
} from './types';

const INIT_STATE = {
  items: [],
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,
};

export default (state = INIT_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_CASE_LOAD_REPORT:
      return { ...state, loading: true };

    case GET_CASE_LOAD_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: payload,
        error: null,
      };

    case GET_CASE_LOAD_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return { ...state };
  }
};
