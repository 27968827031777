import {
  GET_CASE_LOAD_REPORT,
  GET_CASE_LOAD_REPORT_SUCCESS,
  GET_CASE_LOAD_REPORT_ERROR,
} from './types';

export const getCaseLoadReport = payload => ({
  type: GET_CASE_LOAD_REPORT,
  payload,
});

export const getCaseLoadReportSuccess = items => ({
  type: GET_CASE_LOAD_REPORT_SUCCESS,
  payload: items,
});

export const getCaseLoadReportError = error => ({
  type: GET_CASE_LOAD_REPORT_ERROR,
  payload: error,
});
